import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useLayoutEffect,
    useState,
    useEffect,
    useMemo,
} from 'react';
import AddressParser from 'parse-google-address';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import {
    Button,
    CustomCheckBox,
    Logo,
    Stepper,
    TextField,
    AddressAutocomplete,
    Message,
} from '../../../shared/elements/index';
import { stepperMock } from '../../../shared/elements/Stepper/mock';
import { TextFieldBottomMargin } from '../../../shared/elements/TextField/elements';
import { useRedirection } from '../../../shared/helpers/useRedirection';
import { Container } from '../../../shared/layout/Page/';
import Page, { PageContainer } from '../../../shared/layout/Page/index';
import { addressSchema, phoneMask } from '../../../shared/validation/address';
import { AddressContext } from '../../../store/context/subscription/address';
import { ErrorMessage } from '../../questionnaire/Step3/index';
import { QuizStepTitle, QuizStepWrapper } from '../elements';
import {
    AddressPersonalInfoContent,
    AddressPersonalInfoLeftGroup,
    AddressPersonalInfoRightGroup,
    ButtonWrapper,
    CodePostalWrapper,
    SmallInputsGroup,
    SubscriptionText,
    SubscriptionWrapper,
    VilleWrapper,
    CustomSpan,
    MessageWrapper,
    AddressPersonalInfoWrapper,
} from './elements';
import withGoogleMapsAPI from '../../../withGoogleMapsAPI';
import Footer from '../../../shared/elements/Footer';
// import { PersonalTransportContext } from '../../../store/context/subscription/transport';
import { InsuranceContext } from '../../../store/context/subscription/insurance';
import { DriversContext } from '../../../store/context/subscription/drivers';
import { PaymentContext } from '../../../store/context/subscription/payment';

export type AddressSubscriptionField = {
    subscriptionEmail: boolean;
    subscriptionSms: boolean;
};

export type BasicAddressFields = {
    address: string;
    information: string;
    email: string;
    phone: string | undefined;
    postalCode?: number | string;
    city: string;
};

let geocoder: google.maps.Geocoder;
const IS_5_DIGITS_PATTERN = /\d{5}/;
const POSTAL_CODE_ERROR =
    'Désolé, nous n’avons pas les habilitations pour couvrir un risque hors de la France métropolitaine';

const AddressPersonalInfo = () => {
    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    /**
     * Logic related to the routing actions.
     */
    const { push } = useHistory();
    const [nextRoute, prevRoute] = useRedirection({
        currentRoute: 'address',
        nextRoute: 'payment',
        prevRoute: 'drivers',
    });
    const back = useCallback(() => push(prevRoute), [prevRoute, push]);
    useEffect(() => {
        if (!geocoder) {
            geocoder = new google.maps.Geocoder();
        }
    }, []);

    /**
     * Logic related to the storing page-data in the state.
     */
    // const { completed: transportStepCompleted } = useContext(PersonalTransportContext);
    const { completed: insuranceStepCompleted } = useContext(InsuranceContext);
    const { completed: driversStepCompleted } = useContext(DriversContext);
    const { completed: paymentStepCompleted } = useContext(PaymentContext);
    const {
        address,
        city,
        email,
        information,
        phone,
        postalCode,
        subscriptionEmail,
        subscriptionSms,
        saveAddressInfo,
        completed: addressStepCompleted,
    } = useContext(AddressContext);
    const flowComppleted: boolean = useMemo(
        () =>
            // transportStepCompleted &&
            insuranceStepCompleted &&
            driversStepCompleted &&
            addressStepCompleted &&
            paymentStepCompleted,
        [
            addressStepCompleted,
            driversStepCompleted,
            insuranceStepCompleted,
            paymentStepCompleted,
        ]
    );
    const [addressFields, setAddressFields] = useState<
        BasicAddressFields & AddressSubscriptionField
    >({
        address,
        city,
        email,
        information,
        phone,
        postalCode,
        subscriptionEmail,
        subscriptionSms,
    });

    const updateAddressFields = useCallback(
        (field: string, value: string) => {
            return setAddressFields((prev) => ({
                ...prev,
                [field]: value,
            }));
        },
        [setAddressFields]
    );

    const inputsOnChange = useCallback(
        (field) => (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            return updateAddressFields(field, value);
        },
        [updateAddressFields]
    );

    // const checkBoxOnChange = useCallback(() => {
    //     setAddressFields({
    //         ...addressFields,
    //         subscription: !addressFields.subscription,
    //     });
    // }, [addressFields]);

    const handleChangeSubscriptionEmail = useCallback(() => {
        setAddressFields({
            ...addressFields,
            subscriptionEmail: !addressFields.subscriptionEmail,
        });
    }, [addressFields]);
    const handleChangeSubscriptionSms = useCallback(() => {
        setAddressFields({
            ...addressFields,
            subscriptionSms: !addressFields.subscriptionSms,
        });
    }, [addressFields]);

    /**
     * Logic related to the validation process.
     */
    const basicInitialValue: BasicAddressFields = {
        address: '',
        information: '',
        email: '',
        phone: undefined,
        postalCode: undefined,
        city: '',
    };
    const [validationErrors, setValidationErrors] = useState(basicInitialValue);

    const onAddressSelect = useCallback(
        (place) => {
            const subAddress =
                place.Sub_Locality_1.trim() ||
                place.Sub_Locality_2.trim() ||
                place.Sub_Locality_3.trim();
            const address =
                `${place.Street_Number} ${place.Route}`.trim() || subAddress;
            updateAddressFields('address', address);
            updateAddressFields('postalCode', place.Postal_Code);
            updateAddressFields('city', place.Locality);
            setValidationErrors({
                ...validationErrors,
                postalCode: '',
            });
        },
        [updateAddressFields, validationErrors]
    );

    /**
     * Logic related to the setting data in the context and move forward to the next step.
     */
    const saveAddressContext = useCallback(() => {
        saveAddressInfo({
            ...addressFields,
            email: addressFields.email.toLowerCase(),
            completed: true,
        });
        if (!flowComppleted) push(nextRoute);
        push('/subscription/summary');
    }, [flowComppleted, addressFields, nextRoute, push, saveAddressInfo]);

    /**
     * Logic related to the validation process and page submitting.
     */
    const submitAddressInfo = useCallback(async () => {
        setValidationErrors(basicInitialValue);
        try {
            await addressSchema.validate(
                { ...addressFields },
                { abortEarly: false }
            );
            setValidationErrors({ ...basicInitialValue });
            saveAddressContext();
        } catch ({ inner }) {
            const errors = inner.reduce((acc, err: yup.ValidationError) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setValidationErrors({ ...errors });
        }
    }, [basicInitialValue, addressFields, saveAddressContext]);

    const parseAddressAndFillCity = useCallback(
        (address) => {
            AddressParser.Parse_Reverse_GeoCode_Address(address).then(
                (parsedData) => {
                    if (parsedData.Locality) {
                        updateAddressFields('city', parsedData.Locality);
                    }
                }
            );
        },
        [updateAddressFields]
    );

    const geocodePostalCode = useCallback(
        (postalCode) => {
            geocoder.geocode(
                {
                    componentRestrictions: {
                        country: 'fr',
                        postalCode: postalCode,
                    },
                },
                (results, status) => {
                    if (
                        status === 'OK' &&
                        results[0] &&
                        results[0].address_components
                    ) {
                        parseAddressAndFillCity(results[0].address_components);
                        setValidationErrors({
                            ...validationErrors,
                            postalCode: '',
                        });
                    } else {
                        setValidationErrors({
                            ...validationErrors,
                            postalCode: POSTAL_CODE_ERROR,
                        });
                    }
                }
            );
        },
        [parseAddressAndFillCity, setValidationErrors, validationErrors]
    );

    const onPostalCodeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            updateAddressFields('postalCode', value);
            if (IS_5_DIGITS_PATTERN.test(value)) {
                geocodePostalCode(value);
            }
        },
        [updateAddressFields, geocodePostalCode]
    );

    /**
     * General logic and variables needed to define render of something.
     */

    const [error, setError] = useState<ErrorMessage>({
        display: false,
        message: '',
    });
    const hideErrorMessage = useCallback(
        () => setError({ ...error, display: false }),
        [error]
    );

    const [firstError] = Object.values(validationErrors).filter(
        Boolean
    ) as string[];

    return (
        <PageContainer>
            <Logo showGoBack={true} goBack={back} />
            <Stepper steps={stepperMock.steps} currentlySelected={1} />
            <Page>
                <Container>
                    <QuizStepWrapper>
                        <QuizStepTitle>
                            Pour mieux vous contacter, nous avons besoin de vos
                            coordonnées
                        </QuizStepTitle>
                        <AddressPersonalInfoContent>
                            <AddressPersonalInfoWrapper>
                                <AddressPersonalInfoLeftGroup
                                    specialHeight={
                                        !!validationErrors.postalCode
                                    }
                                >
                                    <TextFieldBottomMargin>
                                        <AddressAutocomplete
                                            label="Adresse"
                                            onChange={inputsOnChange('address')}
                                            onSelect={onAddressSelect}
                                            value={addressFields.address}
                                            initialVisibility={true}
                                            error={!!validationErrors.address}
                                        />
                                    </TextFieldBottomMargin>
                                    <TextFieldBottomMargin>
                                        <TextField
                                            label="infos complémentaires (facultatif)"
                                            initialVisibility={true}
                                            onChange={inputsOnChange(
                                                'information'
                                            )}
                                            value={addressFields.information}
                                            error={
                                                !!validationErrors.information
                                            }
                                            capitalize={'capitalize'}
                                        />
                                    </TextFieldBottomMargin>
                                    <SmallInputsGroup>
                                        <CodePostalWrapper>
                                            <TextField
                                                label="Code postal"
                                                initialVisibility={true}
                                                onChange={onPostalCodeChange}
                                                value={addressFields.postalCode}
                                                error={
                                                    !!validationErrors.postalCode
                                                }
                                            />
                                        </CodePostalWrapper>
                                        <VilleWrapper>
                                            <TextField
                                                label="Ville"
                                                initialVisibility={true}
                                                onChange={inputsOnChange(
                                                    'city'
                                                )}
                                                value={addressFields.city}
                                                error={!!validationErrors.city}
                                                capitalize={'uppercase'}
                                            />
                                        </VilleWrapper>
                                    </SmallInputsGroup>
                                </AddressPersonalInfoLeftGroup>
                                <AddressPersonalInfoRightGroup
                                    specialHeight={
                                        !!validationErrors.postalCode
                                    }
                                >
                                    <TextFieldBottomMargin>
                                        <TextField
                                            label="téléphone mobile"
                                            mask={phoneMask}
                                            initialVisibility={true}
                                            onChange={inputsOnChange('phone')}
                                            value={addressFields.phone}
                                            error={!!validationErrors.phone}
                                            inputmode="numeric"
                                        />
                                    </TextFieldBottomMargin>
                                    <TextFieldBottomMargin>
                                        <TextField
                                            label="adresse email"
                                            initialVisibility={true}
                                            onChange={inputsOnChange('email')}
                                            value={addressFields.email}
                                            error={!!validationErrors.email}
                                        />
                                    </TextFieldBottomMargin>
                                </AddressPersonalInfoRightGroup>
                            </AddressPersonalInfoWrapper>
                            {firstError && (
                                <MessageWrapper>
                                    <Message
                                        close={hideErrorMessage}
                                        icon="error"
                                        hiddenIcon
                                        iconColor="error"
                                        backgroundColor="errorBackground"
                                        textColor="error"
                                        message={firstError}
                                    />
                                </MessageWrapper>
                            )}
                        </AddressPersonalInfoContent>
                        {/* <SubscriptionWrapper>
                            <label>
                                <CustomCheckBox
                                    disabled={false}
                                    checked={addressFields.subscription}
                                    onChange={checkBoxOnChange}
                                />
                                <CustomSpan />
                            </label>
                            <SubscriptionText
                                onClick={checkBoxOnChange}
                                fontSize="16px"
                            >
                                J'accepte qu'AssurLink et son partenaire ATM
                                m'envoient des offres sur leurs produits
                                d’assurances ou toute information sur leurs
                                produits et services par e-mail, sms, par voie
                                postale ou téléphonique.
                            </SubscriptionText>
                        </SubscriptionWrapper> */}
                        <SubscriptionWrapper>
                            <label>
                                <CustomCheckBox
                                    disabled={false}
                                    checked={addressFields.subscriptionEmail}
                                    onChange={handleChangeSubscriptionEmail}
                                />
                                <CustomSpan />
                            </label>
                            <SubscriptionText
                                onClick={handleChangeSubscriptionEmail}
                                fontSize="16px"
                            >
                                J'accepte de recevoir des offres de MMA par
                                email
                            </SubscriptionText>
                        </SubscriptionWrapper>
                        <SubscriptionWrapper>
                            <label>
                                <CustomCheckBox
                                    disabled={false}
                                    checked={addressFields.subscriptionSms}
                                    onChange={handleChangeSubscriptionSms}
                                />
                                <CustomSpan />
                            </label>
                            <SubscriptionText
                                onClick={handleChangeSubscriptionSms}
                                fontSize="16px"
                            >
                                J'accepte de recevoir des offres de MMA par SMS{' '}
                            </SubscriptionText>
                        </SubscriptionWrapper>
                        <ButtonWrapper>
                            <Button
                                fullWidth
                                variant="dark"
                                active={true}
                                disabled={false}
                                size="sH"
                                onClick={submitAddressInfo}
                            >
                                Étape suivante
                            </Button>
                        </ButtonWrapper>
                    </QuizStepWrapper>
                </Container>
            </Page>
            <Footer />
        </PageContainer>
    );
};

export default withGoogleMapsAPI(AddressPersonalInfo);
