import gql from "graphql-tag";

const EXTERNAL_REQUEST_VOUCHER = gql`
  mutation externalRequestVoucher($model: ExternalRequestVoucherModel) {
    externalRequestVoucher(model: $model) {
      voucher 
			url
    }
  }
`;

export default EXTERNAL_REQUEST_VOUCHER;