import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { QuestionnaireContext } from '../../store/context/questionnaire/questionnaire';
import QuestionnaireHeader from './components/Header';
import Wrapper from './components/Wrapper';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

const Questionnaire = () => {
    const { path } = useRouteMatch();
    const {
        firstStepCompleted,
        secondStepCompleted,
        thirdStepCompleted,
    } = useContext(QuestionnaireContext);

    return (
        <QuestionnaireHeader>
            <Wrapper>
                <Switch>
                    <Route exact path={`${path}/step1`} component={Step1} />

                    {firstStepCompleted ? (
                        <Route exact path={`${path}/step2`} component={Step2} />
                    ) : (
                        <Redirect to={`${path}/step1`} />
                    )}

                    {secondStepCompleted ? (
                        <Route exact path={`${path}/step3`} component={Step3} />
                    ) : (
                        <Redirect to={`${path}/step2`} />
                    )}

                    {thirdStepCompleted ? (
                        <Route exact path={`${path}/step4`} component={Step4} />
                    ) : (
                        <Redirect to={`${path}/step3`} />
                    )}

                    <Redirect to={`${path}/step1`} />
                </Switch>
            </Wrapper>
        </QuestionnaireHeader>
    );
};

export default Questionnaire;
