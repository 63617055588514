import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Footer from '../../../shared/elements/Footer';
import {
    Button,
    Datepicker,
    Logo,
    Message,
    Stepper,
    TextField,
    Tooltip,
} from '../../../shared/elements/index';
import { stepperMock } from '../../../shared/elements/Stepper/mock';
import { mergeIbanValue } from '../../../shared/helpers/index';
import { useRedirection } from '../../../shared/helpers/useRedirection';
import Page, {
    Container,
    PageContainer,
} from '../../../shared/layout/Page/index';
import { ibanMask, paymentSchema } from '../../../shared/validation/payment';
import { AddressContext } from '../../../store/context/subscription/address';
import { DriversContext } from '../../../store/context/subscription/drivers';
import { InsuranceContext } from '../../../store/context/subscription/insurance';
import {
    PaymentContext,
    PaymentFrequency,
    Tariff,
} from '../../../store/context/subscription/payment';
// import { PersonalTransportContext } from '../../../store/context/subscription/transport';
import { ErrorMessage } from '../../questionnaire/Step3/index';
import { QuizStepTitle, QuizStepWrapper } from '../elements';
import {
    BottomDescription,
    ButtonContainer,
    CalendarWrapper,
    ContentWrapper,
    DirectionContainer,
    ElementsWrapper,
    HiddenWrapper,
    MessageWrapper,
    PaymentDirectionWrapper,
    PaymentPrice,
    PaymentSettingsWrapper,
    SuccessMessageWrapper,
    TextFieldWrapper,
    TooltipWrapper,
} from './elements';

type IBAN = string;

export type PaymentInfoFields = {
    tariff: Tariff;
    frequency: PaymentFrequency;
    startDate: Date;
    userName: string;
    firstName?: string;
    lastName?: string;
    iban: IBAN;
    voucher: string;
    discount: string;
    formula: string;
};

type PaymentValidationErrors = {
    userName: string;
    iban: string;
    date: string;
    formula: string;
};

const Payment = () => {
    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    /**
     * Logic related to the routing actions.
     */
    const { push } = useHistory();
    const [nextRoute, prevRoute] = useRedirection({
        currentRoute: 'payment',
        nextRoute: 'summary',
        prevRoute: 'address',
    });
    const back = useCallback(() => push(prevRoute), [prevRoute, push]);

    /**
     * Logic related to the storing page-data in the state.
     */
    // const { completed: transportStepCompleted } = useContext(
    //     PersonalTransportContext
    // );
    const { completed: addressStepCompleted } = useContext(AddressContext);
    const {
        iban,
        startDate,
        userName,
        tariff,
        frequency,
        voucher,
        discount,
        formula,
        savePaymentInfo,
        completed: paymentStepCompleted,
    } = useContext(PaymentContext);
    const { drivers, completed: driversStepCompleted } = useContext(
        DriversContext
    );
    const { regular, premium, completed: insuranceStepCompleted } = useContext(
        InsuranceContext
    );
    const [paymentFields, setPaymentFields] = useState<PaymentInfoFields>({
        iban,
        startDate,
        userName: !!userName
            ? userName
            : `${drivers[0].firstName} ${drivers[0].lastName}`,
        tariff,
        frequency,
        voucher,
        discount,
        formula,
    });
    const flowComppleted: boolean = useMemo(
        () =>
            // transportStepCompleted &&
            insuranceStepCompleted &&
            driversStepCompleted &&
            addressStepCompleted &&
            paymentStepCompleted,
        [
            addressStepCompleted,
            driversStepCompleted,
            insuranceStepCompleted,
            paymentStepCompleted,
        ]
    );
    const pickSingleTariff = useCallback(() => {
        setPaymentFields({
            ...paymentFields,
            frequency: PaymentFrequency.monthly,
            tariff: Tariff.single,
        });
    }, [paymentFields]);
    const pickMultipleTariff = useCallback(() => {
        setPaymentFields({
            ...paymentFields,
            frequency: PaymentFrequency.yearly,
            tariff: Tariff.multiple,
        });
    }, [paymentFields]);
    /**
     * Logic related to the setting data in the context and move forward to the next step.
     */

    const savePaymentInfoContext = useCallback(() => {
        const selectedFormula: string = regular.selected
            ? 'Responsabilité civile EDPM'
            : 'Responsabilité civile EDPM + Protection du conducteur';
        savePaymentInfo({
            ...paymentFields,
            iban: mergeIbanValue(paymentFields.iban),
            completed: true,
            formula: selectedFormula,
        });
        if (!flowComppleted) push(nextRoute);
        push('/subscription/summary');
    }, [
        flowComppleted,
        savePaymentInfo,
        paymentFields,
        push,
        nextRoute,
        regular,
    ]);

    /**
     * Error message.
     */

    const [error, setError] = useState<ErrorMessage>({
        display: false,
        message:
            "L'IBAN que vous avez saisi n'est pas valide. Merci de réessayer.",
    });
    const hideErrorMessage = useCallback(
        () => setError({ ...error, display: false }),
        [error]
    );
    const showErrorComponent = error.display && !!error.message;

    const [success, setSuccess] = useState<ErrorMessage>({
        display: false,
        message:
            "La garantie ne prendra effet qu'à partir de la signature électronique du contrat.",
    });
    const hideSuccessMessage = useCallback(
        () => setSuccess({ ...success, display: false }),
        [success]
    );
    const showSuccessComponent = success.display && !!success.message;

    const inputsOnChange = useCallback(
        (field) => (event: ChangeEvent<HTMLInputElement>) => {
            setSuccess({ display: true, message: success.message });
            const value = event.target.value;

            setPaymentFields((prev) => ({ ...prev, startDate: new Date() }));
            if (field === 'iban') {
                return setPaymentFields((prev) => ({
                    ...prev,
                    iban: value.toUpperCase(),
                }));
            }

            setPaymentFields((prev) => ({
                ...prev,
                [field]: value,
            }));
        },
        [success]
    );

    /**
     * Logic related to the API requests.
     */
    const initialPaymentErrors: PaymentValidationErrors = {
        userName: '',
        iban: '',
        date: '',
        formula: '',
    };
    const [validationErrors, setValidationErrors] = useState({
        ...initialPaymentErrors,
    });

    const datepickerOnChange = useCallback((value: Date) => {
        return setPaymentFields((prev) => ({
            ...prev,
            startDate: value,
        }));
    }, []);

    const submitPaymentInfo = useCallback(async () => {
        // TODO: Add validation.
        const validationPassed = await paymentSchema
            .validate(
                {
                    iban: mergeIbanValue(paymentFields.iban),
                    userName: paymentFields.userName,
                    date: paymentFields.startDate,
                },
                { abortEarly: false }
            )
            .then(() => {
                setValidationErrors({ ...initialPaymentErrors });
                return true;
            })
            .catch((e) => {
                const errors: any = { ...initialPaymentErrors };
                e.inner.map(
                    (err: yup.ValidationError) =>
                        (errors[err.path] = err.message)
                );
                setValidationErrors({ ...errors });
                return false;
            });

        if (validationPassed) {
            savePaymentInfoContext();
        }

        if (paymentFields.iban.length < 1 || !!validationErrors.iban) {
            setError({ display: true, message: error.message });
        } else {
            setError({ ...error, display: false });
        }
    }, [
        initialPaymentErrors,
        paymentFields,
        savePaymentInfoContext,
        error,
        setError,
        validationErrors.iban,
    ]);

    /**
     * General logic and variables needed to define some render settings.
     */
    const plan = regular.selected ? regular : premium;
    const singleTariffIsActive = paymentFields.tariff === 'single';
    const multipleTariffIsActive = paymentFields.tariff === 'multiple';
    const monthlyPrice = plan.monthlyPrice;
    const yearlyPrice = plan.yearlyPrice;
    const months = plan.discount ? 12 - +plan.discount.discountedMonths : 12;
    const monthlyPriceValue: string = monthlyPrice.toFixed(2).replace('.', ',');
    const yearlyPriceValue: string = yearlyPrice.toFixed(2).replace('.', ',');

    return (
        <PageContainer>
            <Logo showGoBack={true} goBack={back} />
            <Stepper steps={stepperMock.steps} currentlySelected={2} />
            <Page>
                <Container>
                    <QuizStepWrapper>
                        <QuizStepTitle>
                            Plus que 2 étapes pour finaliser votre adhésion!
                            <br />
                            Veuillez renseigner ci-dessous vos informations de
                            paiement
                        </QuizStepTitle>
                        <ContentWrapper>
                            <PaymentDirectionWrapper>
                                <PaymentSettingsWrapper
                                    selected={singleTariffIsActive}
                                    first
                                    onClick={pickSingleTariff}
                                >
                                    Je préfère payer{' '}
                                    <PaymentPrice>
                                        {monthlyPriceValue} € par mois{' '}
                                    </PaymentPrice>
                                    pendant {months} mois
                                </PaymentSettingsWrapper>
                                <PaymentSettingsWrapper
                                    selected={multipleTariffIsActive}
                                    onClick={pickMultipleTariff}
                                >
                                    Je préfère payer en une seule fois
                                    <PaymentPrice>
                                        {' '}
                                        {yearlyPriceValue} € pour l’année
                                    </PaymentPrice>
                                </PaymentSettingsWrapper>
                            </PaymentDirectionWrapper>
                            <BottomDescription>
                                Le contrat proposé est conclu pour une durée de
                                12 mois à compter de sa date d'effet et se
                                renouvellera automatiquement pour une période de
                                12 mois chaque année à son échéance principale
                                sauf résiliation par vous ou l'assureur.
                            </BottomDescription>
                            <BottomDescription>
                                Vous disposez d'un délai de quatorze jours pour
                                exercer votre droit de rétractation d'un contrat
                                conclu à distance, à la suite d'un démarchage
                                téléphonique ou hors établissement, sans avoir à
                                motiver votre décision ni à supporter d'autres
                                coûts que ceux prévus aux articles L221-23, 24
                                et 25.
                            </BottomDescription>
                            <CalendarWrapper className="black-placeholder">
                                <Datepicker
                                    onChange={datepickerOnChange}
                                    label="date d’effet du contrat"
                                    value={new Date()}
                                    error={!!validationErrors.date}
                                    readOnly
                                    disabled
                                    calendar
                                    dateFormat="dd/MM/yyyy"
                                />
                            </CalendarWrapper>
                            <DirectionContainer>
                                <TextFieldWrapper>
                                    <TextField
                                        initialVisibility={true}
                                        onChange={inputsOnChange('userName')}
                                        label="PRENOM ET NOM DU TITULAIRE DU COMPTE "
                                        value={paymentFields.userName}
                                        error={!!validationErrors.userName}
                                    />
                                </TextFieldWrapper>
                                <TextFieldWrapper>
                                    <TextField
                                        initialVisibility={true}
                                        onChange={inputsOnChange('iban')}
                                        label="IBAN"
                                        mask={ibanMask}
                                        placeholder="FRxx xxxx xxxx xxxx xxxx xxxx xxx"
                                        value={paymentFields.iban}
                                        error={!!validationErrors.iban}
                                    />

                                    {/* <TooltipWrapper>
                                        <Tooltip
                                            rightPosition="top"
                                            ibanLocker
                                            text={
                                                'Saisissez dans un 1er temps votre IBAN ici. Nous vous demanderons de confirmer votre autorisation de prélèvement par signature électronique dans quelques instants. Aucun prélèvement ne pourra survenir sans cette signature'
                                            }
                                        />
                                        <Tooltip
                                            rightPosition="top"
                                            text={
                                                '27 caractères pour les comptes tenus en France, commençant par FR suivi de 2 caractères puis des 23 chiffres correspondant au code de l’établissement bancaire, code du guichet, n° de compte et la clé RIB'
                                            }
                                        />
                                    </TooltipWrapper> */}
                                </TextFieldWrapper>
                                {showSuccessComponent ? (
                                    <>
                                        <SuccessMessageWrapper>
                                            <Message
                                                close={hideSuccessMessage}
                                                icon="warning"
                                                iconColor="primaryText"
                                                backgroundColor="secondary"
                                                textColor="success"
                                                message={success.message}
                                            />
                                        </SuccessMessageWrapper>
                                    </>
                                ) : null}
                            </DirectionContainer>
                            <BottomDescription>
                                Les primes d'assurances seront prélevées sur
                                votre compte après la signature du contrat.
                            </BottomDescription>
                        </ContentWrapper>
                        <ElementsWrapper>
                            <ButtonContainer>
                                <Button
                                    fullWidth={true}
                                    variant="dark"
                                    active={
                                        !!paymentFields.userName &&
                                        !!paymentFields.iban
                                    }
                                    size="auto"
                                    disabled={false}
                                    onClick={submitPaymentInfo}
                                >
                                    Valider
                                </Button>
                            </ButtonContainer>
                            {showErrorComponent && (
                                <>
                                    <HiddenWrapper />
                                    <MessageWrapper>
                                        <Message
                                            close={hideErrorMessage}
                                            icon="error"
                                            iconColor="error"
                                            backgroundColor="errorBackground"
                                            textColor="error"
                                            message={error.message}
                                        />
                                    </MessageWrapper>
                                </>
                            )}
                        </ElementsWrapper>
                    </QuizStepWrapper>
                </Container>
            </Page>
            <Footer />
        </PageContainer>
    );
};

export default Payment;
