import React from 'react';
import Slider from 'react-slick';
import {
    PlansSectionWrapper,
    PlansSectionTitle,
    PlansSectionSubTitle,
    PlansWrapper,
    PlansFeaturesWrapperDesktop,
    PlansFeaturesTitle,
    PlansFeatures,
    PlanFeature,
    PlanCardsWrapper,
    MobileSliderWrapper,
    CustomLinkWrapper,
    LinksWrraper,
} from './elements';
import { plansSliderSettings } from '../sliderSettings';
import CardDesktop from './PlanCardDesktop';
import CardMobile from './PlanCardMobile';
import { CustomLink } from '../../../../shared/elements';
import { ExternalLinks } from '../../../../config/variables';

const PlansSection: React.FC = () => {
    const href =
        'https://docs.assur-link.com/wp-content/uploads/2021/04/DG-EDPM-EQEDM0841C-042021.pdf';
    const linksWrraper = (
        <>
            <CustomLink
                icon
                font="10px"
                href=""
                // href={href}
                target="_blank"
            >
                CONSULTER LES DISPOSITIONS GÉNÉRALES
            </CustomLink>
            <CustomLink
                icon
                font="10px"
                href=""
                // href={ExternalLinks.WORDPRESS_INFORMATION_LINK}
                target="_blank"
            >
                VOIR LA FICHE INFORMATION PRODUIT
            </CustomLink>
        </>
    );
    return (
        <PlansSectionWrapper>
            <PlansSectionTitle>
                Deux formules pour vous couvrir
            </PlansSectionTitle>
            <PlansSectionSubTitle>
                Vous possédez plusieurs smartphones, tablettes, ordinateurs,
                appareils photos… au sein de votre foyer. Pour les utiliser en
                toute sérénité, protégez-les contre les risques de casse,
                d’oxydation ou de vol.
            </PlansSectionSubTitle>
            <PlansWrapper>
                <PlansFeaturesWrapperDesktop>
                    <PlansFeaturesTitle>Les garanties</PlansFeaturesTitle>
                    <PlansFeatures>
                        <PlanFeature>Casse toutes causes</PlanFeature>
                        <PlanFeature>Vol toutes causes</PlanFeature>
                        <PlanFeature>Oxydation toutes causes</PlanFeature>
                        <PlanFeature bold={true}>Plafond</PlanFeature>
                    </PlansFeatures>
                    <CustomLinkWrapper>{linksWrraper}</CustomLinkWrapper>
                </PlansFeaturesWrapperDesktop>
                <PlanCardsWrapper>
                    <CardDesktop
                        key="Standard"
                        tariffName="Standard"
                        checks={[true, true, true]}
                        buttonText="Je m’assure pour"
                        buttonTextBold="9,90 € / mois"
                        price={9.9}
                        wholePrice={113.88}
                        sinistrePrice={400}
                        sinistreQuant={1}
                        url="/questionnaire"
                    />
                    <CardDesktop
                        key="Premium"
                        tariffName="Premium"
                        checks={[true, true, true]}
                        buttonText="Je m’assure pour"
                        buttonTextBold="19,90 € / mois"
                        price={19.9}
                        wholePrice={233.88}
                        sinistrePrice={1000}
                        sinistreQuant={2}
                        url="/questionnaire"
                    />
                </PlanCardsWrapper>
            </PlansWrapper>
            <MobileSliderWrapper>
                <Slider {...plansSliderSettings}>
                    <CardMobile
                        tariffName="Standard"
                        checks={[true, true, true, false]}
                        buttonText="Je m’assure pour 7,50 € / mois"
                        price={7.5}
                        wholePrice={84.99}
                        url="/questionnaire"
                    />
                    <CardMobile
                        checks={[true, true, true, true]}
                        tariffName="Premium"
                        buttonText="Je m’assure pour 11,20 € / mois"
                        price={11.2}
                        wholePrice={129.9}
                        url="/questionnaire"
                    />
                </Slider>
                <LinksWrraper>{linksWrraper}</LinksWrraper>
            </MobileSliderWrapper>
        </PlansSectionWrapper>
    );
};

export default PlansSection;
