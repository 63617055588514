import React, { FC } from 'react';
import { ButtonContainer, ButtonContainerProps } from './elements';

type ButtonProps = ButtonContainerProps & {
    children: any;
    onClick(): void;
};

const Button: FC<ButtonProps> = ({
    className,
    variant,
    size,
    fullWidth,
    disabled,
    active,
    onClick,
    children,
}) => {
    return (
        <ButtonContainer
            className={className}
            onClick={onClick}
            active={active}
            variant={variant}
            size={size}
            fullWidth={fullWidth}
            disabled={disabled}
        >
            {children}
        </ButtonContainer>
    );
};

export default Button;
