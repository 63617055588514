import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AddressContext } from '../../store/context/subscription/address';
import { DriversContext } from '../../store/context/subscription/drivers';
import { InsuranceContext } from '../../store/context/subscription/insurance';
import { PaymentContext } from '../../store/context/subscription/payment';
import { SummaryContext } from '../../store/context/subscription/summary';
// import { PersonalTransportContext } from '../../store/context/subscription/transport';
import AddressPersonalInfo from './address/index';
import Drivers from './drivers/index';
import Insurance from './insurance/index';
import Payment from './payment/index';
import Redirection from './redirection';
import Summary from './summary/index';
import TransporterInfo from './transport';

const Subscription = () => {
    const { path } = useRouteMatch();

    // const { completed: transportStepCompleted } = useContext(
    //     PersonalTransportContext
    // );
    const { completed: addressStepCompleted } = useContext(AddressContext);
    const { completed: driversStepCompleted } = useContext(DriversContext);
    const { completed: insuranceStepCompleted } = useContext(InsuranceContext);
    const { completed: paymentStepCompleted } = useContext(PaymentContext);
    const { completed: summaryStepCompleted } = useContext(SummaryContext);

    return (
        <Switch>
            <Route path={`${path}/insurance`} component={Insurance} />

            {insuranceStepCompleted ? (
                <Route path={`${path}/drivers`} component={Drivers} />
            ) : (
                <Redirect to={`${path}/insurance`} />
            )}

            {/* {transportStepCompleted ? (
                <Route path={`${path}/drivers`} component={Drivers} />
            ) : (
                <Redirect to={`${path}/transport`} />
            )} */}

            {/* {insuranceStepCompleted ? (
                <Route path={`${path}/transport`} component={TransporterInfo} />
            ) : (
                <Redirect to={`${path}/insurance`} />
            )}

            {transportStepCompleted ? (
                <Route path={`${path}/drivers`} component={Drivers} />
            ) : (
                <Redirect to={`${path}/transport`} />
            )} */}

            {driversStepCompleted ? (
                <Route
                    path={`${path}/address`}
                    component={AddressPersonalInfo}
                />
            ) : (
                <Redirect to={`${path}/drivers`} />
            )}

            {addressStepCompleted ? (
                <Route path={`${path}/payment`} component={Payment} />
            ) : (
                <Redirect to={`${path}/address`} />
            )}

            {paymentStepCompleted ? (
                <Route path={`${path}/summary`} component={Summary} />
            ) : (
                <Redirect to={`${path}/payment`} />
            )}

            {summaryStepCompleted ? (
                <Route path={`${path}/redirection`} component={Redirection} />
            ) : (
                <Redirect to={`${path}/summary`} />
            )}

            <Redirect to={`${path}/transport`} />
        </Switch>
    );
};

export default Subscription;
