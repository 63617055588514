import React, { ChangeEvent, FC } from 'react';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme';
import { DefaultTransition } from '../../styles';

export type CustomCheckBoxProps = {
    onChange(evt: ChangeEvent<HTMLInputElement>): void;
    checked: boolean;
    disabled?: boolean;
};
type CheckBoxProps = CustomCheckBoxProps &
    StyledThemePropsType & {
        icon: string;
    };

const CheckBox = styled.input<CheckBoxProps>`
    ${({ theme, icon }: CheckBoxProps) => css`
        display: none;
        border: 1px solid ${theme.colors.container};
        + span {
            ${DefaultTransition};
            width: 20px;
            height: 20px;
            border: 1px solid ${theme.colors.borderLight};
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            overflow: hidden;
        }
        & + span::before {
            ${DefaultTransition};
            display: block;
            content: url('${theme.icons[icon]}');
            opacity: 0;
        }

        &:checked {
            + span {
                border: 1px solid ${theme.colors.button};
                background-color: ${theme.colors.button};
            }

            & + span::before {
                opacity: 1;
            }
        }
    `}
`;

const CustomCheckBox: FC<CustomCheckBoxProps> = ({
    onChange,
    checked,
    disabled,
}) => (
    <CheckBox
        type="checkbox"
        icon="inputCheck"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
    />
);

export default CustomCheckBox;
