import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { headerRoutes } from "../../routing/headerRoutes";
import Footer from "../../shared/elements/Footer/index";
import { GreenButtonWrapper } from "../../shared/elements/FooterMessageBlock/elements";
import FooterMessageBlock from "../../shared/elements/FooterMessageBlock/index";
import { Button, Header } from "../../shared/elements/index";
import Page from "../../shared/layout/Page/index";
import {
  ErrorContentWrapper,
  ErrorDescription,
  ErrorPageWrapper,
  ErrorTitle,
  FluidErrorPageContainer,
  FluidPageContainer,
} from "./elements";

const ErrorPage = () => {
  /**
   * Logic related to the routing actions.
   */
  const { push } = useHistory();
  const home = useCallback(() => push("/"), [push]);

  return (
    <ErrorPageWrapper>
      <FluidErrorPageContainer>
        <Header
          menuButtons={headerRoutes}
        />
        <Page>
          <FluidPageContainer>
            <ErrorContentWrapper>
              <ErrorTitle>
							Un peu de patience
              </ErrorTitle>
              <ErrorDescription>
							Aucune promo n'est pour l'instant disponible
              </ErrorDescription>
              <GreenButtonWrapper>
                <Button fullWidth={true} variant="dark" size="sH" disabled={false} onClick={home}>
                  Retour
                </Button>
              </GreenButtonWrapper>
            </ErrorContentWrapper>
          </FluidPageContainer>
        </Page>
        <FooterMessageBlock />
        <Footer />
      </FluidErrorPageContainer>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
