import React from 'react';
import { QuestionnaireStep2Options } from '../types';

export const FirstStepError = (
    redirect?: () => void,
    onResetError?: () => void
) => {
    return (
        <span>Désolé mais notre offre n’est pas adaptée à votre besoin </span>
    );
};

export const SecondStepError = (
    redirect?: () => void,
    onResetError?: () => void
) => {
    return (
        <span>Désolé mais notre offre n’est pas adaptée à votre besoin</span>
    );
};

export const ThirdStepError = (
    redirect?: () => void,
    onResetError?: () => void
) => {
    return (
        <span>
            Désolé, notre offre n'est pas adaptée à votre besoin. Nous vous
            invitons à consulter la documentation ci-après afin de comparer nos
            couvertures à celles de votre contrat d'assurance (consulter l'IPID)
        </span>
    );
};

export const FifthStepError = () => {
    return <span>Vous devez certifier vos réponses avant de poursuivre</span>;
};

export const dialogQuestionsOptions: QuestionnaireStep2Options[] = [
    {
        id: 1,
        name: 'Oui',
    },
    {
        id: 2,
        name: 'Non',
    },
];
