import { Vehicles } from "../../../questionnaire/configuration/vehicles";
import { TransportCardProps } from "./TransportCard";

export const homeTransportSlides: TransportCardProps[] = [
  {
    icon: Vehicles.assures1,
    name: "Téléphone portable",
    description: "Equipements incontournables, inutile de vous présenter nos compagnons du quotidien",
  },
  {
    icon: Vehicles.assures2,
    name: "Tablette tactile ",
    description: "Naviguer sur le web ou jouer en famille, nos tablettes sont nos outils de divertissement du quotidien ",
  },
  {
    icon: Vehicles.assures3,
    name: "Ordinateur portable",
    description: "Pour les devoirs des enfants, pour les jeux en ligne ou pour la navigation web, le plus polyvalent des produits nomades",
  },
  {
    icon: Vehicles.assures4,
    name: "Console de jeux ",
    description: "Le divertissement en famille par excellence",
  },
  {
    icon: Vehicles.protection1,
    name: "TV Connectée ",
    description: "Cinéma ou télé à la maison, la TV connectée est notre fenêtre sur le grand large ",
  },
];
