import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import React, {
    FC,
    useCallback,
    useContext,
    useLayoutEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
    ValidateQuestionAnswerVariables,
    ValidateQuestionMutationResponse,
} from '../../../api/interfaces';
import VALIDATE_QUESTION_ANSWER from '../../../api/mutations/validateQuestionAnswer';
import { Message, Tooltip } from '../../../shared/elements/index';
import { useRedirection } from '../../../shared/helpers';
import {
    QuestionnaireContext,
    QuestionnaireDate,
} from '../../../store/context/questionnaire/questionnaire';
import {
    dialogQuestionsOptions,
    SecondStepError,
} from '../configuration/index';
import {
    ContentHeader,
    DialogButton,
    DialogButtonsContainer,
    DialogButtonSpacing,
    DialogPagesContainer,
    TooltipWrapper,
    TransportIcon,
    MessageWrapper,
    HiddenWrapper,
    ContentHeaderWrapper,
} from '../elements';
import { ErrorMessage } from '../Step3';
import { QuestionnaireStep2Options } from '../types';
export type DialogStepProps = {
    error(): JSX.Element | string;
    options: QuestionnaireStep2Options;
};

const Step2: FC<DialogStepProps> = () => {
    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    const { push } = useHistory();
    const [nextRoute] = useRedirection({
        currentRoute: 'step2',
        nextRoute: 'step3',
    });

    /**
     * Logic related to the storing page-data in the state.
     */
    const { saveQuestionnaireInfo, ...questionnaireContextInfo } = useContext(
        QuestionnaireContext
    );

    const [questionnaireInfo] = useState<QuestionnaireDate>({
        ...questionnaireContextInfo,
    });

    const options: QuestionnaireStep2Options[] = [...dialogQuestionsOptions];

    /**
     * Logic related to the storing page-data in the state.
     */
    const [error, setError] = useState<ErrorMessage>({
        display: false,
        message: SecondStepError(),
    });

    const hideErrorMessage = useCallback(
        () => setError({ ...error, display: false }),
        [error]
    );

    /**
     * Logic related to the validation process and page submitting.
     */
    const [validateQuestion] = useMutation<
        ValidateQuestionMutationResponse,
        ValidateQuestionAnswerVariables
    >(VALIDATE_QUESTION_ANSWER);

    const onRedirect = useCallback(() => push('/questionnaire/step1'), [push]);

    const onResetError = useCallback(
        () => setError({ display: false, message: '' }),
        [setError]
    );
    const submit = useCallback(
        (optionId: number) => async () => {
            // TODO: type, variables from props
            await validateQuestion({ variables: { optionId, questionId: 2 } })
                .then(
                    ({
                        data: { validateQuestionAnswer },
                    }: ExecutionResult<ValidateQuestionMutationResponse>) => {
                        if (validateQuestionAnswer.valid) {
                            setError({ ...error, display: false });
                            saveQuestionnaireInfo({
                                ...questionnaireInfo,
                                secondStepCompleted: true,
                            });
                            push(nextRoute);
                        } else {
                            setError({
                                display: true,
                                message: SecondStepError(
                                    onRedirect,
                                    onResetError
                                ),
                            });
                        }
                    }
                )
                .catch((e) => ({ e }));
        },
        [
            error,
            nextRoute,
            push,
            questionnaireInfo,
            saveQuestionnaireInfo,
            validateQuestion,
            onRedirect,
            onResetError,
        ]
    );

    /**
     * General logic and variables needed to define render of something.
     */
    const showErrorComponent = !!(error.display && error.message);

    return (
        <>
            <ContentHeaderWrapper>
                <ContentHeader>
                    Vos produits nomades sont exposés aux risques de vol, casse
                    ou oxydation?
                </ContentHeader>
                {/* <TooltipWrapper>
                    <Tooltip
                        rightPosition="bottom"
                        text={`Le contrat couvre les véhicules homologués, certifiés CE, conformes à la réglementation française et européenne en vigueur, et non débridés. Si votre véhicule a été acheté avant le
              26/10/2019 avec une vitesse sortie d’usine dépassant les 25 km/h, vous devez être en possession du certificat de
              mise en conformité (bridage) émanant du distributeur ou du revendeur.`}
                    />
                </TooltipWrapper> */}
            </ContentHeaderWrapper>
            <DialogPagesContainer>
                <DialogButtonsContainer>
                    {/* In future refactor to mapping. */}
                    <DialogButton
                        fullWidth={true}
                        size="s"
                        variant="white"
                        disabled={false}
                        onClick={submit(1)}
                        primary={true}
                    >
                        {options[0].name}
                    </DialogButton>
                    <DialogButtonSpacing>
                        <DialogButton
                            fullWidth={true}
                            size="s"
                            variant="white"
                            disabled={false}
                            onClick={submit(2)}
                        >
                            {options[1].name}
                        </DialogButton>
                    </DialogButtonSpacing>
                    <TransportIcon icon="protection2" />
                </DialogButtonsContainer>
                {showErrorComponent ? (
                    <>
                        <HiddenWrapper />
                        <MessageWrapper>
                            <Message
                                close={hideErrorMessage}
                                icon="error"
                                iconColor="error"
                                backgroundColor="errorBackground"
                                textColor="error"
                                message={error.message}
                            />
                        </MessageWrapper>{' '}
                    </>
                ) : null}
            </DialogPagesContainer>
        </>
    );
};

export default Step2;
