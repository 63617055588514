import React from "react";
import { CustomIcon } from "../index";
import { FooterLink, FooterNavContent, FooterNavWrapper, LinkDivider, SocialLinks } from "./elements";

const Footer = () => {
  return (
    <FooterNavWrapper>
      <FooterNavContent>
        {/* TODO: Revisit to array mapping. */}
        <FooterLink
          href="https://docs.assur-link.com/wp-content/uploads/2021/04/CGU-site-de-souscription-AssurLink.pdf"
          target="_blank"
        >
          Conditions Générales d’Utilisation
        </FooterLink>
        <LinkDivider />
        <FooterLink
          href="https://docs.assur-link.com/wp-content/uploads/2021/03/Mentions-legales-pour-site-souscription-assur-link-com-ref-ML-assur-link-com-3-3-2021.pdf"
          target="_blank"
        >
          Mentions légales
        </FooterLink>
        <LinkDivider />
        <FooterLink
          href="https://docs.assur-link.com/wp-content/uploads/2021/03/protection-des-donnees-personnelles-site-RC-EDPM.pdf"
          target="_blank"
        >
          Politique de protection des données personnelles
        </FooterLink>
        <LinkDivider />
        <SocialLinks>
          <FooterLink href="https://www.linkedin.com/company/assurlink/" target="_blank">
            <CustomIcon iconColor="white" icon="linkedIn" height="16" width="16" />
          </FooterLink>
        </SocialLinks>
      </FooterNavContent>
    </FooterNavWrapper>
  );
};

export default Footer;
