import React, {
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
    useMemo,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
    GetProductsQueryResult,
    GetQuestionsQueryResult,
    GetVoucherQueryResult,
    GetVoucherQueryVariables,
    ProductTypeEntity,
    ValidateQuestionAnswerVariables,
    ValidateQuestionMutationResponse,
} from '../../../api/interfaces/index';
import GET_PRODUCTS from '../../../api/queries/getProducts';
import GET_QUESTIONS from '../../../api/queries/getQuestions';
import { Loader } from '../../../shared/elements/Loader/index';
import { useRedirection } from '../../../shared/helpers/index';
import {
    QuestionnaireContext,
    QuestionnaireDate,
} from '../../../store/context/questionnaire/questionnaire';
import { PersonalTransportContext } from '../../../store/context/subscription/transport';
import { IconsThemeContext, selectedTheme } from '../../../themeProviderHOC';
import { Vehicles } from '../configuration/vehicles';
import {
    ContentHeader,
    ContentHeaderWrapper,
    CustomSvg,
    DialogButton,
    DialogButtonsContainer,
    DialogButtonSpacing,
    DialogPagesContainer,
    HiddenWrapper,
    MessageWrapper,
    TransportIcon,
} from '../elements';
import { QuestionnaireStep2Options, RouteParams } from '../types';
import GET_VOUCHER from '../../../api/queries/getVoucher';
import { AddressContext } from '../../../store/context/subscription/address';
import { PaymentContext } from '../../../store/context/subscription/payment';
import { Message } from '../../../shared/elements';
import { dialogQuestionsOptions, FirstStepError } from '../configuration';
import { ErrorMessage } from '../Step3';
import VALIDATE_QUESTION_ANSWER from '../../../api/mutations/validateQuestionAnswer';
import { ExecutionResult } from 'graphql';

const Step1 = () => {
    // useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    // const { path } = useRouteMatch<RouteParams>();
    // const { push, location } = useHistory();
    // const modelIndex = Number(path.split('/questionnaire/step')[1]);
    // const [nextRoute] = useRedirection({
    //     currentRoute: 'step1',
    //     nextRoute: 'step2',
    // });
    // const stepRedirection = useCallback(
    //     () => setTimeout(() => push(nextRoute), 500),
    //     [nextRoute, push]
    // );
    // const ContextIcons = useContext(IconsThemeContext);
    // const { savePersonalTransportInfo, ...other } = useContext(
    //     PersonalTransportContext
    // );
    // const { saveAddressInfo, ...addressParams } = useContext(AddressContext);

    // const { savePaymentInfo, ...paymentParams } = useContext(PaymentContext);

    // const voucher = new URLSearchParams(location.search).get('voucherId');
    // const { data, loading: loadingVoucher } = useQuery<
    //     GetVoucherQueryResult,
    //     GetVoucherQueryVariables
    // >(GET_VOUCHER, {
    //     variables: { voucher },
    //     fetchPolicy: 'cache-and-network',
    //     skip: Boolean(!voucher),
    // });
    // const [state, setState] = useState({
    //     stateLoading: false,
    // });
    // useEffect(() => {
    //     if (loadingVoucher || state?.stateLoading || !data) return;
    //     const {
    //         productBrandId,
    //         productModel,
    //         productSerial,
    //         productTypeId,
    //         email,
    //         mobile,
    //         lastName,
    //         firstName,
    //     } = data.voucher;
    //     setState({ ...state, stateLoading: true });
    //     savePersonalTransportInfo({
    //         ...other,
    //         type: productTypeId,
    //         brand: productBrandId,
    //         model: productModel,
    //         serial: productSerial,
    //     });
    //     saveAddressInfo({
    //         ...addressParams,
    //         email,
    //         phone: `0${mobile}`.replace(/\d{2}(?=.)/g, '$& '),
    //     });
    //     savePaymentInfo({
    //         ...paymentParams,
    //         discount: null,
    //         voucher,
    //         userName: firstName ? `${lastName} ${firstName}` : '',
    //         firstName: firstName || '',
    //         lastName: lastName || '',
    //     });
    // }, [
    //     state,
    //     addressParams,
    //     data,
    //     loadingVoucher,
    //     other,
    //     paymentParams,
    //     saveAddressInfo,
    //     savePaymentInfo,
    //     savePersonalTransportInfo,
    //     voucher,
    // ]);

    // /**
    //  * Logic related to the storing page-data in the state.
    //  */

    // const { data: getProducts } = useQuery<GetProductsQueryResult>(
    //     GET_PRODUCTS
    // );

    // const { saveQuestionnaireInfo, ...questionnaireContextInfo } = useContext(
    //     QuestionnaireContext
    // );

    // const [questionnaireInfo] = useState<QuestionnaireDate>({
    //     ...questionnaireContextInfo,
    // });

    // const { loading } = useQuery<GetQuestionsQueryResult>(GET_QUESTIONS, {
    //     fetchPolicy: 'cache-and-network',
    // });

    // useEffect(() => {
    //     document.head.appendChild(
    //         document.createElement('style')
    //     ).innerHTML = `button:before {color: ${selectedTheme.colors.primaryText} !important;}`;
    // }, []);

    // if (loading) {
    //     return <Loader isLoading={loading} />;
    // }

    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    const { push } = useHistory();
    const [nextRoute] = useRedirection({
        currentRoute: 'step1',
        nextRoute: 'step2',
    });

    /**
     * Logic related to the storing page-data in the state.
     */
    const { saveQuestionnaireInfo, ...questionnaireContextInfo } = useContext(
        QuestionnaireContext
    );

    const [questionnaireInfo] = useState<QuestionnaireDate>({
        ...questionnaireContextInfo,
    });

    const options: QuestionnaireStep2Options[] = [...dialogQuestionsOptions];

    /**
     * Logic related to the storing page-data in the state.
     */
    const [error, setError] = useState<ErrorMessage>({
        display: false,
        message: FirstStepError(),
    });

    const hideErrorMessage = useCallback(
        () => setError({ ...error, display: false }),
        [error]
    );

    /**
     * Logic related to the validation process and page submitting.
     */
    const [validateQuestion] = useMutation<
        ValidateQuestionMutationResponse,
        ValidateQuestionAnswerVariables
    >(VALIDATE_QUESTION_ANSWER);

    const onRedirect = useCallback(() => push('/questionnaire/step2'), [push]);

    const onResetError = useCallback(
        () => setError({ display: false, message: '' }),
        [setError]
    );
    const submit = useCallback(
        (optionId: number) => async () => {
            // TODO: type, variables from props
            await validateQuestion({ variables: { optionId, questionId: 2 } })
                .then(
                    ({
                        data: { validateQuestionAnswer },
                    }: ExecutionResult<ValidateQuestionMutationResponse>) => {
                        if (validateQuestionAnswer.valid) {
                            setError({ ...error, display: false });
                            saveQuestionnaireInfo({
                                ...questionnaireInfo,
                                firstStepCompleted: true,
                            });
                            push(nextRoute);
                        } else {
                            setError({
                                display: true,
                                message: FirstStepError(
                                    onRedirect,
                                    onResetError
                                ),
                            });
                        }
                    }
                )
                .catch((e) => ({ e }));
        },
        [
            error,
            nextRoute,
            push,
            questionnaireInfo,
            saveQuestionnaireInfo,
            validateQuestion,
            onRedirect,
            onResetError,
        ]
    );

    /**
     * General logic and variables needed to define render of something.
     */
    const showErrorComponent = !!(error.display && error.message);

    return (
        <>
            <ContentHeaderWrapper>
                <ContentHeader>
                    Vous et votre foyer possédez plusieurs appareils nomades de
                    type téléphones mobiles, PC, tablette?
                </ContentHeader>
            </ContentHeaderWrapper>
            <DialogPagesContainer>
                <DialogButtonsContainer>
                    {/* In future refactor to mapping. */}
                    <DialogButton
                        fullWidth={true}
                        size="s"
                        variant="white"
                        disabled={false}
                        onClick={submit(1)}
                        primary={true}
                    >
                        {options[0].name}
                    </DialogButton>
                    <DialogButtonSpacing>
                        <DialogButton
                            fullWidth={true}
                            size="s"
                            variant="white"
                            disabled={false}
                            onClick={submit(2)}
                        >
                            {options[1].name}
                        </DialogButton>
                    </DialogButtonSpacing>
                    <TransportIcon icon="protection1" />
                </DialogButtonsContainer>
                {showErrorComponent ? (
                    <>
                        <HiddenWrapper />
                        <MessageWrapper>
                            <Message
                                close={hideErrorMessage}
                                icon="error"
                                iconColor="error"
                                backgroundColor="errorBackground"
                                textColor="error"
                                message={error.message}
                            />
                        </MessageWrapper>{' '}
                    </>
                ) : null}
            </DialogPagesContainer>
        </>
    );
};

export default Step1;
