import React from 'react';
import { Container, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const Spacing = styled.div`
    margin-top: 20px;
    width: 100%;
    // TODO: revisit
    //height: 100%;
    display: flex;
    justify-content: center;
    ${media.xl`
    margin-bottom: 200px;
  `};
`;

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    width: 1000px;
    margin: 0;
    background: ${(props) => props.theme.colors.container};
    align-items: center;
    min-height: 270px;
    border-radius: 12px;

    ${media.xl`
    min-height: 430px;
    border-radius: 3px;
  `};
`;

const Wrapper = (props: React.PropsWithChildren<any>) => {
    return (
        <Spacing>
            <StyledContainer>{props.children}</StyledContainer>
        </Spacing>
    );
};

export default Wrapper;
