import React from 'react';
import InputMask from 'react-input-mask';
import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme/index';
import {
    BodyStyles,
    InputLabelTextMobileStyles,
    LabelStyles,
} from '../../typography/index';
import { DatepickerWrapperProps } from '../Datepicker/element';

const TextFieldContainer = styled.div`
    width: 100%;
`;

const LabelContainer = styled.div`
    ${({ theme }: StyledThemePropsType) => css`
        margin-bottom: 5px;
        ${InputLabelTextMobileStyles};
        color: ${theme.colors.darkColor};
        font-weight: bold;
        text-transform: uppercase;

        ${media.lg`
          ${LabelStyles};
          color: ${theme.colors.darkColor};
          font-weight: bold;
     `};
    `};
`;

const InputStyles = css`
    ${BodyStyles};
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
    font-size: 16px;
    &::placeholder {
        color: ${(props: StyledThemePropsType) =>
            props.theme.colors.inputTextCaption};
        font-weight: normal;
        font-style: italic;
    }

    text-transform: ${(props: any) =>
        props.capitalize ? props.capitalize : 'none'};
    background-color: transparent;
    width: 100%;
    font-weight: normal;
    border: none;
    outline: none;

    ${media.lg`
    font-size: 16px;
  `}
`;

const Input = styled(({ isValid, ...props }) => <InputMask {...props} />)`
    ${InputStyles};
`;

export const WrapperDisabled = css<StyledThemePropsType>`
    background: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.disabled};
`;

export const WrapperDisabledDatePicker = css<StyledThemePropsType>`
    background: ${(props: StyledThemePropsType) =>
        props.theme.colors.container};
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.placeholder};
`;

export const WrapperError = css<StyledThemePropsType & DatepickerWrapperProps>`
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.error};
    background: ${(props: StyledThemePropsType & DatepickerWrapperProps) =>
        props.disabled
            ? props.theme.colors.disabled
            : props.theme.colors.white};
`;

export type WrapperProps = {
    children?: any;
    error?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    height?: string;
};

const WrapperStyles = css`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 5px;
    border-radius: 6px;
    background: ${(props: StyledThemePropsType) => props.theme.colors.white};
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.placeholder};

    &:focus-within {
        border: 1px solid
            ${({ theme, readonly }: WrapperProps & StyledThemePropsType) =>
                readonly
                    ? theme.colors.placeholder
                    : theme.colors.primaryTextLight};
    }

    ${(props: WrapperProps & StyledThemePropsType) => {
        if (props.error) {
            return WrapperError;
        }
        if (props.disabled) {
            return WrapperDisabled;
        }
    }};
`;

const Wrapper = styled.div<WrapperProps & StyledThemePropsType>`
    ${WrapperStyles};
    height: ${(props: WrapperProps) => (props.height ? props.height : '50px')};
    position: relative;
`;

const WrapperWithoutPaddings = styled(Wrapper)`
    padding: 0;
    height: ${(props: WrapperProps) => (props.height ? props.height : '50px')};
`;

type HelpTextProps = {
    error?: boolean;
    children: string | number;
};

const HelpTextError = css<StyledThemePropsType>`
    color: ${(props: StyledThemePropsType) => props.theme.colors.error};
`;

const HelpText = styled.div<HelpTextProps & StyledThemePropsType>`
    ${BodyStyles};
    color: ${(props: StyledThemePropsType) => props.theme.colors.placeholder};
    ${(props: HelpTextProps & StyledThemePropsType) =>
        props.error && HelpTextError};
`;

const TextFieldBottomMarginStyles = css`
    margin-bottom: 25px;
    width: 100%;
    ${media.lg`
    margin-bottom: 32px;
  `};
`;

const TextFieldBottomMargin = styled.div`
    ${TextFieldBottomMarginStyles};
`;

export {
    TextFieldContainer,
    LabelContainer,
    Wrapper,
    Input,
    HelpText,
    TextFieldBottomMarginStyles,
    TextFieldBottomMargin,
    WrapperWithoutPaddings,
    InputStyles,
    WrapperStyles,
};
