import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme/index';
import { Button } from '../../../../shared/elements';
import { DefinedIcons } from '../../../../shared/types/index';

export const InterestCardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const InterestCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    margin: 0 10px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    border-radius: 10px;
    padding: 30px 11px;

    ${media.lg`
    width: 375px;
    padding: 32px 30px 24px;
  `};
`;

export const InterestCardBackground = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-radius: 3px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    margin-bottom: 10px;
    min-height: 355px;
    padding: 16px;
    ${media.lg`
    margin-bottom: 4px;
  `};
`;

type IconContainerProps = {
    icon: DefinedIcons;
};

export const IconContainer = styled.div<IconContainerProps>`
    ${({ theme, icon }: IconContainerProps & StyledThemePropsType) => css`
        background: bottom no-repeat;
        background-image: url(${theme.icons[icon]});
        /* mask: url(${theme.icons[icon]}) center no-repeat; */
        background-size: contain;
        width: 48px;
        height: 48px;
    `};
`;

export const InterestTitle = styled.div`
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    margin: 28px 0 16px;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 110%;
`;

export const InterestDescription = styled.div`
    color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.2px;
    font-family: Arial;
    font-size: 16px;
    line-height: 140%;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    ${media.lg`
    margin-bottom: 32px;
    /* width: 210px; */
  `};
    ${media.xl`
      margin-bottom: 0;
  `};
`;

export const InterestButton = styled(Button)`
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.button};

    &:hover {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.buttonHover};
    }
`;
