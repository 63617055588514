import React, { FC, useCallback, useMemo, useState } from 'react';
import { CustomIcon, CustomLink } from '../../../../shared/elements';
import { H2Desktop } from '../../../../shared/typography/index';
import {
    AdvantageIconWrapper,
    AdvantagesContainer,
    AdvantageText,
    CardTitle,
    CheckIcon,
    ChekIconWrapper,
    SingleAdvantageWrapper,
    SubmitButton,
    TariffCardContainer,
    TariffName,
    AdvantageCustomLinkWrapper,
    SpanLink,
    SubscriptionText,
    PriceContainer,
    CouponDescription,
    CouponText,
    LinkWrapper,
    FooterTariffCardWrapper,
    MainContainer,
    FirstLinkWrapper,
    CashBlock,
    AdvantagesWrapper,
    PlafondLineSub,
    PlafondLineMain,
    PlafondHighlighted,
    PlafondEvenMoreHighlighted,
    Uppercased,
} from './elements';
import { ExternalLinks } from '../../../../config/variables/index';
import { InsurancePricingPlanDiscountType } from '../../../../api/interfaces';
import createDiscountString from '../../../../shared/helpers/createDiscountString';
import Price from './Price';
export type TariffAdvantage = {
    text: string;
    showIcon: boolean;
};

export type InsuranceTariff = {
    planId: number;
    type: string;
    selected: boolean;
    title: string;
    monthlyPrice: number;
    yearlyPrice: number;
    isVoucher: boolean;
    sinistre?: {
        quant: number;
        price: number;
    };
    discount?: {
        text: string;
        discountedMonths: number;
        initialPayment: number;
        percentage: number;
        validityMonths: number;
        monthsToPay: number;
        type: InsurancePricingPlanDiscountType;
        isPrepaid: boolean;
    };
};

type TariffCardProps = InsuranceTariff & {
    advantages: TariffAdvantage[];
    actualChoice: string;
    pickTariff(value: string): void;
    applyTariff(): void;
    linkNumber: number;
};

const TariffCard: FC<TariffCardProps> = ({
    title,
    discount,
    isVoucher,
    type,
    monthlyPrice,
    yearlyPrice,
    advantages,
    actualChoice,
    pickTariff,
    applyTariff,
    linkNumber,
    sinistre,
}) => {
    const pickTariffHandler = useCallback(() => pickTariff(type), [
        type,
        pickTariff,
    ]);
    const active = actualChoice === type;
    const href =
        'https://docs.assur-link.com/wp-content/uploads/2021/04/DG-EDPM-EQEDM0841C-042021.pdf';
    const [discountDetailInfo, setDiscountDetailInfo] = useState('');
    useMemo(() => {
        if (discount) {
            setDiscountDetailInfo(
                createDiscountString(discount, monthlyPrice, yearlyPrice)
            );
        }
    }, [discount, setDiscountDetailInfo, monthlyPrice, yearlyPrice]);
    const showDiscountBox: boolean = isVoucher && !!discount;
    const isRegularType: boolean = type === 'REGULAR';
    const detailsHref = isRegularType
        ? 'https://docs.assur-link.com/wp-content/uploads/2021/04/Tableau-des-garanties-Formule-Simple-scaled.jpg'
        : 'https://docs.assur-link.com/wp-content/uploads/2021/04/Tableau-des-garanties-Formule-Premium-scaled.jpg';
    return (
        <TariffCardContainer onClick={pickTariffHandler} active={active}>
            <MainContainer>
                <ChekIconWrapper>
                    <CheckIcon active={active} />
                </ChekIconWrapper>
                <CardTitle>
                    <span>Formule</span>
                    &nbsp;
                    <TariffName>{title}</TariffName>
                </CardTitle>
                <AdvantagesContainer>
                    <AdvantagesWrapper>
                        {advantages.map((advantage, index) => {
                            return (
                                <SingleAdvantageWrapper key={index}>
                                    <AdvantageIconWrapper>
                                        {advantage.showIcon ? (
                                            <CustomIcon
                                                icon="check"
                                                iconColor="checkMark"
                                                height="11"
                                                width="16"
                                            />
                                        ) : null}
                                    </AdvantageIconWrapper>
                                    <AdvantageText
                                        active={active}
                                        showIcon={advantage.showIcon}
                                    >
                                        {advantage.text}
                                    </AdvantageText>
                                </SingleAdvantageWrapper>
                            );
                        })}
                    </AdvantagesWrapper>
                    <CashBlock>
                        <PlafondLineSub>Plafond de </PlafondLineSub>
                        <PlafondLineMain>
                            <PlafondEvenMoreHighlighted>
                                {sinistre?.price} €
                            </PlafondEvenMoreHighlighted>{' '}
                            / sinistre
                        </PlafondLineMain>
                        <PlafondLineMain>
                            pour{' '}
                            <PlafondHighlighted>
                                {sinistre?.quant} sinistre
                                {sinistre?.quant > 1 ? 's' : null}
                            </PlafondHighlighted>{' '}
                            / an
                        </PlafondLineMain>
                        <PlafondLineSub>Réparations illimitées</PlafondLineSub>
                    </CashBlock>
                    <AdvantageCustomLinkWrapper>
                        <SpanLink>
                            Consulter le&nbsp;
                            <CustomLink
                                href={detailsHref}
                                target="_blank"
                                font="12px"
                            >
                                détail des garanties
                            </CustomLink>
                        </SpanLink>
                    </AdvantageCustomLinkWrapper>
                </AdvantagesContainer>
                <PriceContainer isVoucher={showDiscountBox}>
                    <Price
                        active={active}
                        price={monthlyPrice}
                        priceYearly={yearlyPrice}
                    />
                    {showDiscountBox ? (
                        <CouponDescription active={active}>
                            <CouponText active={active}>
                                {' '}
                                {discountDetailInfo}{' '}
                            </CouponText>
                        </CouponDescription>
                    ) : null}
                </PriceContainer>
            </MainContainer>
            <FooterTariffCardWrapper>
                <FirstLinkWrapper active={active}>
                    <CustomLink
                        icon
                        font="10px"
                        href={ExternalLinks.WORDPRESS_INFORMATION_LINK}
                        target="_blank"
                    >
                        VOIR LA FICHE INFORMATION PRODUIT
                    </CustomLink>
                </FirstLinkWrapper>
                <LinkWrapper active={active}>
                    <CustomLink icon font="10px" href={href} target="_blank">
                        CONSULTER LES DISPOSITIONS GÉNÉRALES
                    </CustomLink>
                </LinkWrapper>
                <SubmitButton
                    active={active}
                    disabledEvents={active}
                    variant="dark"
                    size="m"
                    disabled={false}
                    onClick={applyTariff}
                >
                    Je choisis la formule&nbsp;<Uppercased>{title}</Uppercased>
                </SubmitButton>
                {/* <SubscriptionText active={active}>
                    Vous entrez dans un parcours de souscription dématérialisé
                </SubscriptionText> */}
            </FooterTariffCardWrapper>
        </TariffCardContainer>
    );
};

export default TariffCard;
