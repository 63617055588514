import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import Footer from '../../shared/elements/Footer/index';
import { headerRoutes } from '../../routing/headerRoutes';
import { Button, Header } from '../../shared/elements/index';
import { PageContainer } from '../../shared/layout/Page/index';
import { RedirectionContext } from '../../store/context/subscription/redirection';
import InterestsCard from './components/InterestsCard/index';
import { product1, product2, product3 } from './components/InterestsCard/mocks';
import { selectedTheme } from '../../themeProviderHOC';
import {
    Description,
    MainText,
    MainWrapper,
    PageWrapper,
    TextWrapper,
    Section,
    HorizontalWrapper,
    SectionSubsection,
    SectionTitle,
    SectionDescr,
    CardContainer,
    CenteredContent,
    Questions,
    QuestionCard,
    QuestionCardTitle,
    QuestionCardText,
} from './elements';

const Home = () => {
    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    const { clearPersistedStorage } = useContext(RedirectionContext);
    useEffect(() => clearPersistedStorage(), [clearPersistedStorage]);

    const homeAndProt = useRef(null);
    const scrollToHomeAndProt = () =>
        homeAndProt.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        document.head.appendChild(
            document.createElement('style')
        ).innerHTML = `button:before {color: ${selectedTheme.colors.primaryText} !important;}`;
    }, []);

    const wipAlert = () => {
        alert('WIP');
    };
    return (
        <PageContainer>
            <PageWrapper>
                <Header menuButtons={headerRoutes} withEscapeProButton={true} />
                <MainWrapper>
                    <TextWrapper>
                        {process.env.REACT_APP_THEME_KEY === 'ONEY' ? (
                            <MainText>
                                La plateforme d'assurance Covéa Affinity pour
                                mieux protéger votre quotidien
                            </MainText>
                        ) : (
                            <MainText>
                                La plateforme d'assurance Covéa Affinity pour
                                mieux protéger votre quotidien
                            </MainText>
                        )}
                        <Description>
                            C'est quand on en a le plus besoin qu'on se retrouve
                            souvent sans les assurances adaptées à nos besoins
                            et faciles à souscrire en ligne. Covea Affinity vous
                            propose des couvertures qui protègent vos objets du
                            quotidien et votre foyer.
                        </Description>
                        <Button
                            variant="dark"
                            disabled={false}
                            size="auto"
                            onClick={scrollToHomeAndProt}
                        >
                            Découvrir nos offres
                        </Button>
                    </TextWrapper>
                </MainWrapper>
            </PageWrapper>
            <Section background="sectionBg1" ref={homeAndProt}>
                <HorizontalWrapper>
                    <SectionSubsection>Univers</SectionSubsection>
                    <SectionTitle>
                        Protection du foyer et des biens
                    </SectionTitle>
                    <SectionDescr>
                        Vous possédez plusieurs appareils nomades chez vous
                        (téléphones, tablettes, ordinateurs)? Assurez-les dès
                        aujourd’hui contre les risques liés à leur utilisation
                        quotidienne.
                    </SectionDescr>
                    <CardContainer>
                        {product1.map(
                            (
                                {
                                    title,
                                    description,
                                    icon,
                                    buttonText,
                                    disabled,
                                    to,
                                },
                                index
                            ) => (
                                <InterestsCard
                                    key={index}
                                    title={title}
                                    description={description}
                                    icon={icon}
                                    buttonText={buttonText}
                                    disabled={disabled}
                                    to={to}
                                />
                            )
                        )}
                    </CardContainer>
                </HorizontalWrapper>
            </Section>
            <Section background="sectionBg2">
                <HorizontalWrapper>
                    <SectionSubsection>Univers</SectionSubsection>
                    <SectionTitle>Mobilités douces</SectionTitle>
                    <SectionDescr>
                        Vous êtes détenteur d'un vélo ou d'un EDPM (Engin de
                        Déplacement Personnel Motorisé: trottinette électrique,
                        monoroue, gyropode, hoverboard)?
                        <br /> Assurez-vous dès aujourd'hui pour rouler serein
                        et en toute légalité.
                    </SectionDescr>
                    <CardContainer>
                        {product2.map(
                            (
                                {
                                    title,
                                    description,
                                    icon,
                                    buttonText,
                                    disabled,
                                    to,
                                },
                                index
                            ) => (
                                <InterestsCard
                                    key={index}
                                    title={title}
                                    description={description}
                                    icon={icon}
                                    buttonText={buttonText}
                                    disabled={disabled}
                                    to={to}
                                />
                            )
                        )}
                    </CardContainer>
                </HorizontalWrapper>
            </Section>
            <Section background="sectionBg3">
                <HorizontalWrapper>
                    <SectionSubsection>Univers</SectionSubsection>
                    <SectionTitle>Protection de la famille </SectionTitle>
                    <SectionDescr>
                        Vous souhaitez mieux protéger vos proches au quotidien?
                        Souscrivez dès maintenant aux garanties qui vous
                        correspondent.
                    </SectionDescr>
                    <CardContainer>
                        {product3.map(
                            (
                                {
                                    title,
                                    description,
                                    icon,
                                    buttonText,
                                    disabled,
                                    to,
                                },
                                index
                            ) => (
                                <InterestsCard
                                    key={index}
                                    title={title}
                                    description={description}
                                    icon={icon}
                                    buttonText={buttonText}
                                    disabled={disabled}
                                    to={to}
                                />
                            )
                        )}
                    </CardContainer>
                </HorizontalWrapper>
            </Section>
            <Section>
                <HorizontalWrapper>
                    <CenteredContent>
                        <SectionTitle>VOUS AVEZ UNE QUESTION?</SectionTitle>
                        <Questions>
                            <QuestionCard>
                                <QuestionCardTitle>
                                    En savoir plus sur l’assurance
                                </QuestionCardTitle>
                                <QuestionCardText>
                                    Découvrez les différentes offres et
                                    garanties Covéa Affinity
                                </QuestionCardText>
                                <Button
                                    variant="dark"
                                    disabled={false}
                                    size="auto"
                                    onClick={wipAlert}
                                >
                                    Découvrir
                                </Button>
                            </QuestionCard>
                            <QuestionCard>
                                <QuestionCardTitle>Notre FAQ</QuestionCardTitle>
                                <QuestionCardText>
                                    Toutes les réponses aux questions que vous
                                    vous posez sur votre assurance
                                </QuestionCardText>
                                <Button
                                    variant="dark"
                                    disabled={false}
                                    size="auto"
                                    onClick={wipAlert}
                                >
                                    Consulter
                                </Button>
                            </QuestionCard>
                        </Questions>
                    </CenteredContent>
                </HorizontalWrapper>
            </Section>
            {/* <EmptyContainer /> */}
            <Footer />
        </PageContainer>
    );
};

export default Home;
