import React, { ChangeEvent, FC } from 'react';
import {
    HelpText,
    Input,
    LabelContainer,
    TextFieldContainer,
    Wrapper,
} from './elements';

type TextFieldProps = {
    label?: string;
    placeholder?: string;
    value?: string | number | Date;
    type?: 'text' | 'number';
    disabled?: boolean;
    readOnly?: boolean;
    error?: boolean;
    initialVisibility: boolean;
    helpText?: string | number;
    capitalize?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
    onChange(event: ChangeEvent<HTMLInputElement>): void;
    mask?: any;
    inputmode?: string;
};

const TextField: FC<TextFieldProps> = ({
    label,
    placeholder,
    helpText,
    error,
    disabled,
    readOnly,
    type,
    value,
    capitalize,
    onChange,
    mask,
    inputmode,
}) => {
    const initialType = type ? type : 'text';
    const placeholderText = placeholder ? placeholder : '';

    return (
        <TextFieldContainer>
            <LabelContainer>{label?.toUpperCase()}</LabelContainer>
            <Wrapper error={error} disabled={disabled} readonly={readOnly}>
                <Input
                    name={label}
                    type={initialType}
                    placeholder={placeholderText}
                    disabled={disabled}
                    value={value}
                    capitalize={capitalize}
                    onChange={onChange}
                    mask={mask}
                    inputmode={inputmode}
                    readOnly={readOnly}
                />
            </Wrapper>
            {!!helpText ? <HelpText error={error}>{helpText}</HelpText> : null}
        </TextFieldContainer>
    );
};

export default TextField;
