import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme/index';
import {
    InputLabelTextMobileStyles,
    LabelStyles,
} from '../../typography/index';
import { InputStyles } from '../TextField/elements';

const TextFieldContainer = styled.div`
    width: 100%;
`;

const LabelContainer = styled.div`
    ${({ theme }: StyledThemePropsType) => css`
        margin-bottom: 5px;
        ${InputLabelTextMobileStyles};
        color: ${theme.colors.darkColor};
        text-transform: uppercase;
        font-weight: bold;

        ${media.lg`
      ${LabelStyles};
        font-weight: bold;
        color: ${theme.colors.darkColor};
    `};
    `};
`;

const Input = styled.input`
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
    ${InputStyles};
    &::placeholder {
        color: ${(props: StyledThemePropsType) =>
            props.theme.colors.inputTextCaption};
        font-weight: normal;
        font-style: italic;
    }

    background-color: transparent;
    width: 100%;
    font-weight: normal;
    border: none;
    outline: none;

    ${media.lg`
    font-size: 16px;
  `}
`;

export const WrapperDisabled = css<StyledThemePropsType>`
    background: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.disabled};
`;

export const WrapperDisabledDatePicker = css<StyledThemePropsType>`
    background: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.placeholder};
`;

export const WrapperError = css<StyledThemePropsType>`
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.error};
`;

export type WrapperProps = {
    children?: any;
    error?: boolean;
    disabled?: boolean;
    height?: string;
};

const WrapperStyles = css`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 5px;
    border-radius: 6px;
    background: ${(props: StyledThemePropsType) => props.theme.colors.white};
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.placeholder};

    &:focus-within {
        border: 1px solid
            ${(props: StyledThemePropsType) =>
                props.theme.colors.primaryTextLight};
    }

    ${(props: WrapperProps & StyledThemePropsType) => {
        if (props.error) {
            return WrapperError;
        }
        if (props.disabled) {
            return WrapperDisabled;
        }
    }};
`;

const Wrapper = styled.div<WrapperProps & StyledThemePropsType>`
    ${WrapperStyles};
    height: ${(props: WrapperProps) => (props.height ? props.height : '50px')};
    position: relative;
`;

const WrapperWithoutPaddings = styled(Wrapper)`
    padding: 0;
    height: ${(props: WrapperProps) => (props.height ? props.height : '50px')};
`;

const TextFieldBottomMarginStyles = css`
    margin-bottom: 25px;
    width: 100%;
    ${media.lg`
    margin-bottom: 32px;
  `};
`;

const TextFieldBottomMargin = styled.div`
    ${TextFieldBottomMarginStyles};
`;

export {
    TextFieldContainer,
    LabelContainer,
    Wrapper,
    Input,
    TextFieldBottomMarginStyles,
    TextFieldBottomMargin,
    WrapperWithoutPaddings,
    WrapperStyles,
};
