import HexToRgba from "./hexToRgba";
import { useRedirection } from "./useRedirection";
import formatStoredDate from "./formatStoredDate";

const mergeIbanValue = (ibanValue: string) => ibanValue.split(" ").join("");

export {
  HexToRgba,
  useRedirection,
  mergeIbanValue,
  formatStoredDate,
};
