// import { ApolloLink, HttpLink, InMemoryCache } from "apollo-boost";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";

const cache = new InMemoryCache();


const link = new HttpLink({
	// Dev graphql link
	// uri: "https://api.code.store/a4a73a0a29ce4bf39c1114c94fb5b7fb/graphql",
	// Staging  graphql link
	uri: "https://assurlink-external-staging.herokuapp.com/graphql",
	// Prod  graphql link
	// uri: "https://assurlink.herokuapp.com/graphql",
});

export const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('AUTH_TOKEN');
	return {
		headers: {
			...headers,
			authorization: token ? token : "",
		},
	};
});

const client = new ApolloClient({
	cache,
	name: "AssurLink",
	//@ts-ignore
	link: ApolloLink.from([authLink, link]),
});

export default client;
