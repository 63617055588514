import React, { FC, useState } from 'react';
import { CustomIcon } from '../../../../shared/elements';
import { FaqProps } from '../../types';
import { Container, Title, Description, IconWrapper } from './elements';

export type FaqCardProps = FaqProps & {
    isActive?: boolean;
    closeAll: () => void;
};

const FaqCard: FC<FaqCardProps> = ({
    title,
    description,
    isActive = true,
    closeAll,
}) => {
    const [active, setActive] = useState(isActive);
    const handleChangeActive = async () => {
        await closeAll();
        setActive((prev) => !prev);
    };

    return (
        <Container onClick={handleChangeActive}>
            <Title>{title}</Title>
            {active ? <Description>{description}</Description> : null}
            <IconWrapper>
                <CustomIcon
                    icon={active ? 'minus' : 'plus'}
                    iconColor="darkColor"
                    width="20"
                    height="20"
                />
            </IconWrapper>
        </Container>
    );
};

export default FaqCard;
