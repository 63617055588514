import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import Slider from 'react-slick';
import Footer from '../../shared/elements/Footer/index';
import { headerRoutes } from '../../routing/headerRoutes';
import { Header, RulesContainer } from '../../shared/elements/index';
import { PageContainer } from '../../shared/layout/Page/index';
import { RedirectionContext } from '../../store/context/subscription/redirection';
import InterestsCard from './components/InterestsCard/index';
import { interests } from './components/InterestsCard/mocks';
import {
    interestsSliderSettings,
    transportSliderSettings,
} from './components/sliderSettings';
import { homeTransportSlides } from './components/Transport/config';
import TransportCard from './components/Transport/TransportCard';
import { selectedTheme } from '../../themeProviderHOC';
import {
    Description,
    InterestsContent,
    InterestsTitle,
    InterestsWrapper,
    MainText,
    MainWrapper,
    PageWrapper,
    TextWrapper,
    TransportSection,
    TransportSliderWrapper,
    TransportText,
    TransportTitle,
    InformationLink,
    EmptyContainer,
    Section,
} from './elements';
import VoucherCard from './components/VoucherCard';
import PlansSection from './components/PlansSection';
import { InterestCardWrapper } from './components/InterestsCard/elements';

const Insurance = () => {
    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    const { clearPersistedStorage } = useContext(RedirectionContext);
    useEffect(() => clearPersistedStorage(), [clearPersistedStorage]);

    const insurancesBlock = useRef(null);
    const scrollToInsurancesBlock = () =>
        insurancesBlock.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        document.head.appendChild(
            document.createElement('style')
        ).innerHTML = `button:before {color: ${selectedTheme.colors.primaryText} !important;}`;
    }, []);
    return (
        <PageContainer>
            <PageWrapper>
                <Header menuButtons={headerRoutes} />
                <MainWrapper>
                    <TextWrapper>
                        {process.env.REACT_APP_THEME_KEY === 'ONEY' ? (
                            <MainText>
                                L’Assurance produits nomades foyer
                            </MainText>
                        ) : (
                            <MainText>
                                L’Assurance produits nomades foyer{' '}
                            </MainText>
                        )}
                        <Description>
                            À partir de 9,90 € par mois, couvrez les appareils
                            nomades de votre foyer
                            {/* <InformationLink href="#rules">
                                {' '}
                                (1){' '}
                            </InformationLink> */}
                        </Description>
                    </TextWrapper>
                </MainWrapper>
                <VoucherCard scrollTo={scrollToInsurancesBlock} />
                <div ref={insurancesBlock}>
                    <PlansSection />
                </div>
            </PageWrapper>
            <TransportSection>
                <TransportTitle>Les produits nomades assurés</TransportTitle>
                <TransportText>
                    Pratiques, connectés, seul ou en famille, nos appareils sont
                    devenus des vrais compagnons du quotidien. Tour d’horizon
                    des appareils protégés par Covéa Affinity
                </TransportText>
                <TransportSliderWrapper>
                    <Slider {...transportSliderSettings}>
                        {homeTransportSlides.map(
                            ({ icon, name, description }) => (
                                <TransportCard
                                    key={name}
                                    icon={icon}
                                    name={name}
                                    description={description}
                                />
                            )
                        )}
                    </Slider>
                </TransportSliderWrapper>
            </TransportSection>
            <Section background="sectionBg1">
                <InterestsTitle>
                    Ces offres peuvent aussi vous intéresser
                </InterestsTitle>
                <InterestsWrapper>
                    <InterestsContent>
                        <InterestCardWrapper>
                            {/* <Slider
                            {...interestsSliderSettings}
                            className="interests-slider"
                        > */}
                            {interests.map(
                                (
                                    { title, description, icon, buttonText },
                                    index
                                ) => (
                                    <InterestsCard
                                        key={index}
                                        title={title}
                                        description={description}
                                        icon={icon}
                                        buttonText={buttonText}
                                    />
                                )
                            )}
                        </InterestCardWrapper>
                        {/* </Slider> */}
                    </InterestsContent>
                </InterestsWrapper>
            </Section>
            <RulesContainer />
            <EmptyContainer />
            <Footer />
        </PageContainer>
    );
};

export default Insurance;
