import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";


type CurrentRouteProps = {
  currentRoute: string;
}

type returnToPreviousPageType = [() => void];

export function useReturnToPreviousPage({ currentRoute }: CurrentRouteProps): returnToPreviousPageType {
  const { push } = useHistory();

  const finalString = useMemo(() => {
    const currentPath = currentRoute.split("").slice(0, currentRoute.length - 1);
    const currentStepNumber = +currentRoute.split("").slice(currentRoute.length - 1);
    currentPath.push(`${currentStepNumber - 1}`);
    const prevStep = [...currentPath].join("");
    return prevStep;
  }, [currentRoute]);

  const goBack = useCallback(() => {
    if (currentRoute === "/questionnaire/step1") {
      push("/insurance");
    } else {
      push(finalString);
    }
  }, [push, finalString, currentRoute])

  return [goBack];
}
