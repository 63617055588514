import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme';
import {
    H2DesktopStyles,
    HeroDescriptionStyles,
} from '../../../../shared/typography';

export const PlansSectionWrapper = styled.div`
    margin-bottom: 40px;

    ${media.lg`
    width: auto;
    margin: 0 25px;
  `}

    ${media.xl`
    width: 1160px;
    margin: 0 auto;
  `};
`;

export const PlansSectionTitle = styled.div`
    ${H2DesktopStyles};
    margin: 67px 36px 20px;
    text-transform: uppercase;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    font-size: 32px;
    line-height: 37px;
    ${media.lg`
    margin: 0 auto 20px;;
  `}
`;

export const PlansSectionSubTitle = styled.div`
    ${HeroDescriptionStyles};
    text-align: center;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    font-weight: normal;
    margin: 4px auto 0;
    font-size: 16px;
    width: 280px;
    max-width: 100%;
    padding: 0 25px;

    ${media.lg`
    ${HeroDescriptionStyles};
    font-weight: normal;
    width: 540px;
    margin: 8px auto 0;
  `}

    ${media.xl`
    width: 800px;
  `}
`;

export const PlansWrapper = styled.div`
    position: relative;
    display: none;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.container};
    width: 1160px;
    height: 716px;
    border-radius: 5px;
    margin: 50px 0 60px;
    padding: 15px 50px 36px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;

    ${media.xl`
    display: flex;
  `};
`;

export const PlansFeaturesWrapperDesktop = styled.div`
    width: 457px;
    margin-top: 30px;
`;

export const PlansFeaturesTitle = styled.div`
    font-size: 18px;
    font-weight: normal;
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryLight};
    line-height: 1.5;
    letter-spacing: 0.2px;
`;

export const PlansFeatures = styled.div`
    width: 512px;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 24px;
`;

type PlanFeatureProps = {
    bold?: boolean;
};

export const PlanFeature = styled.div<PlanFeatureProps>`
    ${({ bold = false, theme }: PlanFeatureProps & StyledThemePropsType) => css`
        font-size: 18px;
        font-weight: ${bold ? 'bold' : 'normal'};
        color: ${theme.colors.darkColor};
        line-height: 1.5;
        letter-spacing: 0.2px;
    `};
`;

export const PlanCardsWrapper = styled.div`
    width: 563px;
    margin: 0 0 0 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const MobileSliderWrapper = styled.div`
    margin: 25px auto 0;
    width: 280px;

    ${media.lg`
    width: 600px;
  `};

    ${media.xl`
    display: none;
  `};
`;

export const CustomLinkWrapper = styled.div`
    position: absolute;
    bottom: 35px;
    left: 50px;
`;

export const LinksWrraper = styled.div`
    margin: 50px 0;
`;
