import React, { FC, useContext } from 'react';
import { LabelWithDescription } from '../../../../shared/elements/index';
import { formatStoredDate } from '../../../../shared/helpers/index';
import { QuestionnaireContext } from '../../../../store/context/questionnaire/questionnaire';
import { Vehicles } from '../../../questionnaire/configuration/vehicles';
import {
    ModifierButton,
    PaymentBlockTitle,
    PaymentInfoBlock,
    PaymentInfoBlockBackgroundForInsurance,
    VehicleIcon,
    SeveralPropertiesContainer,
} from '../elements';

export type CouponDetails = {
    title: string;
    priceDescription: string;
};

type InsuranceInfoProps = {
    tariffName: string;
    priceDescription: string;
    startDate: Date;
    showCoupon: boolean;
    couponDetails: CouponDetails;
    modify(): void;
};

const InsuranceInfo: FC<InsuranceInfoProps> = ({
    showCoupon,
    couponDetails,
    tariffName,
    priceDescription,
    startDate,
    modify,
}) => {
    const { vehicle } = useContext(QuestionnaireContext);
    return (
        <PaymentInfoBlockBackgroundForInsurance>
            <PaymentInfoBlock overflow="visible">
                <VehicleIcon icon={Vehicles[vehicle]} />
                <PaymentBlockTitle>Formule {tariffName}</PaymentBlockTitle>
                <SeveralPropertiesContainer>
                    <LabelWithDescription
                        label="Date d’effet"
                        description={formatStoredDate(new Date(startDate))}
                    />
                    <LabelWithDescription
                        label="Prix"
                        description={priceDescription}
                    />
                </SeveralPropertiesContainer>
                {/* For the future feature: using voucher with discount */}
                {/* {showCoupon ? (
          <CouponWrapper>
            <CouponText>
              {couponDetails.title}
              &nbsp; <TransferConpoment />
            </CouponText>
            <CouponTextThin>{couponDetails.priceDescription}</CouponTextThin>
            <CouponText></CouponText>
          </CouponWrapper>
        ) : null} */}

                <ModifierButton onClick={modify}>Modifier</ModifierButton>
            </PaymentInfoBlock>
        </PaymentInfoBlockBackgroundForInsurance>
    );
};

export default InsuranceInfo;
