import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme';
import { DefaultTransition } from '../../../../shared/styles';
import { H3Styles, HeroDescriptionStyles } from '../../../../shared/typography';

export const CardSection = styled.div`
    height: 290px;
    width: 100%;
    position: relative;

    ${media.lg`
    height: 285px;
  `};
`;

export const CardWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 285px;
    border-radius: 0;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;

    ${(props: StyledThemePropsType) => media.lg`
    position: absolute;
    width: 750px;
    height: 320px;
    left: 50%;
    box-shadow: 0 2px 34px 0 ${props.theme.colors.buttonShadow2};
    transform: translateX(-50%);
    top: -77px;
    z-index: 10;
    border-radius: 10px;
  `};
`;

export const VoucherIcon = styled.div`
    ${({ theme }: StyledThemePropsType) => css`
        width: 46px;
        height: 46px;
        background-image: url(${theme.icons.voucher});
        background-repeat: no-repeat;
        background-size: cover;
        margin: 32px auto 0;

        ${media.lg`
    width: 83px;
    height: 83px;
  `}
    `};
`;

export const HeadphonesIcon = styled.div`
    ${({ theme }: StyledThemePropsType) => css`
        width: 40px;
        height: 40px;
        background-image: url(${theme.icons.headphones});
        background-repeat: no-repeat;
        background-size: cover;
    `};
`;

export const MonitorIcon = styled.div`
    ${({ theme }: StyledThemePropsType) => css`
        width: 40px;
        height: 40px;
        background-image: url(${theme.icons.monitor});
        background-repeat: no-repeat;
        background-size: cover;
    `};
`;

export const SmartphoneIcon = styled.div`
    ${({ theme }: StyledThemePropsType) => css`
        width: 40px;
        height: 40px;
        background-image: url(${theme.icons.smartphone});
        background-repeat: no-repeat;
        background-size: cover;
    `};
`;

export const IconsCont = styled.div`
    display: flex;
    align-items: center;
    width: 160px;
    margin-top: 65px;
    justify-content: space-between;
`;

export const CardText = styled.div`
    ${HeroDescriptionStyles};
    width: 280px;
    margin: 20px auto 0;
    font-size: 16px;
    line-height: 23px;
    text-align: center;

    ${media.lg`
    ${HeroDescriptionStyles};
    width: auto;
    font-weight: normal;
    margin: 22px 51px 0;
  `}
`;

export const CardButton = styled.button`
    ${H3Styles};
    ${DefaultTransition};
    display: block;
    font-size: 14px;
    line-height: 22px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.button};
    color: ${(props: StyledThemePropsType) => props.theme.colors.white};
    width: 280px;
    height: 50px;
    border: none;
    margin: 25px auto 0;
    cursor: pointer;

    ${(props: StyledThemePropsType) => media.lg`
    ${H3Styles};
    color: ${props.theme.colors.white};
    border-radius: 3px;
    width: 267px;
    height: 62px;
    margin: 26px auto 0;
  `}

    &:hover {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.buttonHover};
    }
`;
