import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme';
import { HexToRgba } from '../../helpers';
import { DefaultTransition } from '../../styles';
import { HeaderButtonStyles, ButtonTextDesktopStyles } from '../../typography';

export const ButtonsWrapper = styled.div`
    flex-direction: row;
    justify-content: space-between;
    display: none;

    ${media.xl`
    display: flex;
    width: 715px;
  `}
`;

export const HeaderButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

interface HeaderButtonProps {
    selected?: boolean;
}

export const HeaderButton = styled.div<HeaderButtonProps>`
    ${HeaderButtonStyles};
    /* font-weight: ${(props: HeaderButtonProps) =>
        props.selected ? 'bold' : 'normal'}; */
    cursor: pointer;
    font-weight: bold;
`;

export const ButtonsDelimeter = styled.div`
    width: 2px;
    height: 20px;
    background-color: ${(props: StyledThemePropsType) =>
        HexToRgba(props.theme.colors.darkColor, 0.3)};
    margin: 0 15px;
`;

export const SubscriptionButton = styled.div`
    ${ButtonTextDesktopStyles};
    ${DefaultTransition};
    cursor: pointer;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.button};
    width: 166px;
    height: 62px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    /* box-shadow: 0 2px 34px 0
            ${(props: StyledThemePropsType) =>
        props.theme.colors.buttonShadow1},
        0 2px 34px 0
            ${(props: StyledThemePropsType) =>
        props.theme.colors.buttonShadow2}; */

    ${media.xl`
    display: flex;
  `}

    &:hover {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.buttonHover};
    }
`;

export const BurgerButton = styled.div`
    display: initial;

    ${media.xl`
    display: none;
  `}
`;

export const MenuWrapper = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${(props: StyledThemePropsType) =>
        HexToRgba(props.theme.colors.darkColor, 0.5)};
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;

    ${media.xl`
    display: none;
  `}
`;

export const BurgerMenuContainer = styled.div`
    width: 250px;
    height: 100%;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.primaryText};
    position: relative;
`;

export const BurgerCloseButton = styled.div`
    position: absolute;
    top: 23px;
    right: 20px;
`;

export const BurgerMenuButtons = styled.div`
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 84px 0 0 34px;
`;

export const BurgerSubscriptionButton = styled(SubscriptionButton)`
    width: 200px;
    margin: 143px 0 0 25px;
    display: flex;
`;

export const LogoContainer = styled.button`
    border: none;
    outline: none;
    background: transparent;
`;

export const ExcapeProButton = styled.button`
    display: flex;
    border: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: ${(props: StyledThemePropsType) => props.theme.colors.button};
    align-items: center;
    background-color: transparent;
    padding: 10px 5px;
    cursor: pointer;
`;
