import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { HeroDescriptionStyles } from '../../../shared/typography';
import { TransportIcon } from '../elements';
import { StyledThemePropsType } from '../../../config/theme';
import { CustomSpan } from '../../subscription/transport/elements';
import { DefaultTransition } from '../../../shared/styles';

export const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 20px;

    ${media.lg`
    padding-top: 0;
  `};
`;

export const SummaryItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;

    padding: 0 20px 0;
    width: 100%;

    ${media.lg`
    flex-direction: row;
  `};
`;

export const SummaryItemText = styled.div`
    ${HeroDescriptionStyles};
    font-size: 16px;
    font-weight: normal;
    width: 255px;
    text-align: left;
    margin-top: 6px;

    ${media.lg`
    ${HeroDescriptionStyles};
    font-weight: normal;
    text-align: left;
    width: 600px;
    margin-top: 0;
  `};
`;

export const SummaryTransportIcon = styled(TransportIcon)`
    display: none;

    ${media.lg`
    display: block;
    bottom: 10px;
    right: 10px;
  `};

    ${media.xl`
    display: block;
    bottom: 10px; 
    right: 46px;
  `};
`;

export const AgreementWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: -95px;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${media.lg`
    bottom: -75px;
  `};
`;

type CheckBoxError = {
    error: boolean;
};

export const AgreementText = styled.div<CheckBoxError>`
    font-size: 20px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType & CheckBoxError) =>
        props.error ? props.theme.colors.error : props.theme.colors.darkColor};
    margin-left: 7px;
    opacity: 0.8;
    cursor: pointer;
    flex: 20;
    padding-left: 18px;

    ${media.xl`
    flex: auto;
	/* font-size: 16px; */
	`};
`;

export type IsDisabledProps = {
    isDisabled: boolean;
};

export const SubmitButton = styled.div<IsDisabledProps>`
    ${DefaultTransition};
    width: 100%;
    height: 50px;
    background: ${(props: StyledThemePropsType) =>
        props.theme.colors.primaryLight};
    box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    opacity: ${(props: IsDisabledProps & StyledThemePropsType) => {
        return props.isDisabled ? '0.6' : '1';
    }};

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    ${media.lg`
    width: 100%;
    height: 62px;
    align-self: center;
 `};

    &:hover {
        background: ${(props: IsDisabledProps & StyledThemePropsType) => {
            return props.isDisabled
                ? props.theme.colors.primaryLight
                : props.theme.colors.primaryDark;
        }};
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    height: 62px;
    margin-bottom: 50px;
    ${media.lg`
		width: 265px;
		margin-bottom: 0px;
  `};
`;

export const ErrorWrapper = styled.div`
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);

    ${media.xl`
    bottom: -160px;
    right: 0;
    width: 60%;
    transform: inherit;
  `};
`;

export const ElementsStep5Wrapper = styled.div`
    position: absolute;
    left: 0;
    top: 376px;
    display: flex;
    flex-direction: column;
    max-height: 480px;
    width: 100%;
    ${media.lg`
		top: 360px;
	`};
    ${media.xl`
		flex-direction: row;
		bottom: -160px;
		top: auto;
`};
`;

export const CustomQuestionnaireSpan = styled(CustomSpan)`
    margin-top: 4px;
`;
