import { InterestsCardProps } from "./index";

export const interests: InterestsCardProps[] = [
  {
    title: "Assurance casse vol mobile",
    description: "Protection et réparation en cas vol, casse et oxydation de votre mobile ",
    icon: "protection2",
    buttonText: "Bientôt disponible",
  },
  {
    title: "Panne électronique",
    description: "Réparation ou remplacement des appareils électroniques ",
    icon: "protection3",
    buttonText: "Bientôt disponible",
  },
  {
    title: "Assurance multirisque habitation  ",
    description: "Couverture multirisques classique, à prix réduits et facile à souscrire ",
    icon: "protection4",
    buttonText: "Bientôt disponible",
  },
];
