import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme';
import {
    H1MobileStyles,
    HeroDescriptionStyles,
} from '../../../../shared/typography';
import { Vehicles } from '../../../questionnaire/configuration/vehicles';

export const TransportCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px 10px;
    border-radius: 3px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.container};
    margin: 0 auto 30px;

    ${media.lg`
    padding: 14px 30px;
    width: 380px;
    height: 350px;
  `};
`;

export type TransportIcon = {
    icon: Vehicles;
};

export const TransportImage = styled.div<TransportIcon>`
    background: url(${(props: TransportIcon & StyledThemePropsType) =>
            props.theme.icons[props.icon]})
        no-repeat bottom;
    background-size: contain;
    margin-bottom: 8px;
    ${media.lg`
    margin-bottom: 18px;
  `};
    width: 120px;
    height: 180px;
`;

export const TransportCardTitle = styled.div`
    margin-bottom: 10px;
    ${H1MobileStyles};
    font-size: 18px;
    line-height: 1.38;
    font-weight: normal;

    ${media.lg`
    ${HeroDescriptionStyles};
    font-weight: normal;
  `};
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkText};
`;

export const TransportCardDescription = styled.div`
    margin-bottom: 10px;
    ${H1MobileStyles};
    line-height: 1.38;
    font-weight: normal;
    color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
`;
