const mieuxVousProtegerLink: string = process.env.REACT_APP_THEME_KEY === "ASSURLINK" ? "https://docs.assur-link.com/" : "https://oney.docs.assur-link.com/";
export const headerRoutes = [
	{
		key: 0,
		name: "Nos assurances",
		url: "/"
	},
	{
		key: 1,
		name: "Nos promos",
		url: "/", // "/questionnaire" // temp hidden
	},
	{
		key: 2,
		name: "Mieux vous protéger ",
		url: "/", // "/test" // hidden due recolour
	},
	{ // hidden beore changed colors
		key: 3,
		name: "Nous contacter",
		url: "/contact",  // mieuxVousProtegerLink // hidden
	},
	// {
	// 	key: 4,
	// 	name: "Nous contacter ",
	// 	url: "/contact"
	// }
];
