import { FaqProps, someCardsProps } from "./types";

export const someCards: someCardsProps[] = [
    {
        title: "J'envoie un lien d'activation à mon client",
        description: "Accéder aux formulaires permettant à vos clients de souscrire en temps réel à des assurances utiles et avantageuses",
        icon: "link",
        buttonText: "Générer les liens d’activation ",
        buttonLink: "https://staging.d2gf2qmsb9112s.amplifyapp.com/login",
    },
    {
        title: "J'aide mon client à souscrire et à suivre ses garanties d'assurance",
        description: "Aider le client dans sa souscription et lui apporter l'information nécessaire sur la vie de ses contrats ",
        icon: "lock",
        buttonText: "Accéder au portail vendeur",
        buttonLink: "https://demoportailvendeurcovea.atm-assur.com/",
    },
]

export const faqCards: FaqProps[] = [
    {
        title: "En quoi consiste le formulaire d’activation rapide? ",
        description: "Contrat d'assurance collectif à adhésions individuelles facultatives souscrit, distribué et géré par [le Courtier, ses mentions légales] auprès de MMA IARD Assurances Mutuelles RCS Le Mans N° 775 652 126 société d’assurance mutuelle à cotisations fixes et MMA IARD, S.A. au capital social de 537.052.368 €, RCS Le Mans N° 440 048 882 ayant leurs sièges sociaux au 14 boulevard Marie et Alexandre Oyon -72030 Le Mans cedex 9 (conjointement dénommées ci-après «MMA» ou l’«Assureur»). Sociétés appartenant au groupe Covéa. Entrepris",
    },
    {
        title: "Quelles sont les assurances que je peux proposer via l’activation rapide au client?  ",
        description: "Contrat d'assurance collectif à adhésions individuelles facultatives souscrit, distribué et géré par [le Courtier, ses mentions légales] auprès de MMA IARD Assurances Mutuelles RCS Le Mans N° 775 652 126 société d’assurance mutuelle à cotisations fixes et MMA IARD, S.A. au capital social de 537.052.368 €, RCS Le Mans N° 440 048 882 ayant leurs sièges sociaux au 14 boulevard Marie et Alexandre Oyon -72030 Le Mans cedex 9 (conjointement dénommées ci-après «MMA» ou l’«Assureur»). Sociétés appartenant au groupe Covéa. Entrepris",
    },
    {
        title: "Que faire si mon client ne reçoit pas son lien d’activation?  ",
        description: "Contrat d'assurance collectif à adhésions individuelles facultatives souscrit, distribué et géré par [le Courtier, ses mentions légales] auprès de MMA IARD Assurances Mutuelles RCS Le Mans N° 775 652 126 société d’assurance mutuelle à cotisations fixes et MMA IARD, S.A. au capital social de 537.052.368 €, RCS Le Mans N° 440 048 882 ayant leurs sièges sociaux au 14 boulevard Marie et Alexandre Oyon -72030 Le Mans cedex 9 (conjointement dénommées ci-après «MMA» ou l’«Assureur»). Sociétés appartenant au groupe Covéa. Entrepris",
    },
    {
        title: " Que me permet de faire le portail vendeur? ",
        description: "Contrat d'assurance collectif à adhésions individuelles facultatives souscrit, distribué et géré par [le Courtier, ses mentions légales] auprès de MMA IARD Assurances Mutuelles RCS Le Mans N° 775 652 126 société d’assurance mutuelle à cotisations fixes et MMA IARD, S.A. au capital social de 537.052.368 €, RCS Le Mans N° 440 048 882 ayant leurs sièges sociaux au 14 boulevard Marie et Alexandre Oyon -72030 Le Mans cedex 9 (conjointement dénommées ci-après «MMA» ou l’«Assureur»). Sociétés appartenant au groupe Covéa. Entrepris",
    },
    {
        title: "Comment je peux suivre l'activité de mon client dans le portail vendeur?",
        description: "Contrat d'assurance collectif à adhésions individuelles facultatives souscrit, distribué et géré par [le Courtier, ses mentions légales] auprès de MMA IARD Assurances Mutuelles RCS Le Mans N° 775 652 126 société d’assurance mutuelle à cotisations fixes et MMA IARD, S.A. au capital social de 537.052.368 €, RCS Le Mans N° 440 048 882 ayant leurs sièges sociaux au 14 boulevard Marie et Alexandre Oyon -72030 Le Mans cedex 9 (conjointement dénommées ci-après «MMA» ou l’«Assureur»). Sociétés appartenant au groupe Covéa. Entrepris",
    },
    {
        title: "Mon client veut connaître le prix des formules d’assurances proposées à l’activation / souscription",
        description: "Contrat d'assurance collectif à adhésions individuelles facultatives souscrit, distribué et géré par [le Courtier, ses mentions légales] auprès de MMA IARD Assurances Mutuelles RCS Le Mans N° 775 652 126 société d’assurance mutuelle à cotisations fixes et MMA IARD, S.A. au capital social de 537.052.368 €, RCS Le Mans N° 440 048 882 ayant leurs sièges sociaux au 14 boulevard Marie et Alexandre Oyon -72030 Le Mans cedex 9 (conjointement dénommées ci-après «MMA» ou l’«Assureur»). Sociétés appartenant au groupe Covéa. Entrepris",
    },
    {
        title: "Mon client souhaite déclarer un sinistre  et ne retrouve pas son numéro de contrat",
        description: "Contrat d'assurance collectif à adhésions individuelles facultatives souscrit, distribué et géré par [le Courtier, ses mentions légales] auprès de MMA IARD Assurances Mutuelles RCS Le Mans N° 775 652 126 société d’assurance mutuelle à cotisations fixes et MMA IARD, S.A. au capital social de 537.052.368 €, RCS Le Mans N° 440 048 882 ayant leurs sièges sociaux au 14 boulevard Marie et Alexandre Oyon -72030 Le Mans cedex 9 (conjointement dénommées ci-après «MMA» ou l’«Assureur»). Sociétés appartenant au groupe Covéa. Entrepris",
    },
];

export const sliderItems = [
    {
        title: "Assurance produits nomades foyer ",
        description: "Protection en cas de casse ou vol de tous les appareils nomades du foyer",
        icon: "protection1",
        buttonText: "Je m’assure",
        disabled: false,
        to: "/insurance"
    },
    {
        title: "Assurance casse-vol mobile",
        description: "Protection et réparation en cas de vol, casse et oxydation de votre mobile",
        icon: "protection2",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
    {
        title: "Extension de garantie panne",
        description: "Réparation ou remplacement des appareils électroniques en cas de panne",
        icon: "protection3",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
    {
        title: "Assurance multirisque habitation",
        description: "Couverture multirisques classique, à prix réduits et facile à souscrire",
        icon: "protection4",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
    {
        title: "Assurance responsabilité civile obligatoire",
        description: "Garantie obligatoire pour tout conducteur d’EDPM",
        icon: "mobilities1",
        buttonText: "Je m’assure",
        disabled: false,
        to: "/login"
    },
    {
        title: "Assurance casse-vol EDPM",
        description: "Casse ou vol de l'EDPM? Nous prenons en charge la réparation ou le remplacement",
        icon: "mobilities2",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
    {
        title: "Assurance casse-vol vélo électrique",
        description: "Prise en charge des réparations ou du remplacement",
        icon: "mobilities3",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
    {
        title: "Assurance casse-vol vélo",
        description: "Prise en charge des réparations ou du remplacement",
        icon: "mobilities4",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
    {
        title: "Assurance famille mobilité",
        description: "Un seul contrat pour garantir toutes les mobilités douces de de la famille",
        icon: "mobilities5",
        buttonText: "Bientôt disponible",
        disabled: true,
    }, {
        title: "Assurance scolaire ",
        description: "Pour assurer les activités scolaires et extra-scolaires de vos enfants",
        icon: "protection5",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
    {
        title: "Protection accident",
        description: "Pour vous protéger contre les accidents du quotidien ",
        icon: "protection6",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
    {
        title: "Assurance animaux",
        description: "Pour prendre soins de vos petits compagnons",
        icon: "protection7",
        buttonText: "Bientôt disponible",
        disabled: true,
    },
];
