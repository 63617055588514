import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme/index';
import { HexToRgba } from '../../../helpers/index';
import {
    StepperItemTextHighlightedStyles,
    StepperItemTextStyles,
} from '../../../typography/index';

type LineProps = {
    notShown?: boolean;
    isSelected?: boolean;
};
// interface LineProps {
//     notShown?: boolean;
//     isSelected: boolean;
// }

interface StepIndicatorWrapperProps {
    isSelected: boolean;
}

const StepWrapper = styled.div`
    display: none;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    min-width: 70px;

    ${media.lg`
    display: flex;
    min-width: 160px;
  `}

    ${media.xl`
    min-width: 190px;
  `}
`;

const StepText = styled.div`
    ${(props: StepIndicatorWrapperProps) =>
        props.isSelected
            ? StepperItemTextHighlightedStyles
            : StepperItemTextStyles};
    font-size: 10px;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};

    ${media.xl`
    font-size: 12px;
  `};
`;

const StepIndicatorWrapper = styled.div<
    StepIndicatorWrapperProps & StyledThemePropsType
>`
    background-color: ${(
        props: StepIndicatorWrapperProps & StyledThemePropsType
    ) => {
        const { button, white } = props.theme.colors;

        return props.isSelected ? button : white;
    }};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-color: ${(props: StepIndicatorWrapperProps & StyledThemePropsType) =>
        props.isSelected
            ? props.theme.colors.button
            : props.theme.colors.steper};
    border-style: dashed;
    margin-top: 7px;
    z-index: 2;
`;

type StepIndicatorProps = {
    isSelected: boolean;
    completed: boolean;
};

const StepIndicator = styled.div<StepIndicatorProps>`
    ${({
        isSelected,
        completed,
        theme,
    }: StyledThemePropsType & StepIndicatorProps) => css`
        background-color: ${isSelected
            ? HexToRgba(theme.colors.steper, 0)
            : theme.colors.steper};
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    `};
`;

const LeftLine = styled.div<LineProps>`
    ${({
        notShown,
        isSelected,
        theme,
    }: StyledThemePropsType & LineProps) => css`
        display: ${notShown ? 'none' : 'initial'};
        height: 1px;
        background-color: ${theme.colors.steper};
        position: absolute;
        width: 43%;
        left: 57%;
        top: 27px;

        ${media.xl`
            top: 30px;
        `};
    `};
`;

const RightLine = styled.div<LineProps>`
    ${({
        notShown,
        isSelected,
        theme,
    }: StyledThemePropsType & LineProps) => css`
        display: ${notShown ? 'none' : 'initial'};
        height: 1px;
        background-color: ${theme.colors.steper};
        position: absolute;
        width: 43%;
        right: 57%;
        top: 27px;

        ${media.xl`
            top: 30px;
        `};
    `};
`;

export {
    StepWrapper,
    StepIndicatorWrapper,
    StepIndicator,
    LeftLine,
    RightLine,
    StepText,
};
