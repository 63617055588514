import { Vehicles } from "../../pages/questionnaire/configuration/vehicles";

type Option = {
	id: number;
	text: string;
	image: string;
};

export enum TariffTitleVariants {
	simple = "Simple",
	premium = "Premium",
	// simple = "SIMPLE",
	// premium = "PREMIUM",
}

export enum InsurancePricingPlanDiscountType {
	MONTHS = "MONTHS",
	PERCENTAGE = "PERCENTAGE",
}

export enum InsurancePricingPlanType {
	REGULAR = "Regular",
	PREMIUM = "Premium",
	// REGULAR = "REGULAR",
	// PREMIUM = "PREMIUM",
}

export enum Civility {
	Mr = "Monsieur",
	Madam = "Madame",
}

type QuestionType = "SLIDER_IMAGE" | "YES_NO";

export type InsurancePricingPlanDiscount = {
	text: string;
	discountedMonths: number;
	initialPayment: number;
	percentage: number;
	validityMonths: number;
	monthsToPay: number;
	type: InsurancePricingPlanDiscountType;
	isPrepaid: boolean;
};

type Question = {
	id: number;
	questionText: string;
	tooltip: string;
	validationRequired: boolean;
	type: QuestionType;
	options: Option[];
};

type InsurancePricingPlan = {
	id: number;
	monthlyPrice: number;
	yearlyPrice: number;
	title: string;
	type: InsurancePricingPlanType;
	discount?: InsurancePricingPlanDiscount;
}

export type ProductBrandEntity = {
	id: number;
	name: string;
};

export type ProductTypeEntity = {
	id: number;
	name: Vehicles;
	brands: ProductBrandEntity[];
};

type VoucherDetails = {
	id: number;
	firstName: string;
	lastName: string;
	mobile: string;
	email: string;
	productTypeId: number;
	productBrandId: number;
	productModel: string;
	productSerial: string;
	discount: string;
};

export type GetQuestionsQueryResult = {
	questions: Question[];
};

export type ValidateQuestionMutationResponse = {
	validateQuestionAnswer: {
		valid: boolean;
		error: string;
	}
}

export type ValidateQuestionAnswerVariables = {
	questionId: number;
	optionId: number;
}

export type GetPlansQueryResult = {
	plans: {
		regular: InsurancePricingPlan;
		premium: InsurancePricingPlan;
		voucher: VoucherDetails;
	}
}

export type GetPlansQueryVariables = {
	voucher: string;
};

export type GetProductsQueryResult = {
	products: ProductTypeEntity[];
};

export type GetVoucherQueryVariables = GetPlansQueryVariables;

export type GetVoucherQueryResult = {
	voucher: VoucherDetails;
};

export enum RequestedInsurancePlanType {
	MONTHLY = "MONTHLY",
	YEARLY = "YEARLY",
}

export type InsuredDriver = {
	firstName: string;
	lastName: string;
	birthDay: string;
	birthPlace: string;
}

export type SubmitMutationInput = {
	planId: number;
	type: RequestedInsurancePlanType;
	effectiveDate: string;
	productTypeId: number;
	productBrandId: number;
	productModel: string;
	productSerial: string;
	drivers: InsuredDriver[];
	firstName: string;
	lastName: string;
	email: string;
	mobile: string;
	address: string;
	postalCode: string;
	city: string;
	information: string;
	accountOwner: string;
	accountIBAN: string;
	// newsletterSubscribe: boolean;
	newsletterSubscribeEmail: boolean;
	newsletterSubscribeSms: boolean;
	voucher: string;
	dateOfBirth: string;
	cityOfBirth: string;
	nativeCountry: string;
	country: string;
	formule: string;
	civility: Civility;
};

export type SubmitMutationVariables = {
	input: SubmitMutationInput;
};

export type SubmitMutationResult = {
	submit: {
		redirect: string;
	};
}
