import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';

const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-top: 11px;
`;

const TariffsWrapper = styled.div`
    align-self: flex-start;
    width: 100%;
    margin: 30px 0 11px;
    overflow-x: hidden;
    position: relative;
    ${media.xs`
    width: 100%;
  `};

    ${media.xl`
    height: auto;
    width: 100%;
    margin: 10px auto 0;
  `};
`;
type TariffCardWrapper = {
    cardNumber: number;
};

const TariffCardWrapper = styled.div<TariffCardWrapper>`
    outline: none;
    display: flex !important;
    justify-content: center;
    ${({ cardNumber }: TariffCardWrapper) => css`
        ${media.xl`
			justify-content: ${cardNumber === 1 ? 'flex-end' : 'flex-start'};
			padding: 30px 10px;
			outline: none;
		`};
    `};
`;

const InsurersWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
`;

export { LinksContainer, TariffsWrapper, TariffCardWrapper, InsurersWrapper };
