import React, { FC, useCallback, useState } from 'react';
import { FaqProps } from '../../types';
import { FaqContainer } from './elements';
import FaqCard from './FaqCard';

export type FAQProps = {
    faqs: FaqProps[];
};

const FAQ: FC<FAQProps> = ({ faqs }) => {
    const faqCards = useCallback(() => {
        return faqs.map((faq: FaqProps) => {
            return { ...faq, isActive: false };
        });
    }, [faqs]);
    const [faqCardsState, setFaqCardsState] = useState(faqCards);

    const handleCloseAll = () => {
        setFaqCardsState(
            faqCardsState.map((faq) => {
                return { ...faq, isActive: false };
            })
        );
    };

    return (
        <FaqContainer>
            {faqCardsState.map((faq: FaqProps) => {
                const { title, description, isActive } = faq;
                return (
                    <FaqCard
                        title={title}
                        description={description}
                        isActive={isActive}
                        closeAll={handleCloseAll}
                    />
                );
            })}
        </FaqContainer>
    );
};

export default FAQ;
