import React, { ReactElement } from "react";
import "react-app-polyfill/stable";
import { ThemeProvider } from "styled-components";
import theme from "./config/theme";
import { primaryTheme } from "./config/theme/primary";

import IconsDefaultTheme from "./shared/icons/defaultTheme";
import IconsSecondTheme from "./shared/icons/secondTheme";

interface IThemeProviderHOC {
  children: ReactElement;
}

const defaultIconsTheme = {
  ...IconsDefaultTheme,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const secondIconsTheme = {
  ...IconsDefaultTheme,
  ...IconsSecondTheme,
};

export const IconsThemeContext = React.createContext(IconsDefaultTheme);
const assurlinkTheme = process.env.REACT_APP_THEME_KEY === "ASSURLINK";
export const selectedTheme = assurlinkTheme
  ? { ...primaryTheme, icons: secondIconsTheme }
  : { ...theme, icons: defaultIconsTheme };
const selectedIconsTheme = assurlinkTheme ? secondIconsTheme : defaultIconsTheme;
const ThemeProviderHOC = ({ children }: IThemeProviderHOC) => {
  return (
      <ThemeProvider theme={selectedTheme}>
        <IconsThemeContext.Provider value={selectedIconsTheme}>{children}</IconsThemeContext.Provider>
      </ThemeProvider>
  );
};

export default ThemeProviderHOC;
