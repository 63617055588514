import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/default";

// TODO: check it
export type LogoProps = {
  src: string;
};

const Logo = styled.img<LogoProps>`
  width: 180px;
  height: 48px;
  margin-bottom: 24px;
  ${media.lg`
		margin-bottom: 32px;
	`};
`;

const PageDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
  margin-bottom: 40px;
	padding: 0 20px;
  ${media.lg`
		font-size: 20px;
		line-height: 28px;
		padding: 0;
	`};
`;

export { Logo, PageDescription };
