import { TariffTitleVariants } from "../../../../api/interfaces";
import { TariffAdvantage } from "./index";

type Advantages = {
  [key in keyof typeof TariffTitleVariants]: TariffAdvantage[]
}

export const advantages: Advantages = {
  "simple": [
    {
      text: "Casse toutes causes",
      showIcon: true,
    },
    {
      text: "Vol toutes causes",
      showIcon: true,
    },
    {
      text: "Oxydation toutes causes",
      showIcon: true,
    },
  ],
  "premium": [
    {
      text: "Casse toutes causes",
      showIcon: true,
    },
    {
      text: "Vol toutes causes",
      showIcon: true,
    },
    {
      text: "Oxydation toutes causes",
      showIcon: true,
    },
  ],
};
