import contact from "./contact.svg";
import coupon from "./coupon.svg";
import habitation from "./habitation.svg";
import logo from "./logo.svg";
import mobile from "./mobile.svg";
import error from "./statuses/error.svg";
import success from "./statuses/success.svg";
import warning from "./statuses/warning.svg";
import gyropode from "./vehicles/gyropode.svg";
import gyroroue from "./vehicles/gyroroue.svg";
import hoverboard from "./vehicles/hoverboard.svg";
import onewheel from "./vehicles/onewheel.svg";
import skateboard from "./vehicles/skateboard.svg";
import trottinette from "./vehicles/trottinette.svg";
import draisienne from "./vehicles/electricbalancebike.svg";
import voiture from "./vehicles/voiture.svg";
import add from "./actions/add.svg";
import voucher from "./voucher-icon.svg";
import targetBlank from "./actions/targetBlank.svg";
import check from "./actions/check.svg";
import insurance from "./actions/insurance.svg";
import locked from "./actions/locked.svg";
import tooltip from "./actions/tooltip.svg";
import close from "./actions/close.svg";
import isuranceCheck from './statuses/Isurance_check_green.svg';
import isuranceCheckTransparent from './statuses/Isurance_check_transparent.svg';
import facebook from "./social/facebook.svg";
import linkedIn from "./social/linkedIn.svg";
import twitter from "./social/twitter.svg";
import burger from "./actions/burger_menu.svg";
import phone from "./actions/phone.svg";
import mail from "./actions/mail.svg";
import chat from "./actions/chat.svg";
import habitationInterest from "./interests/habitation.svg";
import mobileInterest from "./interests/mobile.svg";
import voitureInterest from "./interests/voiture.svg";
import leftArrow from "./actions/leftArrow.svg";
import rightArrow from "./actions/rightArrow.svg";
import inputCheck from "./check.svg";
import calendar from "./calendar_icon.svg";
import filledLocker from "./actions/filledLocker.svg";
import lequite from "./lequite.svg";
import girl1 from "./humans/girl1.svg";
import girl2 from "./humans/girl2.svg";
import girl3 from "./humans/girl3.svg";
import girl4 from "./humans/girl4.svg";
import boy1 from "./humans/boy1.svg";
import boy2 from "./humans/boy2.svg";
import boy3 from "./humans/boy3.svg";
import smartphone from "./smartphone.svg";
import monitor from "./monitor.svg";
import lock from "./lock.svg";
import plus from "./plus.svg";
import minus from "./minus.svg";
import link from "./link.svg";
import letter from "./letter.svg";
import headphones from "./headphones.svg";
import alluraMobile from "./alluraMobile.svg";
import alluraScreen from "./alluraScreen.svg";
import protection1 from "./protection/protection1.svg";
import protection2 from "./protection/protection2.svg";
import protection3 from "./protection/protection3.svg";
import protection4 from "./protection/protection4.svg";
import protection5 from "./protection/protection5.svg";
import protection6 from "./protection/protection6.svg";
import protection7 from "./protection/protection7.svg";
import mobilities1 from "./mobilities/mobilities1.svg";
import mobilities2 from "./mobilities/mobilities2.svg";
import mobilities3 from "./mobilities/mobilities3.svg";
import mobilities4 from "./mobilities/mobilities4.svg";
import mobilities5 from "./mobilities/mobilities5.svg";
import assures1 from "./assures/assures1.svg";
import assures2 from "./assures/assures2.svg";
import assures3 from "./assures/assures3.svg";
import assures4 from "./assures/assures4.svg";


export type IconsType = {
  [key: string]: any;
};

const Icons: IconsType = {
  logo,
  contact,
  coupon,
  mobile,
  habitation,
  error,
  warning,
  success,
  gyropode,
  gyroroue,
  hoverboard,
  onewheel,
  skateboard,
  trottinette,
  draisienne,
  voiture,
  add,
  voucher,
  targetBlank,
  check,
  insurance,
  locked,
  tooltip,
  close,
  isuranceCheck,
  isuranceCheckTransparent,
  facebook,
  linkedIn,
  twitter,
  burger,
  phone,
  mail,
  chat,
  habitationInterest,
  mobileInterest,
  voitureInterest,
  leftArrow,
  rightArrow,
  inputCheck,
  calendar,
  filledLocker,
  lequite,
  girl1,
  girl2,
  girl3,
  girl4,
  boy1,
  boy2,
  boy3,
  smartphone,
  monitor,
  lock,
  plus,
  minus,
  link,
  headphones,
  alluraMobile,
  alluraScreen,
  protection1,
  protection2,
  protection3,
  protection4,
  protection5,
  protection6,
  protection7,
  mobilities1,
  mobilities2,
  mobilities3,
  mobilities4,
  mobilities5,
  assures1,
  assures2,
  assures3,
  assures4,
  letter
};

export default Icons;
