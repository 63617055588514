import React, { useContext, useEffect, useLayoutEffect } from 'react';
import Slider from 'react-slick';
import Footer from '../../shared/elements/Footer/index';
import { headerRoutes } from '../../routing/headerRoutes';
import { Header } from '../../shared/elements/index';
import { PageContainer } from '../../shared/layout/Page/index';
import { RedirectionContext } from '../../store/context/subscription/redirection';
import InterestsCard from './components/InterestsCard/index';
import { transportSliderSettings } from './components/sliderSettings';
import TransportCard from './components/Transport/TransportCard';
import { selectedTheme } from '../../themeProviderHOC';
import {
    MainText,
    MainWrapper,
    PageWrapper,
    TextWrapper,
    TransportSection,
    TransportSliderWrapper,
    TransportTitle,
    Section,
    HorizontalWrapper,
    CardsWrapper,
} from './elements';
import FAQ from './components/FAQ';
import { faqCards, sliderItems, someCards } from './mock';
import { someCardsProps } from './types';

const EscapePro = () => {
    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    const { clearPersistedStorage } = useContext(RedirectionContext);
    useEffect(() => clearPersistedStorage(), [clearPersistedStorage]);

    useEffect(() => {
        document.head.appendChild(
            document.createElement('style')
        ).innerHTML = `button:before {color: ${selectedTheme.colors.primaryText} !important;}`;
    }, []);

    function compare(a, b) {
        if (a.disabled < b.disabled) return -1;
        if (a.disabled > b.disabled) return 1;
        return 0;
    }

    const sortedSliderItems = (() => {
        return sliderItems.sort(compare);
    })();

    return (
        <PageContainer>
            <PageWrapper>
                <Header menuButtons={headerRoutes} withEscapeProButton={true} />
                <MainWrapper>
                    <TextWrapper>
                        {process.env.REACT_APP_THEME_KEY === 'ONEY' ? (
                            <MainText>
                                Aider vos clients à se protéger contre les aléas
                                du quotidien
                            </MainText>
                        ) : (
                            <MainText>
                                Aider vos clients à se protéger contre les aléas
                                du quotidien
                            </MainText>
                        )}
                    </TextWrapper>
                </MainWrapper>
            </PageWrapper>
            <HorizontalWrapper>
                <CardsWrapper>
                    {someCards.map((card: someCardsProps) => {
                        const {
                            title,
                            description,
                            icon,
                            buttonText,
                            buttonLink,
                        } = card;
                        return (
                            <InterestsCard
                                title={title}
                                description={description}
                                key={title}
                                icon={icon}
                                buttonText={buttonText}
                                buttonLink={buttonLink}
                            />
                        );
                    })}
                </CardsWrapper>
            </HorizontalWrapper>
            <TransportSection>
                <TransportTitle>
                    Accès rapide au parcours de souscription
                </TransportTitle>
                <TransportSliderWrapper>
                    <Slider {...transportSliderSettings}>
                        {sortedSliderItems.map(
                            (
                                {
                                    title,
                                    description,
                                    icon,
                                    buttonText,
                                    disabled,
                                    to,
                                },
                                index
                            ) => (
                                <TransportCard
                                    key={index}
                                    title={title}
                                    description={description}
                                    icon={icon}
                                    buttonText={buttonText}
                                    disabled={disabled}
                                    to={to}
                                />
                            )
                        )}
                    </Slider>
                </TransportSliderWrapper>
            </TransportSection>
            {/* <Section background="sectionBg1">
            </Section> */}
            <Section>
                <HorizontalWrapper>
                    <TransportTitle>Foire aux questions</TransportTitle>
                    <FAQ faqs={faqCards} />
                </HorizontalWrapper>
            </Section>
            {/* <RulesContainer /> */}
            {/* <EmptyContainer /> */}
            <Footer />
        </PageContainer>
    );
};

export default EscapePro;
