import styled from 'styled-components';
import { StyledThemePropsType } from '../../../../../config/theme';
import { DefaultTransition } from '../../../../../shared/styles';
import {
    H3Styles,
    H2Styles,
    BottomSingleTextDesktopStyles,
} from '../../../../../shared/typography';

export const CardWrapper = styled.div``;

export const Card = styled.div`
    width: 273px;
    height: 546px;
    border-radius: 5px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    padding: 7px 19px 23px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const CardButton = styled.button`
    ${H3Styles};
    ${DefaultTransition};
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    border-radius: 6px;
    letter-spacing: 1px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.button};
    color: ${(props: StyledThemePropsType) => props.theme.colors.white};
    /* box-shadow: 0 2px 34px 0
        ${(props: StyledThemePropsType) => props.theme.colors.buttonShadow1}; */
    width: 256px;
    height: 85px;
    border: none;
    margin: 34px auto 0;
    cursor: pointer;
    padding: 0 35px;
    box-sizing: border-box;

    &:hover {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.buttonHover};
    }
`;

export const CardTitle = styled.div`
    ${H3Styles};
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    font-size: 18px;
    line-height: 27px;
`;

export const PlanName = styled.div`
    ${H3Styles};
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryLight};
    font-size: 18px;
    line-height: 27px;
    display: inline;
`;

export const CheckmarksWrapper = styled.div`
    margin: 51px 0 0;
    height: 152px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CardDivider = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 28px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.textStyleMain};
`;

export const UncheckIcon = styled.div`
    width: 16px;
    height: 1px;
    margin: 16px 7px 4px;
`;

export const PriceWrapper = styled.div`
    margin-top: 18px;
    display: flex;
`;

export const PriceText = styled.div`
    ${H2Styles};
    letter-spacing: 0.77px;
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryLight};
    font-size: 40px;
    line-height: 46px;
`;

type DescriptionTextProps = {
    bottom?: boolean;
};

export const DescriptionText = styled.div<DescriptionTextProps>`
    ${BottomSingleTextDesktopStyles};
    font-size: 20px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    letter-spacing: 1px;
    margin: ${(props: DescriptionTextProps) =>
        props.bottom ? '15px 0 0' : '10px 0 0 8px'};
`;

export const DescriptionTextBold = styled.span`
    font-weight: bold;
`;

export const ButtonTextBold = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
`;

export const CashBlock = styled.div`
    text-align: center;
    margin-top: 50px;
`;

export const PlafondLineMain = styled.p`
    margin: 0;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
`;

export const PlafondLineSub = styled.p`
    margin: 0;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
`;

export const PlafondHighlighted = styled.span`
    color: ${(props: StyledThemePropsType) => props.theme.colors.button};
`;

export const PlafondEvenMoreHighlighted = styled.span`
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: ${(props: StyledThemePropsType) => props.theme.colors.button};
`;

export const Uppercased = styled.span`
    text-transform: uppercase;
`;
