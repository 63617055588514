import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme/index';
import { DefaultFlexStyles } from '../../../shared/styles/index';
import { LabelStyles } from '../../../shared/typography/index';
import { Vehicles } from '../../questionnaire/configuration/vehicles';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 28px;

    ${media.lg`
    width: 215px;
    margin-top: 40px;
    align-self: baseline;
  `};
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 3px;
    background-color: transparent;
    padding: 10px 20px 0px;
    margin: 0;
    width: 100%;

    ${(props: StyledThemePropsType) => media.lg`
    margin: 16px 0;
    padding: 30px 52px 42px;
    background-color: ${props.theme.colors.disabled};
  `};
`;

type IconContainerProps = {
    icon: Vehicles;
};

const IconContainer = styled.div<IconContainerProps>`
    ${({ theme, icon }: IconContainerProps & StyledThemePropsType) => css`
        background: url(${theme.icons[icon]}) no-repeat center;
        background-size: contain;
        margin: 0 auto;
        width: 85px;
        height: 120px;
        margin-bottom: 26px;
        ${media.lg`
    width: 127px;
		height: 164px;
	`};
        ${media.xl`
		margin-bottom: 0;
	`};
    `};
`;

const FieldsWrapper = styled.div`
    ${DefaultFlexStyles};
    width: 100%;
    flex-direction: column;
    align-content: space-between;
    ${media.xl`
		margin-top: 26px;
	`};

    ${media.xl`
    justify-content: space-between;
    flex-direction: row;
  `};
`;

const SingleField = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 28px;

    ${media.xl`
    margin-bottom: 32px;
    width: 405px;
  `};
`;

const CheckboxWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: baseline;
    margin: 15px 0 0;
`;

type CheckBoxError = {
    error: boolean;
};

const CustomSpan = styled.span<CheckBoxError>`
    position: relative;
    display: block;
    margin-top: 3px;
    width: 13px;
    height: 13px;
    border-radius: 2px;
    border: solid 1px
        ${(props: StyledThemePropsType & CheckBoxError) => {
            return props.error
                ? props.theme.colors.error
                : props.theme.colors.primaryText;
        }};
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};

    :hover {
        cursor: pointer;
    }
`;

const PolicyText = styled.div<CheckBoxError>`
    ${({ error, theme }: StyledThemePropsType & CheckBoxError) => css`
        ${LabelStyles};
        line-height: 1.33;
        color: ${error ? theme.colors.error : theme.colors.textStyleFirst};
        margin-left: 7px;
        opacity: 0.8;
        cursor: pointer;
        flex: 20;
        color: ${theme.colors.darkColor};
        padding-left: 18px;

        ${media.xl`flex: auto;
      font-size: 20px;
      line-height: 140%;
    color: ${theme.colors.darkColor};
    `};
    `};
`;

const ElementsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${media.xl`
		flex-direction: row;
	`};
`;

const MessageWrapper = styled.div`
    width: 280px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%);

    ${media.lg`
		position: relative;
		top: 0;
		left: 0;
		z-index: 0;
		transform: translate(0);
		width: 476px;
		
		display: flex;
		align-items: flex-end;
	`};

    ${media.xl`
		margin-left: 28px;
	`};
`;

const HiddenWrapper = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000000;
    z-index: 100;
    ${media.lg`
		display: none;
	`};
`;

const TooltipWrapper = styled.div`
    position: absolute;
    top: 21px;
    left: 90px;
`;

const SingleFieldWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export {
    ButtonWrapper,
    ContentWrapper,
    IconContainer,
    FieldsWrapper,
    SingleField,
    CheckboxWrapper,
    PolicyText,
    CustomSpan,
    ElementsWrapper,
    MessageWrapper,
    HiddenWrapper,
    TooltipWrapper,
    SingleFieldWrapper,
};
