import React from 'react';
import {
    CardWrapper,
    Card,
    CardButton,
    CardTitle,
    PlanName,
    CheckmarksWrapper,
    CardDivider,
    UncheckIcon,
    PriceWrapper,
    PriceText,
    DescriptionText,
    DescriptionTextBold,
    ButtonTextBold,
    CashBlock,
    PlafondLineSub,
    PlafondLineMain,
    PlafondEvenMoreHighlighted,
    PlafondHighlighted,
} from './elements';
import { CustomIcon } from '../../../../../shared/elements';
import { useHistory } from 'react-router-dom';

interface PlanCardProps {
    buttonText: string;
    buttonTextBold?: string;
    tariffName: string;
    checks: boolean[];
    price: number;
    wholePrice: number;
    url: string;
    sinistrePrice: number;
    sinistreQuant: number;
}

const PlanCardDesktop: React.FC<PlanCardProps> = ({
    buttonText,
    buttonTextBold = '',
    tariffName,
    checks,
    price,
    url,
    sinistrePrice,
    sinistreQuant,
}) => {
    const { push } = useHistory();

    const priceFormatted = price.toFixed(2).replace('.', ',');
    const yearlyPriceFormatted = (price * 12).toFixed(2).replace('.', ',');

    return (
        <CardWrapper>
            <Card>
                <CardTitle>
                    Formule &nbsp;
                    <PlanName>{tariffName}</PlanName>
                </CardTitle>
                <CheckmarksWrapper>
                    {checks.map((check, index) => {
                        return check ? (
                            <CustomIcon
                                key={index}
                                icon="check"
                                iconColor="checkMark"
                                width="30"
                                height="21"
                            />
                        ) : (
                            <UncheckIcon key={index} />
                        );
                    })}
                </CheckmarksWrapper>
                <CashBlock>
                    {/* <PlafondLineSub>Plafond de </PlafondLineSub> */}
                    <PlafondLineMain>
                        <PlafondEvenMoreHighlighted>
                            {sinistrePrice} €
                        </PlafondEvenMoreHighlighted>{' '}
                        / sinistre
                    </PlafondLineMain>
                    <PlafondLineMain>
                        {/* <PlafondHighlighted>
                            {sinistreQuant} sinistre
                        </PlafondHighlighted>{' '} */}
                        {sinistreQuant} sinistre{sinistreQuant > 1 ? 's' : null}{' '}
                        / an
                    </PlafondLineMain>
                    <PlafondLineSub>Réparations illimitées</PlafondLineSub>
                </CashBlock>
                <CardDivider />
                <PriceWrapper>
                    <PriceText>{priceFormatted} €</PriceText>
                    <DescriptionText>/ mois</DescriptionText>
                </PriceWrapper>
                <DescriptionText bottom>
                    soit{' '}
                    <DescriptionTextBold>
                        {yearlyPriceFormatted} €
                    </DescriptionTextBold>{' '}
                    / an
                </DescriptionText>
            </Card>
            <CardButton
                onClick={() => {
                    push(url);
                }}
            >
                {buttonText}
                <br />
                <ButtonTextBold>{buttonTextBold}</ButtonTextBold>
            </CardButton>
        </CardWrapper>
    );
};

export default PlanCardDesktop;
