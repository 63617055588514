import Icons from "../../../shared/icons/index";

export enum Vehicles {
  gyropode = "gyropode",
  gyroroue = "gyroroue",
  hoverboard = "hoverboard",
  onewheel = "onewheel",
  skateboard = "skateboard",
  trottinette = "trottinette",
  draisienne = "draisienne",
  alluraMobile = "alluraMobile",
  protection1 = "protection1",
  assures1 = "assures1",
  assures2 = "assures2",
  assures3 = "assures3",
  assures4 = "assures4",
}

const VehicleIcons: { [key in keyof typeof Vehicles]: string } = {
  "gyropode": Icons.gyropode,
  "gyroroue": Icons.gyroroue,
  "hoverboard": Icons.hoverboard,
  "onewheel": Icons.onewheel,
  "skateboard": Icons.skateboard,
  "trottinette": Icons.trottinette,
  "draisienne": Icons.draisienne,
  "alluraMobile": Icons.alluraMobile,
  "protection1": Icons.protection1,
  "assures1": Icons.assures1,
  "assures2": Icons.assures2,
  "assures3": Icons.assures3,
  "assures4": Icons.assures4,
};

export default VehicleIcons;
