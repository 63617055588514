import React, { useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
    DatepickerWrapper,
    LabelContainer,
    DatepickerInput,
    DatepickerIcon,
} from './element';
import 'react-datepicker/dist/react-datepicker.css';
import { HelpText } from '../TextField/elements';
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

interface DatepickerProps {
    label?: string;
    placeholder?: string;
    value?: Date;
    onChange?: (value: Date) => void;
    formatShortWeekday?: (value: Date) => void;
    error?: boolean;
    helpText?: string;
    maxDate?: Date;
    minDate?: Date;
    dateFormat?: string;
    showMonthYearPicker?: boolean;
    showFullMonthYearPicker?: boolean;
    showYearPicker?: boolean;
    calendar?: boolean;
    className?: string;
    dropDownArrow?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
}

const SharedDatePicker: React.FC<DatepickerProps> = ({
    label,
    placeholder,
    onChange,
    formatShortWeekday,
    value,
    error,
    helpText,
    maxDate,
    minDate,
    dateFormat,
    showMonthYearPicker,
    showFullMonthYearPicker,
    showYearPicker,
    calendar,
    dropDownArrow,
    className,
    disabled,
    readOnly,
}) => {
    const datePickerRef = useRef<DatePicker>();

    const datePickerInput = <DatepickerInput error={error} />;
    const selectedValue = value ? new Date(value) : undefined;
    return (
        <DatepickerWrapper disabled={disabled}>
            <LabelContainer>{label}</LabelContainer>
            <DatePicker
                ref={datePickerRef}
                placeholderText={placeholder}
                customInput={datePickerInput}
                dateFormat={dateFormat}
                selected={selectedValue}
                onChange={onChange}
                maxDate={maxDate}
                minDate={minDate}
                dropdownMode="select"
                locale={'fr'}
                // locale={process.env.REACT_APP_USER_LOCATION}
                readOnly={readOnly}
                disabled={disabled}
                showMonthYearPicker={showMonthYearPicker}
                showFullMonthYearPicker={showFullMonthYearPicker}
            />
            {helpText ? <HelpText error={error}>{helpText}</HelpText> : null}
            {calendar ? <DatepickerIcon /> : null}
        </DatepickerWrapper>
    );
};

export default SharedDatePicker;
