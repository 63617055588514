import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../config/theme/index";
import { Container, PageContainer } from "../../shared/layout/Page/index";
import { HeroDescriptionStyles, HeroTitleStyles } from "../../shared/typography/index";

const FluidPageContainer = styled(Container)`
  margin: auto;
`;

const ErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;  
`;

const ErrorContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 264px;
  margin: 120px auto;
  
  ${media.lg`
    width: 580px;
    margin: 150px auto 160px;
  `};
`;

const ErrorTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.2px;
  text-align: center;
  
  ${media.lg`
    ${HeroTitleStyles};
  `};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  margin-bottom: 11px;
`;

const ErrorDescription = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  text-align: center;
  
  ${media.lg`
    ${HeroDescriptionStyles};
  `};
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
  margin-bottom: 33px;
`;

const FluidErrorPageContainer = styled(PageContainer)`
  min-height: 100vh;
`;

export {
  ErrorPageWrapper,
  ErrorContentWrapper,
  ErrorTitle,
  ErrorDescription,
  FluidPageContainer,
  FluidErrorPageContainer
};
