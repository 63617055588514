import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../shared/elements';
import HeaderVoucher from '../../shared/elements/HeaderVoucher';
import CustomIcon from '../../shared/elements/Icon';
import { AccessContext } from '../../store';
import {
    SuccessWrapper,
    SuccesTitle,
    SuccesDescription,
    AdditionalInfo,
    Container,
    SuccessTextWrapper,
    IconWrapper,
} from './elements';

const SuccessPage = () => {
    const history = useHistory();
    const { saveAccessInfo } = useContext(AccessContext);

    const handleRedirectToForms = useCallback(() => {
        history.push('/');
        saveAccessInfo({ accessFlag: false });
    }, [history, saveAccessInfo]);

    return (
        <Container>
            <HeaderVoucher />
            <SuccessWrapper>
                <SuccessTextWrapper>
                    <IconWrapper>
                        <CustomIcon
                            icon="letter"
                            iconColor="button"
                            height="60"
                            width="60"
                            margin="0 0 24px 0"
                        />
                    </IconWrapper>
                    <SuccesTitle>
                        Merci! Dans 2 minutes votre client(e) va recevoir un
                        lien d’activation par sms ou email
                    </SuccesTitle>
                    <SuccesDescription>
                        Si votre client utilise tout de suite son lien, il
                        choisit son assurance responsabilité civile et s’assure
                        en quelques instants
                    </SuccesDescription>
                    <Button
                        onClick={handleRedirectToForms}
                        size="l"
                        variant="dark"
                        fullWidth={true}
                        disabled={false}
                    >
                        OK
                    </Button>
                    <AdditionalInfo>
                        Besoin d’aide? Votre client n’a pas reçu le lien
                        d’activation ou vous avez une question ? Appelez-nous au
                        XXXXXXXXX
                    </AdditionalInfo>
                </SuccessTextWrapper>
            </SuccessWrapper>
        </Container>
    );
};

export default SuccessPage;
