import React from 'react';
import Icons from '../../icons/defaultTheme';
import { Logo, PageDescription } from './elements';

const Header = () => {
    return (
        <>
            <Logo src={Icons.logo} />
            {/* <Logo src={selectedIconsThemeValue["logo"]} /> */}
            <PageDescription>
                En moins d'une minute, aidez votre client à obtenir sa
                responsabilité civile
            </PageDescription>
        </>
    );
};

export default Header;
