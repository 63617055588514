import React, {
    useCallback,
    useContext,
    useLayoutEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    CustomCheckBox,
    Logo,
    Message,
    Stepper,
} from '../../../shared/elements/index';
import { stepperMock } from '../../../shared/elements/Stepper/mock';
import createFullDriversBirthday from '../../../shared/helpers/fullDriversBirthday';
import createDiscountString from '../../../shared/helpers/createDiscountString';
import { useRedirection } from '../../../shared/helpers/useRedirection';
import { Container } from '../../../shared/layout/Page/';
import Page, { PageContainer } from '../../../shared/layout/Page/index';
import {
    AddressContext,
    AddressInfoFields,
} from '../../../store/context/subscription/address';
import { DriversContext } from '../../../store/context/subscription/drivers';
import { InsuranceContext } from '../../../store/context/subscription/insurance';
import {
    PaymentContext,
    PaymentFrequency,
} from '../../../store/context/subscription/payment';
import { SummaryContext } from '../../../store/context/subscription/summary';
import { PersonalTransportContext } from '../../../store/context/subscription/transport';
import { ErrorMessage } from '../../questionnaire/Step3';
import { Driver } from '../drivers/mocks';
import { QuizStepTitle, QuizStepWrapper } from '../elements';
import { InsuranceTariff } from '../insurance/TariffCard/index';
import { PaymentInfoFields } from '../payment/index';
import { CheckboxWrapper, CustomSpan, PolicyText } from '../transport/elements';
import { BasicTransportInfo } from '../transport/index';
import AddressInfo from './addressInfo/index';
import {
    ElementsWrapper,
    HiddenWrapper,
    MessageWrapper,
    PaymentContent,
    SubmitButtonContainer,
    TwoBLocksColumn,
    SummaryWrapperText,
    SummaryTitle,
    SummaryTitleBold,
    SummaryText,
} from './elements';
import InsuranceInfo, { CouponDetails } from './insuranceInfo/index';
import PaymentInfo from './paymentInfo/index';
import TransportInfo from './transportInfo/index';
import Footer from '../../../shared/elements/Footer';

const Summary = () => {
    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    /**
     * Logic related to the routing actions.
     */
    const { push } = useHistory();
    // const [transportRoute] = useRedirection({
    //     currentRoute: 'summary',
    //     nextRoute: 'transport',
    // });
    const [driversRoute] = useRedirection({
        currentRoute: 'summary',
        nextRoute: 'drivers',
    });
    const [addressRoute] = useRedirection({
        currentRoute: 'summary',
        nextRoute: 'address',
    });
    const [insuranceRoute] = useRedirection({
        currentRoute: 'summary',
        nextRoute: 'insurance',
    });
    const [paymentRoute] = useRedirection({
        currentRoute: 'summary',
        nextRoute: 'payment',
    });
    const [redirectionRoute, prevRoute] = useRedirection({
        currentRoute: 'summary',
        nextRoute: 'redirection',
        prevRoute: 'payment',
    });
    const back = useCallback(() => push(prevRoute), [push, prevRoute]);
    const modifyPreviousSteps = useCallback(
        (route: string) => () => push(route),
        [push]
    );

    /**
     * Logic related to the storing page-data in the state.
     */
    const {
        type,
        brand,
        typeName,
        brandName,
        model,
        serial,
        speedLimitPolicy,
    } = useContext(PersonalTransportContext);
    const [transportState] = useState<BasicTransportInfo>({
        type,
        brand,
        typeName,
        brandName,
        model,
        serial,
        speedLimitPolicy,
    });
    const { drivers } = useContext(DriversContext);
    const [driversState] = useState<Driver[]>([...drivers]);
    const {
        address,
        city,
        email,
        information,
        phone,
        postalCode,
        subscriptionEmail,
        subscriptionSms,
        // subscription,
    } = useContext(AddressContext);
    // const subscriptionEmail = false;
    // const subscriptionSms = false;
    const [addressState] = useState<AddressInfoFields>({
        address,
        city,
        email,
        information,
        phone,
        postalCode,
        subscriptionEmail,
        subscriptionSms,
        // subscription,
    });
    const { regular, premium } = useContext(InsuranceContext);
    const insuranceState: InsuranceTariff = regular.selected
        ? regular
        : premium;
    const {
        iban,
        startDate,
        tariff,
        userName,
        frequency,
        discount,
        voucher,
        formula,
    } = useContext(PaymentContext);
    const [paymentState] = useState<PaymentInfoFields>({
        iban,
        startDate,
        tariff,
        userName,
        frequency,
        discount,
        voucher,
        formula,
    });
    const prixDescription =
        frequency === PaymentFrequency.yearly
            ? `${insuranceState.yearlyPrice
                  .toFixed(2)
                  .replace('.', ',')} € / année`
            : `${insuranceState.monthlyPrice
                  .toFixed(2)
                  .replace('.', ',')} € / mois`;

    /**
     * Logic related to the API requests.
     */
    const formattedDriversArray = drivers.map(
        ({ firstName, lastName, city: birthPlace, day, month, year }) => {
            const fullDateString = createFullDriversBirthday(day, month, year);
            return {
                firstName,
                lastName,
                birthPlace,
                birthDay: fullDateString,
            };
        }
    );

    const [error, setError] = useState<ErrorMessage>({
        display: false,
        message: 'Vous devez attester sur l’honneur avant de poursuivre .',
    });
    const hideErrorMessage = useCallback(
        () => setError({ ...error, display: false }),
        [error]
    );
    const showErrorComponent = error.display && !!error.message;

    const [checkbox, setCheckbox] = useState(false);
    const [checkboxFlag, setCheckboxFlag] = useState(true);

    const controlCheckBox = useCallback(() => {
        setCheckbox(!checkbox);
        setCheckboxFlag(true);
        setError({ display: false, message: error.message });
    }, [error, setCheckboxFlag, checkbox]);

    const { completeSummaryInfo } = useContext(SummaryContext);

    const submitSummaryPage = useCallback(() => {
        if (!checkbox) {
            setCheckboxFlag(false);
            setError({ display: true, message: error.message });
        } else {
            setCheckboxFlag(true);
            setError({ ...error, display: false });
            completeSummaryInfo({ completed: true });
            push('/');
            // push(redirectionRoute);
        }
    }, [checkbox, push, error, completeSummaryInfo]);

    /**
     * General logic and variables needed to define some render settings.
     */
    const coupon = !!discount;
    const couponDetailsObject: CouponDetails = {
        title: 'AVANTAGE COUPON',
        priceDescription: insuranceState.discount
            ? createDiscountString(
                  insuranceState.discount,
                  insuranceState.monthlyPrice,
                  insuranceState.yearlyPrice
              )
            : '',
    };
    return (
        <PageContainer>
            <Logo showGoBack={true} goBack={back} />
            <Stepper steps={stepperMock.steps} currentlySelected={3} />
            <Page>
                <Container>
                    <QuizStepWrapper>
                        <QuizStepTitle>
                            Récapitulons votre besoin avant de finaliser votre
                            adhésion à l’assurance produits nomades du foyer
                        </QuizStepTitle>
                        <PaymentContent>
                            <TwoBLocksColumn>
                                <InsuranceInfo
                                    tariffName={insuranceState.title}
                                    priceDescription={prixDescription}
                                    startDate={startDate}
                                    showCoupon={coupon}
                                    couponDetails={couponDetailsObject}
                                    modify={modifyPreviousSteps(insuranceRoute)}
                                />
                                <AddressInfo
                                    firstName={driversState[0].firstName}
                                    lastName={driversState[0].lastName}
                                    date={formattedDriversArray[0].birthDay}
                                    address={`${addressState.address}, ${addressState.postalCode}, ${addressState.city}`}
                                    country={driversState[0].country}
                                    email={addressState.email}
                                    phone={addressState.phone}
                                    modify={modifyPreviousSteps(driversRoute)}
                                />
                                {/* <TransportInfo
                                    transport={transportState}
                                    driversArray={driversState}
                                    modify={modifyPreviousSteps(transportRoute)}
                                /> */}
                            </TwoBLocksColumn>
                            <TwoBLocksColumn>
                                <PaymentInfo
                                    userName={paymentState.userName}
                                    iban={paymentState.iban}
                                    frequency={paymentState.frequency}
                                    modify={modifyPreviousSteps(paymentRoute)}
                                />
                            </TwoBLocksColumn>
                        </PaymentContent>
                        <CheckboxWrapper>
                            <label>
                                <CustomCheckBox
                                    disabled={false}
                                    checked={checkbox}
                                    onChange={controlCheckBox}
                                />
                                <CustomSpan error={!checkboxFlag} />
                            </label>
                            <PolicyText
                                onClick={controlCheckBox}
                                error={!checkboxFlag}
                            >
                                Je certifie exactes les informations déclarées
                                ci-dessus. En cas de fausses déclarations, je
                                m'expose à des sanctions, notamment la nullité
                                du contrat ou la réduction des indemnités
                                (articles L 113-8 et L 113-9 du Code des
                                Assurances).
                            </PolicyText>
                        </CheckboxWrapper>
                        <ElementsWrapper>
                            <SubmitButtonContainer>
                                <Button
                                    fullWidth={true}
                                    variant="dark"
                                    size="l"
                                    active={checkbox}
                                    disabled={false}
                                    onClick={submitSummaryPage}
                                >
                                    Finaliser l'adhésion
                                </Button>
                            </SubmitButtonContainer>
                            {showErrorComponent ? (
                                <>
                                    <HiddenWrapper />
                                    <MessageWrapper>
                                        <Message
                                            close={hideErrorMessage}
                                            icon="error"
                                            iconColor="error"
                                            backgroundColor="errorBackground"
                                            textColor="error"
                                            message={error.message}
                                        />
                                    </MessageWrapper>
                                </>
                            ) : null}
                        </ElementsWrapper>
                        {/* <SummaryWrapperText>
                            <SummaryTitle>
                                <SummaryTitleBold>
                                    INFORMATIONS A CARACTERE COMMERCIAL SUR LE
                                    CONTRAT PROPOSE
                                </SummaryTitleBold>{' '}
                                (article L112-2-1 III du code des assurances) :
                            </SummaryTitle>
                            <SummaryText>
                                L'assureur du contrat est L'EQUITE, SA au
                                capital de 26.469.320 €, Entreprise régie par le
                                code des assurances, RCS Paris 572084697, 2 rue
                                Pillet Will 75009 PARIS, Société appartenant au
                                Groupe Generali, immatriculé au registre italien
                                des groupes d'assurance sous le numéro 026 -
                                Société soumise au contrôle de l'ACPR 4 place de
                                Budapest - CS 92459 - 75436 Paris Cedex 09.{' '}
                                <br /> <br />
                                Le montant total de la prime du contrat pour la
                                première année d'assurance est indiqué
                                ci-dessus. Le contrat proposé est d'une durée
                                minimale de 1 an avec tacite reconduction
                                annuelle sous réserve du paiement de la
                                cotisation. Les garanties, exclusions, et autres
                                conditions contractuelles du contrat proposé,
                                figurent aux dispositions générales et
                                particulières du contrat. Les informations sur
                                le contrat proposé sont valables pendant la
                                durée du parcours de souscription jusqu’à
                                signature. <br />
                                <br /> Le contrat sera conclu par signature
                                électronique après acceptation des Conditions
                                Générales d'Utilisation de la signature
                                électronique par le souscripteur. La prime est
                                payable par prélèvement sur votre compte
                                bancaire. La souscription du contrat RC EDPM ne
                                vous permet pas de bénéficier d'un délai de
                                renonciation. <br />
                                <br /> Les relations précontractuelles et
                                contractuelles sont régies par le droit français
                                et notamment, le code des assurances, et seront
                                en langue française. Les modalités d'examen de
                                vos éventuelles réclamations et les cas et
                                conditions dans lesquels vous pouvez saisir le
                                service de Médiation des assurances ou la
                                plateforme de règlement en ligne des litiges,
                                sont précisées dans les Dispositions Générales
                                du contrat.
                            </SummaryText>
                        </SummaryWrapperText> */}
                    </QuizStepWrapper>
                </Container>
            </Page>
            <Footer />
        </PageContainer>
    );
};

export default Summary;
