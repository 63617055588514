import { InterestsCardProps } from "./index";

export const product1: InterestsCardProps[] = [
  {
    title: "Assurance produits nomades foyer ",
    description: "Protection en cas de casse ou vol de tous les appareils nomades du foyer",
    icon: "protection1",
    buttonText: "Je m’assure",
    disabled: false,
    to: "/insurance"
  },
  {
    title: "Assurance casse-vol mobile",
    description: "Protection et réparation en cas de vol, casse et oxydation de votre mobile",
    icon: "protection2",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
  {
    title: "Extension de garantie panne",
    description: "Réparation ou remplacement des appareils électroniques en cas de panne",
    icon: "protection3",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
  {
    title: "Assurance multirisque habitation",
    description: "Couverture multirisques classique, à prix réduits et facile à souscrire",
    icon: "protection4",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
];

export const product2: InterestsCardProps[] = [
  {
    title: "Assurance responsabilité civile obligatoire",
    description: "Garantie obligatoire pour tout conducteur d’EDPM",
    icon: "mobilities1",
    buttonText: "Je m’assure",
    disabled: false,
    to: "https://covea.app.assur-link.com/"
  },
  {
    title: "Assurance casse-vol EDPM",
    description: "Casse ou vol de l'EDPM? Nous prenons en charge la réparation ou le remplacement",
    icon: "mobilities2",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
  {
    title: "Assurance casse-vol vélo électrique",
    description: "Prise en charge des réparations ou du remplacement",
    icon: "mobilities3",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
  {
    title: "Assurance casse-vol vélo",
    description: "Prise en charge des réparations ou du remplacement",
    icon: "mobilities4",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
  {
    title: "Assurance famille mobilité",
    description: "Un seul contrat pour garantir toutes les mobilités douces de de la famille",
    icon: "mobilities5",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
];

export const product3: InterestsCardProps[] = [
  {
    title: "Assurance scolaire ",
    description: "Pour assurer les activités scolaires et extra-scolaires de vos enfants",
    icon: "protection5",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
  {
    title: "Protection accident",
    description: "Pour vous protéger contre les accidents du quotidien ",
    icon: "protection6",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
  {
    title: "Assurance animaux",
    description: "Pour prendre soins de vos petits compagnons",
    icon: "protection7",
    buttonText: "Bientôt disponible",
    disabled: true,
  },
];
