import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { InputLabelTextDesktopStyles } from "../../typography/index";

const FooterNavWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.highlightedText};
  padding: 14px 43px;

  ${media.lg`
    padding: 5px 0;
  `};
`;

const FooterNavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 690px;
  flex-direction: column;
  ${media.lg`
    flex-direction: row;
  `};
`;

const FooterLink = styled.a`
  ${InputLabelTextDesktopStyles};
  line-height: 1.17;
  text-decoration: none;
  color: ${(props: StyledThemePropsType) => props.theme.colors.container};
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;

  &:visited {
    color: ${(props: StyledThemePropsType) => props.theme.colors.container};
  }
`;

const LinkDivider = styled.div`
  width: 1px;
  height: 1px;
  margin: 10px 0;
  background-color: transparent;
  ${(props: StyledThemePropsType) => {
    return media.lg`
      background-color: ${props.theme.colors.container};
      margin: 0 15px;
      height: 16px;
    `;
  }};
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
`;

export { FooterNavWrapper, FooterNavContent, FooterLink, LinkDivider, SocialLinks };
