import React, {
    useCallback,
    useContext,
    useLayoutEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
    CustomCheckBox,
    CustomIcon,
    Message,
} from '../../../shared/elements/index';
import { QuestionnaireContext } from '../../../store/context/questionnaire/questionnaire';
import {
    ButtonWrapper,
    SummaryContainer,
    SummaryItemText,
    SummaryItemWrapper,
    AgreementText,
    AgreementWrapper,
    SubmitButton,
    ElementsStep5Wrapper,
    CustomQuestionnaireSpan,
} from './elements';
import { HiddenWrapper } from '../elements';
import { mockedSummaryArray } from './mock';
import { MessageWrapper } from '../../subscription/transport/elements';
import { FifthStepError } from '../configuration';
const Step4 = () => {
    const [agree, setAgree] = useState(false);
    const [showError, setShowError] = useState<boolean>(false);

    useLayoutEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
    /**
     * Logic related to the routing actions.
     */
    const { push } = useHistory();

    const {
        vehicle,
        saveQuestionnaireInfo,
        vehicleName,
        ...questionnaireInfo
    } = useContext(QuestionnaireContext);
    const [errorFlag, setErrorFlag] = useState(false);
    const onCheck = useCallback(() => {
        setAgree(!agree);

        setErrorFlag(false);
        setShowError(false);
    }, [setAgree, agree, setErrorFlag]);

    const savePageProgress = useCallback(() => {
        if (agree) {
            saveQuestionnaireInfo({
                ...questionnaireInfo,
                vehicle,
                vehicleName,
                summaryStepCompleted: true,
            });
            push('/subscription');
        }
        setErrorFlag(true);
        setShowError(true);
    }, [
        push,
        agree,
        questionnaireInfo,
        saveQuestionnaireInfo,
        vehicle,
        vehicleName,
    ]);

    const onCloseError = useCallback(() => setShowError(false), []);
    return (
        <SummaryContainer>
            {mockedSummaryArray.map((questionSummary, index) => (
                <SummaryItemWrapper key={index}>
                    <CustomIcon
                        icon="check"
                        iconColor="checkMark"
                        margin="0 20px 0 0"
                        height="15"
                        width="21"
                    />
                    <SummaryItemText>{questionSummary}</SummaryItemText>
                </SummaryItemWrapper>
            ))}
            <AgreementWrapper>
                <label>
                    <CustomCheckBox
                        disabled={false}
                        checked={agree}
                        onChange={onCheck}
                    />
                    <CustomQuestionnaireSpan error={errorFlag} />
                </label>
                <AgreementText onClick={onCheck} error={errorFlag}>
                    Je certifie que ces réponses sont exactes et confirme mon
                    souhait de souscrire une garantie produits nomades du foyer
                    pour protéger mes appareils multimédias et ceux de mon foyer
                </AgreementText>
            </AgreementWrapper>
            <ElementsStep5Wrapper>
                <ButtonWrapper>
                    <SubmitButton
                        isDisabled={!agree}
                        onClick={savePageProgress}
                    >
                        Choisir ma garantie
                    </SubmitButton>
                </ButtonWrapper>

                {showError && (
                    <>
                        <HiddenWrapper />
                        <MessageWrapper>
                            <Message
                                close={onCloseError}
                                icon="error"
                                iconColor="error"
                                backgroundColor="errorBackground"
                                textColor="error"
                                message={FifthStepError()}
                            />
                        </MessageWrapper>
                    </>
                )}
            </ElementsStep5Wrapper>
        </SummaryContainer>
    );
};

export default Step4;
