import React, { useCallback } from 'react';
import { Header } from '../../shared/elements';
import Footer from '../../shared/elements/Footer/index';
import FooterMessageBlock from '../../shared/elements/FooterMessageBlock/index';
import Page, { Container, PageContainer } from '../../shared/layout/Page/index';
import ContactMethod from './ContactMethod/index';
import {
    ContactImage,
    ContactImageTitle,
    ContactInfoWrapper,
    ContactLink,
    ContactText,
    LeftContactInfo,
    QuestionBlock,
    QuestionCards,
    QuestionText,
    RightContactInfo,
    RightSideTitle,
    TopImageBlock,
    TransparentTextBlock,
} from './elements';
import QuestionCard from './QuestionCard/index';
import { headerRoutes } from '../../routing/headerRoutes';

const Contact = () => {
    const redirectDecouvrir = useCallback(() => {
        return window.open(
            'https://staging.d2gf2qmsb9112s.amplifyapp.com/',
            '_blank'
        );
    }, []);

    const redirectDeclarer = useCallback(() => {
        return window.open('https://jsc-recette.atm-assur.com/', '_blank');
    }, []);

    return (
        <PageContainer>
            <Header menuButtons={headerRoutes} />
            <TopImageBlock>
                <TransparentTextBlock>
                    Contacter l’équipe de Covéa Affinity
                </TransparentTextBlock>
            </TopImageBlock>
            <Page>
                <Container>
                    <ContactInfoWrapper>
                        <LeftContactInfo>
                            <ContactImageTitle>
                                Accueil / Contact
                            </ContactImageTitle>
                            <ContactImage />
                        </LeftContactInfo>
                        <RightContactInfo>
                            <RightSideTitle>
                                Vous pouvez nous contacter du lundi au samedi de
                                8h à 19h.
                            </RightSideTitle>
                            <ContactMethod icon="mail" text="Par mail" />
                            <ContactText>
                                contact@covea-affinity.com
                            </ContactText>
                            <ContactMethod icon="chat" text="Par chat" />
                            <ContactLink href="#replain-link">
                                Écrivez-nous et nous vous répondons en quelques
                                minutes
                            </ContactLink>
                        </RightContactInfo>
                    </ContactInfoWrapper>
                </Container>
            </Page>
            <QuestionBlock>
                <QuestionText>vous avez une question ?</QuestionText>
                <QuestionCards>
                    <QuestionCard
                        title="En savoir plus sur l’assurance"
                        description="Découvrez les différentes offres et Garanties de covéa affinity"
                        buttonText="Découvrir"
                        onClick={redirectDecouvrir}
                    />
                    <QuestionCard
                        title="Déclarer un sinistre"
                        description="Prenez  quelques minutes pour nous décrire votre sinistre "
                        buttonText="Déclarer"
                        onClick={redirectDeclarer}
                    />
                </QuestionCards>
            </QuestionBlock>
            <FooterMessageBlock />
            <Footer />
        </PageContainer>
    );
};

export default Contact;
