import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme';

interface PriceProps {
    price: number;
    priceYearly: number;
    active: boolean;
}

const Price: FC<PriceProps> = ({ price, priceYearly, active }) => {
    const firstPart = price.toFixed(2).toString().split('.')[0];
    const secondPart = price.toFixed(2).toString().split('.')[1];
    const yearly = String(priceYearly.toFixed(2)).replace('.', ',');

    return (
        <PriceWrapper>
            <PriceLine>
                <PriceLineText active={active}>
                    {firstPart}
                    {`,${secondPart} `}€{` `}
                </PriceLineText>
                <MonthText active={active}>/{` `}mois</MonthText>
            </PriceLine>
            <YearlyText>
                soit <Boldy>{yearly} €</Boldy> / an
            </YearlyText>
        </PriceWrapper>
    );
};

type TariffCardContainerProps = {
    active: boolean;
};

const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const PriceLine = styled.div`
    display: flex;
    flex-direction: row;
`;

const PriceLineText = styled.span<TariffCardContainerProps>`
    /* font-size: ${(props: TariffCardContainerProps) =>
        props.active ? '55px' : '47px'}; */
    font-size: 40px;
    line-height: 46px;
    font-weight: bold;
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
    line-height: 69px;
`;

const MonthText = styled.span`
    margin: 32px 0 0 4px;
    /* font-size: ${(props: TariffCardContainerProps) =>
        props.active ? '19px' : '16px'}; */
    font-size: 19px;
    font-weight: bold;
    color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
    justify-self: flex-end;
`;

const YearlyText = styled.p`
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
`;

const Boldy = styled.span`
    font-weight: bold;
`;

export default Price;
