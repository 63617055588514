import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { StyledThemePropsType } from '../../../config/theme/index';
import { DefaultFlexStyles, DefaultTransition } from '../../styles/index';
import {
    BodyStyles,
    ButtonTextDesktopStyles,
    H3Styles,
} from '../../typography/index';

export type ButtonContainerProps = {
    variant: 'light' | 'dark' | 'white';
    size: 'xs' | 's' | 'm' | 'l' | 'sH' | 'mH' | 'auto';
    fullWidth?: boolean;
    disabled: boolean;
    active?: boolean;
    className?: string;
};

const sizeXS = css`
    width: 60px;
`;

const sizeS = css`
    width: 120px;
`;

const sizeM = css`
    width: 185px;
`;

const sizeL = css`
    width: 280px;
`;

const smallHigh = css`
    width: 134px;
    height: 62px;
`;

const mediumHigh = css`
    width: 166px;
    height: 62px;
`;

const auto = css`
    width: auto;
    height: 62px;
`;

const basicStyles = css`
    ${({ size }: ButtonContainerProps & StyledThemePropsType) => {
        switch (size) {
            case 'xs':
                return sizeXS;
            case 's':
                return sizeS;
            case 'm':
                return sizeM;
            case 'l':
                return sizeL;
            case 'sH':
                return smallHigh;
            case 'mH':
                return mediumHigh;
            case 'auto':
                return auto;
        }
    }};
    ${(props: ButtonContainerProps) =>
        props.fullWidth &&
        css`
            width: 100%;
        `};
    // In future, we could use custom helper hexToRgba.
    box-shadow: 0 2px 34px 0 rgba(47, 61, 64, 0.3);
    border: none;
    cursor: pointer;
    text-align: center;

    &:focus {
        outline: none;
    }
`;

const dark = css`
    ${basicStyles};
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.button};
    color: ${(props: StyledThemePropsType) => props.theme.colors.white};

    &:hover {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.buttonHover};
    }
`;

const light = css`
    ${basicStyles};
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    color: ${(props: StyledThemePropsType) =>
        props.theme.colors.primaryTextLight};
    border: 1px solid
        ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};

    &:hover {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.white};
        color: ${(props: StyledThemePropsType) =>
            props.theme.colors.primaryTextDark};
        border: 1px solid
            ${(props: StyledThemePropsType) =>
                props.theme.colors.primaryTextDark};
    }
`;

const white = css`
    ${basicStyles};
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    color: ${(props: StyledThemePropsType) =>
        props.theme.colors.highlightedText};
    border: 1px solid transparent;
    box-shadow: unset;

    &:hover {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.white};
        color: ${(props: StyledThemePropsType) =>
            props.theme.colors.primaryTextDark};
        border: 1px solid
            ${(props: StyledThemePropsType) =>
                props.theme.colors.primaryTextDark};
    }
`;

const disabledButtonStyles = css`
    box-shadow: unset;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.primaryTextLight};
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
    border: none;
    cursor: unset;

    &:hover {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.primaryTextLight};
    }
`;

const activeButtonStyles = css`
    /* opacity: 0.6; */
    /* &:hover {
        background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.primaryTextLight};
    } */
`;

const ButtonContainer = styled.button<ButtonContainerProps>`
    ${DefaultFlexStyles};
    ${ButtonTextDesktopStyles};
    ${DefaultTransition};
    /* text-transform: uppercase; */
    padding: 14px 17px;
    border-radius: 6px;
    ${({ size }: ButtonContainerProps) =>
        size === 'l' ? H3Styles : BodyStyles};
    ${({ variant }: StyledThemePropsType & ButtonContainerProps) => {
        switch (variant) {
            case 'dark':
                return dark;
            case 'light':
                return light;
            case 'white':
                return white;
        }
    }};
    ${({ disabled }: ButtonContainerProps & StyledThemePropsType) =>
        disabled && disabledButtonStyles};
    ${({ active }: ButtonContainerProps & StyledThemePropsType) =>
        !active ? activeButtonStyles : null};
    box-shadow: none;

    ${media.lg`
		padding: 20px 30px;
	`};
`;

export { ButtonContainer };
