import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DefinedIcons } from '../../../../shared/types/index';
import { someCardsProps } from '../../types';
import {
    ButtonWrapper,
    IconContainer,
    InterestCardBackground,
    InterestCardContainer,
    InterestDescription,
    InterestTitle,
    InterestButton,
} from './elements';

export type InterestsCardProps = {
    title: string;
    description: string;
    icon: DefinedIcons;
    buttonText: string;
};

const InterestsCard: FC<someCardsProps> = ({
    title,
    description,
    icon,
    buttonText,
    buttonLink,
}) => {
    const { push } = useHistory();

    const openNewTab = useCallback(() => {
        if (buttonLink.startsWith('http'))
            return window.open(buttonLink, '_blank');
        push(buttonLink);
    }, [buttonLink, push]);

    const goTo = useCallback(() => (buttonLink ? openNewTab() : undefined), [
        buttonLink,
        openNewTab,
    ]);
    return (
        <InterestCardContainer>
            <InterestCardBackground>
                <IconContainer icon={icon} />
                <InterestTitle>{title}</InterestTitle>
                <InterestDescription>{description}</InterestDescription>
            </InterestCardBackground>
            <ButtonWrapper>
                <InterestButton
                    variant="dark"
                    size="l"
                    active={true}
                    disabled={false}
                    onClick={goTo}
                >
                    {buttonText}
                </InterestButton>
            </ButtonWrapper>
        </InterestCardContainer>
    );
};

export default InterestsCard;
