import React, { useCallback } from 'react';
import {
    CardButton,
    CardSection,
    CardText,
    CardWrapper,
    HeadphonesIcon,
    IconsCont,
    MonitorIcon,
    SmartphoneIcon,
} from './elements';

type VoucherCardSectionProps = {
    scrollTo(): void;
};

const VoucherCardSection: React.FC<VoucherCardSectionProps> = ({
    scrollTo,
}) => {
    const scrollToThat = useCallback(() => {
        scrollTo();
    }, [scrollTo]);

    return (
        <CardSection>
            <CardWrapper>
                <IconsCont>
                    <SmartphoneIcon />
                    <MonitorIcon />
                    <HeadphonesIcon />
                </IconsCont>
                <CardText>
                    Vos possédez plusieurs appareils multimédias? Assurez-les
                    dès aujourd’hui contre les risques liés à leur utilisation
                    quotidienne.
                </CardText>
                <CardButton onClick={scrollToThat}>
                    Découvrir nos assurances
                </CardButton>
            </CardWrapper>
        </CardSection>
    );
};

export default VoucherCardSection;
