import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme/index';
import { Button } from '../../../../shared/elements';
import { DefinedIcons } from '../../../../shared/types/index';

export const InterestCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    /* margin: 0 auto; */
    border-radius: 6px;
    overflow: hidden;
    flex-basis: calc(100% / 2 - 10px);
    margin: 0 5px;
    min-height: 450px;
    justify-content: space-between;

    ${media.lg`
    width: 350px;
    flex-basis: calc(100% / 3 - 10px);
    margin: 0 5px;
    margin-bottom: 16px;
  `};
`;

export const InterestCardBackground = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    padding: 30px 11px;
    min-height: 355px;
    ${media.lg`
    padding: 32px 28px 24px;
    flex-grow: 1;
    margin-bottom: 4px;
  `};
`;

type IconContainerProps = StyledThemePropsType & {
    icon: DefinedIcons;
};

export const IconContainer = styled.div<IconContainerProps>`
    background: bottom no-repeat;
    background-image: url(${(props) => props.theme.icons[props.icon]});
    background-size: contain;
    width: 160px;
    height: 160px;
`;

export const InterestTitle = styled.div`
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-size: 26px;
    line-height: 110%;
    letter-spacing: 0.2px;
    margin: 24px 0 16px;
`;

export const InterestDescription = styled.div`
    color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.2px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    ${media.lg`
    margin-bottom: 32px;
  `};
    ${media.xl`
      margin-bottom: 0;
  `};
`;

export const InterestButton = styled(Button)`
    width: 100%;
    border-radius: 0;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.darkGray};
    background-color: #f09a00;
    color: ${(props: StyledThemePropsType) => props.theme.colors.white};

    &:hover {
        /* background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.darkGray}; */
        background-color: #b26900;
    }

    &:disabled {
        background-color: ${(props: StyledThemePropsType) =>
            props.theme.colors.disabled};
    }
`;
