import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import { DefinedIcons } from '../../../../shared/types/index';
import {
    ButtonWrapper,
    IconContainer,
    InterestCardBackground,
    InterestCardContainer,
    InterestDescription,
    InterestTitle,
    InterestButton,
} from './elements';

export type TransportCardProps = {
    title: string;
    description: string;
    icon: DefinedIcons;
    buttonText: string;
    disabled?: boolean;
    to?: string;
};

const TransportCard: FC<TransportCardProps> = ({
    title,
    description,
    icon,
    buttonText,
    disabled = false,
    to,
}) => {
    const { push } = useHistory();

    const openNewTab = useCallback(() => {
        if (to.startsWith('http')) return window.open(to, '_blank');
        push(to);
    }, [push, to]);

    const goTo = useCallback(() => (to ? openNewTab() : undefined), [
        openNewTab,
        to,
    ]);

    return (
        <InterestCardContainer>
            <InterestCardBackground>
                <IconContainer icon={icon} />
                <InterestTitle>{title}</InterestTitle>
                <InterestDescription>{description}</InterestDescription>
            </InterestCardBackground>
            <ButtonWrapper>
                <InterestButton
                    variant="dark"
                    size="l"
                    active={true}
                    disabled={disabled}
                    onClick={goTo}
                >
                    {buttonText}
                </InterestButton>
            </ButtonWrapper>
        </InterestCardContainer>
    );
};

export default TransportCard;
