import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../config/theme/index';
import { Button } from '../../shared/elements/index';
import {
    DefaultFlexStyles,
    DefaultTransition,
} from '../../shared/styles/index';
import {
    ErrorPopupTextDesktopStyles,
    H2MobileStyles,
    H2Styles,
} from '../../shared/typography/index';
import { media } from 'styled-bootstrap-grid';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const HeaderText = styled.div`
    ${DefaultFlexStyles};
    flex-direction: column;
    align-items: flex-start;
`;

const HeaderTitle = styled.div`
    ${H2MobileStyles};
    color: ${(props: StyledThemePropsType) =>
        props.theme.colors.primaryTextLight};
    padding-top: 87px;
    margin-bottom: 7px;
    text-align: left;

    ${media.lg`
    padding-top: 125px;
  `};

    ${(props: StyledThemePropsType) => media.xl`
    ${H2Styles};
    color: ${props.theme.colors.primaryTextLight};
  `};
`;

const HeaderDescription = styled.div`
    ${H2MobileStyles};
    padding-top: 87px;
    text-align: left;
    position: relative;
    font-size: 20px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
    ${media.lg`
    padding-top: 125px;
  `};
    ${(props: StyledThemePropsType) => media.xl`
    ${H2Styles};
    text-align: left;
		font-size: 22px;
		color: ${props.theme.colors.disabled};
  `};
`;

const TooltipWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 35px;
    right: 20px;
    ${media.lg`
		right:-13px;
		top: auto;
		bottom: 0;
  `};
`;

const ContentHeaderWrapper = styled.div`
    position: relative;
    max-width: 100%;
    padding: 0 10px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 220px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.disabled};
`;

const DialogButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    margin-top: 34px;
    position: relative;

    ${media.lg`
    flex-direction: row;
    width: 232px;
    margin-top: 56px;
  `};
`;

type DialogButtonProps = StyledThemePropsType & {
    primary?: boolean;
};

const DialogButton = styled(Button)<DialogButtonProps>`
    ${({ primary = false, theme }: DialogButtonProps) => css`
        ${DefaultTransition};
        width: 100%;
        max-width: 100%;
        min-width: 130px;
        padding: 16px 34px;
        border-radius: 3px;
        background-color: ${primary
            ? theme.colors.button
            : theme.colors.buttonSecondary};
        color: ${theme.colors.white};
        border: none;

        ${media.lg`
    width: 100px;
  `};

        &:hover {
            background-color: ${primary
                ? theme.colors.buttonHover
                : theme.colors.buttonSecondaryHover};
            color: ${theme.colors.white};
            border: none;
        }
    `}
`;

const DialogButtonSpacing = styled.div`
    width: 100%;
    margin: 32px 0 0 0;

    ${media.lg`
    margin: 0 0 0 32px;
  `};
`;

type TransportIconProps = {
    icon: string;
};

const TransportIcon = styled.div<TransportIconProps>`
    ${(props: TransportIconProps & StyledThemePropsType) => css`
        width: 76px;
        height: 154px;
        position: absolute;
        background: url(${props.theme.icons[props.icon]}) no-repeat center;
        background-size: contain;
        left: -42px;
        bottom: -129px;

        ${media.lg`
    width: 76px;
    height: 154px;
    right: -140px;
    bottom: -65px;
		left: auto;
  `};

        ${media.xl`
    width: 160px;
    height: 160px;
    right: -337px;
    bottom: -216px;
		left: auto;
  `};
    `}
`;

const ErrorTextLink = styled.a`
    ${ErrorPopupTextDesktopStyles};
    text-decoration: underline;
    cursor: pointer;
    line-height: 22px;
    ${media.lg`
	line-height: 30px;
	`};
`;

const NextLine = styled.div`
    display: block;
    width: 100%;
    margin-top: 20px;

    ${media.lg`
		display: none;
	`};
`;

const CustomSvg = styled.div<any>`
    display: inline-block;
    height: 110px;
    width: 62px;
    background: url(${(props: any) => props.icon}) no-repeat center;
    background-size: contain;
`;

const CustomSvgLogo = styled(CustomSvg)`
    height: 40px;
`;

const ContentHeader = styled.h2`
    ${H2MobileStyles};
    margin-top: 44px;
    position: relative;
    font-weight: normal;
    margin-bottom: 0;
    max-width: 100%;
    width: 720px;
    font-size: 20px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};

    ${(props) => media.xl`
    font-size: 20px;
    font-weight: bold;
    line-height: 140%;
    letter-spacing: normal;
    margin-top: 41px;
  `};
`;

const DialogPagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
`;

const MessageWrapper = styled.div`
    width: 280px;
    position: fixed;
    top: 35%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%);

    ${media.lg`
		position: relative;
		top: 0;
		left: 0;
		z-index: 0;
		transform: translate(0);
    width: 485px;
  `};
    ${media.lg`
	margin: 0 0 30px;
`};
    ${media.xl`
		width: 560px;
		margin: 30px 0;
  `};
`;

const HiddenWrapper = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.darkColor};
    z-index: 100;
    ${media.lg`
		display: none;
	`};
`;

const QuestionnaireFooterWrapper = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
`;

const EmptyContainer = styled.div`
    width: 100%;
    height: 100px;
    margin-bottom: 50px;
`;

export {
    HeaderContainer,
    HeaderText,
    HeaderTitle,
    HeaderDescription,
    ContentWrapper,
    DialogButtonsContainer,
    DialogButton,
    DialogButtonSpacing,
    TooltipWrapper,
    TransportIcon,
    ErrorTextLink,
    CustomSvg,
    CustomSvgLogo,
    ContentHeader,
    DialogPagesContainer,
    MessageWrapper,
    HiddenWrapper,
    ContentHeaderWrapper,
    NextLine,
    QuestionnaireFooterWrapper,
    EmptyContainer,
};
