import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { StyledThemePropsType } from '../../config/theme/default';

type FormsTitleProps = {
    spaceUnder?: boolean;
};

const Container = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    width: 100%;
`;

const FormsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: ${(props: StyledThemePropsType) =>
        props.theme.colors.neutralLight};
    padding: 20px;

    ${media.lg`
		width: 480px;
		padding: 40px;
	`};
`;

const FormsTextWrapper = styled.div`
    width: 100%;
    max-width: 320px;
    ${media.lg`
		max-width: 480px;
	`};
`;

const FormsTitle = styled.h1<FormsTitleProps>`
    font-style: normal;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
    margin: ${({ spaceUnder }: FormsTitleProps) =>
        spaceUnder ? '0 0 24px' : '0 0 36px'};
    ${media.lg`
		font-size: 28px;
		line-height: 36px;
	`};
`;

const FormsUserFullName = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.lg`
		flex-direction: row;
		justify-content: space-between;
	`};
`;

const TextFieldWrapper = styled.div`
    width: 100%;
    ${media.lg`
		width: 192px;
	`};
`;

const DropdownWrapper = styled.div`
    margin-bottom: 24px;
`;

export {
    Container,
    FormsWrapper,
    FormsTitle,
    FormsUserFullName,
    TextFieldWrapper,
    FormsTextWrapper,
    DropdownWrapper,
};
