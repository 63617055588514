import React, { FC, useContext } from 'react';
import { AddressContext } from '../../../store/context/subscription/address';
import { DriversContext } from '../../../store/context/subscription/drivers';
import { InsuranceContext } from '../../../store/context/subscription/insurance';
import { PaymentContext } from '../../../store/context/subscription/payment';
import { RedirectionContext } from '../../../store/context/subscription/redirection';
// import { PersonalTransportContext } from '../../../store/context/subscription/transport';
import { StepperMobile, StepperWrapper } from './elements';
import { StepProps, Steps } from './mock';
import Step from './Step/index';

interface StepperProps {
    steps: StepProps[];
    currentlySelected: number;
    // completed: boolean;
}

const Stepper: FC<StepperProps> = ({ steps, currentlySelected }) => {
    const { completed: insuranceStepCompleted } = useContext(InsuranceContext);
    // const { completed: transportStepCompleted } = useContext(
    //     PersonalTransportContext
    // );
    const { completed: driversStepCompleted } = useContext(DriversContext);
    const { completed: addressStepCompleted } = useContext(AddressContext);
    // const { completed: paymentStepCompleted } = useContext(PaymentContext);
    /**
     * summary context was deleted because we need to display completed step only after
     * redirection step complition (customer request)
     */
    const { completed: summaryStepCompleted } = useContext(RedirectionContext);
    const { completed: redirectionStepCompleted } = useContext(
        RedirectionContext
    );

    const stepsState = {
        [Steps.insurance]: insuranceStepCompleted,
        // [Steps.transport]: transportStepCompleted,
        [Steps.personelle]: driversStepCompleted,
        [Steps.address]: addressStepCompleted,
        // [Steps.payment]: paymentStepCompleted,
        [Steps.summary]: summaryStepCompleted,
        [Steps.redirection]: redirectionStepCompleted,
    };

    return (
        <StepperWrapper>
            <StepperMobile
                steps={steps.length}
                currentStep={currentlySelected}
            />
            {steps.map((step, index) => {
                let completedStep;
                if (step.key === Steps.personelle) {
                    completedStep =
                        stepsState[Steps.personelle] &&
                        stepsState[Steps.address];
                } else {
                    completedStep = stepsState[step.key];
                }

                return (
                    <Step
                        key={index}
                        text={step.value}
                        isFirst={index === 0}
                        isLast={index === steps.length - 1}
                        isSelected={index <= currentlySelected}
                        currentlySelected={index === currentlySelected}
                        completed={completedStep}
                    />
                );
            })}
        </StepperWrapper>
    );
};

export default Stepper;
