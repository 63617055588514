import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme/index';
import {
    DefaultFlexStyles,
    DefaultTransition,
} from '../../../shared/styles/index';
import {
    BottomSingleTextDesktopStyles,
    SummaryFormHeaderTextStyles,
} from '../../../shared/typography/index';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    margin: 40px auto 0;
    width: 100%;
    ${media.lg`
		width: 137px;
    margin: 40px 0 0;
    justify-content: flex-start;
  `};
`;

const ContentWrapper = styled.div`
    ${({ theme }: StyledThemePropsType) => css`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        background-color: ${theme.colors.container};
        border-radius: 12px;
        padding: 24px 10px;
        margin-top: 16px;

        ${media.lg`
    padding: 57px 67px 35px;
  `};

        ${media.xl`
    border-radius: 3px;
  `};
    `}
`;

const DirectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;

    ${media.xl`
    flex-direction: row;
    flex-wrap: wrap;
  `};
`;

const DirectionContainer = styled.div`
    ${DirectionStyles};
    justify-content: space-between;
`;

const TextFieldWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 14px 0;

    ${media.xl`
    width: 405px;
    margin: 0;
  `};
`;

const PaymentDirectionWrapper = styled.div`
    ${DirectionStyles};
    justify-content: flex-start;
`;

type PaymentSettingsWrapperProps = {
    first?: boolean;
    selected: boolean;
};

const PaymentSettingsWrapper = styled.div<PaymentSettingsWrapperProps>`
    ${DefaultFlexStyles};
    ${DefaultTransition};
    position: relative;
    min-width: 250px;
    max-width: 330px;
    margin: 0 0 22px 0;
    ${(props: PaymentSettingsWrapperProps) =>
        !props.first && media.xl`margin: 0 0 22px 32px;`};
    padding: 23px 16px;
    cursor: pointer;
    ${SummaryFormHeaderTextStyles};
    ${media.xl`
    font-size: 16px;
    line-height: 140%;
		font-weight: bold;
    letter-spacing: normal;
  `};
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
    border-radius: 6px;
    box-shadow: 0 2px 34px 0 rgba(29, 37, 45, 0.15);
    /* border: 0.5px solid
        ${({
        theme,
        selected,
    }: PaymentSettingsWrapperProps & StyledThemePropsType) =>
        selected ? theme.colors.borderLight : theme.colors.borderLight}; */
    background-color: ${({
        theme,
        selected,
    }: PaymentSettingsWrapperProps & StyledThemePropsType) =>
        selected ? theme.colors.white : theme.colors.white};
    font-weight: normal;
    opacity: ${({ selected }: PaymentSettingsWrapperProps) =>
        selected ? '1' : '0.6'};
    &:hover {
        opacity: 1;
    }
`;

const PaymentPrice = styled.span`
    display: contents;
    ${SummaryFormHeaderTextStyles};
    ${media.xs`
    font-size: 15px;
    letter-spacing: normal;
  `};
`;

const BottomDescription = styled.div`
    margin-top: 8px;
    ${BottomSingleTextDesktopStyles};
    font-size: 16px;
    line-height: 140%;
    ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
    ${media.xs`
    font-size: 13px;
  `};
`;

const ChoiceIconWrapper = styled.div`
    ${DefaultFlexStyles};
    position: absolute;
    right: 4px;
    top: 4px;
`;

const CalendarWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    width: 100%;
    margin: 40px 0 18px;
    pointer-events: none;
    ${media.xl`
    margin-bottom: 30px;
    width: 190px;
  `};
`;

const TooltipWrapper = styled.div`
    position: absolute;
    top: 22px;
    left: 60px;
`;

const ElementsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${media.lg`
	width: auto;
	`};
    ${media.xl`
		flex-direction: row;
	`};
`;

const MessageWrapper = styled.div`
    width: 280px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%);

    ${media.lg`
		position: relative;
		top: 0;
		left: 0;
		z-index: 0;
		transform: translate(0);
		width: 630px;
		
		display: flex;
		align-items: flex-end;
	`};

    ${media.xl`
		margin-left: 28px;
	`};
`;

const SuccessMessageWrapper = styled(MessageWrapper)`
    width: 280px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translate(0, 0);
    ${media.lg`
		width: 435px;
	`};
    ${media.xl`
		margin: 25px 0px 0px;
	`};
`;

const HiddenWrapper = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.darkText};
    z-index: 100;
    ${media.lg`
		display: none;
	`};
`;

const DropdownWrapper = styled.div`
    width: 100%;
    z-index: 5;
    ${media.lg`
		margin-top: 30px;
	`};
`;

export {
    ButtonContainer,
    ContentWrapper,
    BottomDescription,
    TextFieldWrapper,
    DirectionContainer,
    PaymentSettingsWrapper,
    PaymentDirectionWrapper,
    PaymentPrice,
    ChoiceIconWrapper,
    CalendarWrapper,
    TooltipWrapper,
    MessageWrapper,
    SuccessMessageWrapper,
    ElementsWrapper,
    HiddenWrapper,
    DropdownWrapper,
};
