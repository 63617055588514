export enum Steps {
  insurance = "insurance",
  personelle = "personelle",
  bancaires = "bancaires",
  address = "address",
  payment = "payment",
  summary = "summary",
  redirection = "redirection",
}

export type StepProps = {
  key: Steps;
  value: string;
};

export type StepsConfig = {
  currentlySelected: number;
  steps: StepProps[];
};

export const stepperMock: StepsConfig = {
  currentlySelected: 1,
  steps: [
    {
      key: Steps.insurance,
      value: "Votre offre",
    },
    {
      key: Steps.personelle,
      value: "Vos informations personnelles",
    },
    // {
    //   key: Steps.address,
    //   value: "Address",
    // },
    {
      key: Steps.bancaires,
      value: "Vos coordonnées bancaires",
    },
    {
      key: Steps.summary,
      value: "Paiement",
    },
    {
      key: Steps.redirection,
      value: "Signature",
    },
  ],
};


