import React from 'react';
import { Link } from 'react-router-dom';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme';

const RulesWrapper = styled.div`
    width: auto;
    padding: 95px 45px 0;
    ${media.xl`
		max-width: 1160px;
  `};
`;

const RulesSection = styled.div`
    margin: 30px 0px;
`;

const RuleTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`;

type RulesLinkProps = {
    name: string;
};

const RulesLink = styled.a<RulesLinkProps>``;

const RulesExternalLink = styled(Link)`
    text-decoration: underline;
    color: inherit;
    cursor: pointer;
`;

const DownloadLink = styled.a`
    text-decoration: none;
    color: inherit;
    cursor: pointer;
`;

const RuleText = styled.p`
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
    font-size: 12px;
    line-height: 1.38;
    letter-spacing: 0.2px;
    margin: 0;
    margin-bottom: 20px;
`;

const ListItem = styled.li`
    margin: 5px 0 5px 20px;
`;

const RuleNumber = styled.span`
    font-size: 10px;
`;

const RulesContainer = () => {
    return (
        <RulesLink name="rules">
            <RulesWrapper>
                <RulesSection>
                    <RuleTitle>Mentions légales et commerciales</RuleTitle>
                    <RuleText>
                        Ce contrat es commercialisé et assuré par MMA IARD
                        Assurances Mutuelles RCS Le Mans N° 775 652 126 société
                        d’assurance mutuelle à cotisations fixes et MMA IARD,
                        S.A. au capital social de 537.052.368 €, RCS Le Mans N°
                        440 048 882 ayant leurs sièges sociaux au 14 boulevard
                        Marie et Alexandre Oyon -72030 Le Mans cedex 9
                        (conjointement dénommées ci-après «MMA» ou
                        l’«Assureur»). Sociétés appartenant au groupe Covéa.
                        Entreprise régie par le Code des assurances et sont
                        soumises au contrôle de l’Autorité de Contrôle
                        Prudentiel et de Résolution située 4 place de Budapest,
                        CS 92459, 75436 Paris Cedex 09.
                    </RuleText>
                    <RuleText>
                        Vos données personnelles sont traitées par votre
                        Assureur, par le Groupe Covéa, auquel il appartient,
                        responsables de traitement. Vous trouverez les
                        coordonnées de votre Assureur sur les documents
                        contractuels et précontractuels qui vous ont été remis
                        ou mis à votre disposition. Le Groupe Covéa est sis
                        86-90 rue St Lazare 75009 Paris. Vos données
                        personnelles sont traitées afin de conclure, gérer et
                        exécuter les garanties de votre contrat d’assurance;
                        réaliser des opérations de prospection commerciale;
                        permettre l’exercice des recours et la gestion des
                        réclamations; conduire des actions de recherche et de
                        développement dans le cadre des finalités précitées;
                        mener des actions de prévention; élaborer des
                        statistiques et études actuarielles; lutter contre la
                        fraude à l’assurance; mener des actions de lutte contre
                        le blanchiment et le financement du terrorisme; exécuter
                        ses obligations légales, réglementaires et
                        administratives en vigueur. Vous disposez d’un droit
                        d’accès, de rectification, d’opposition, d’effacement,
                        de limitation et de demander la portabilité de certaines
                        données personnelles vous concernant, que vous pouvez
                        exercer auprès du Délégué à la Protection des données à
                        l’adresse Covéa 14, boulevard Marie et Alexandre OYON
                        72030 Le Mans Cedex ou par email à l’adresse&nbsp;
                        <RulesExternalLink
                            to="protectiondesdonnees@mma.fr"
                            target="_blank"
                        >
                            protectiondesdonnees@mma.fr
                        </RulesExternalLink>
                        .
                    </RuleText>
                    <RuleText>
                        Les informations complémentaires sur vos droits et le
                        traitement de vos données personnelles sont disponibles
                        sur le site de votre Assureur sous l’onglet «Vie privée»
                        ainsi que dans les Conditions Générales ou Notice
                        d’information qui vous ont été remises ou mises à votre
                        disposition lors de votre souscription.
                    </RuleText>
                    <RuleText>
                        Vous disposez d'un délai de quatorze jours pour exercer
                        votre droit de rétractation d'un contrat conclu à
                        distance, à la suite d'un démarchage téléphonique ou
                        hors établissement, sans avoir à motiver sa décision ni
                        à supporter d'autres coûts que ceux prévus aux articles
                    </RuleText>
                    {/* <RuleText>
                        <RuleNumber>(1)</RuleNumber> Dans les conditions,
                        limites, et exclusions des Dispositions Générales
                        relatives à la Garantie Responsabilité Civile EDPM Pour
                        tous, disponibles{' '}
                        <DownloadLink
                            download
                            href="DG-EDPM-EQEDM0841C-042021.pdf"
                        >
                            ici.
                        </DownloadLink>{' '}
                        Offre réservée aux risques situés en France
                        métropolitaine (y compris Corse). <br /> <br />
                        Avant d'adhérer, nous vous recommandons de : <br />{' '}
                        <br />
                        <ListItem>
                            Lire attentivement le document d'information sur le
                            produit d'assurance et les Dispositions Générales
                            relatives au contrat d'assurance qui précise
                            l'ensemble des garanties, les conditions, limites et
                            exclusions applicables
                        </ListItem>
                        <ListItem>
                            Vérifier que la garantie correspond bien à votre
                            besoin et que vous n'êtes pas déjà couvert par
                            ailleurs pour les mêmes risques.
                        </ListItem>
                        <br />
                        S'agissant d'une assurance obligatoire, la souscription
                        de ce contrat Véhicule Terrestre à Moteur ne vous
                        permettra pas de bénéficier d'un délai de renonciation.
                        <br /> <br />
                        Selon conditions et limites prévues dans les
                        Dispositions Générales du Contrat « Responsabilité
                        Civile EDPM » garanti par L'Équité, SA au capital de 26
                        469 320 euros. Entreprise régie par le Code des
                        assurances - B 572 084 697 RCS Paris. Siège Social : 2
                        rue Pillet-Will 75009 Paris. Société appartenant au
                        Groupe Generali immatriculé sur le registre italien des
                        groupes d'assurances sous le numéro 026. <br />
                        <br />
                        L’autorité chargée du contrôle des entreprises
                        d’assurances qui accordent les garanties prévues par le
                        présent contrat est l’Autorité de Contrôle Prudentiel et
                        de Résolution (ACPR) - 4 place de Budapest - CS 92459 -
                        75436 Paris Cedex 09.
                        <br /> <br />
                        <b>
                            La distribution du Contrat d'assurance est confiée à
                            AssurLink
                        </b>{' '}
                        Société de courtage en assurance, SAS au capital de 10
                        000 €, Siège social : 38 rue F.Villon, 91450 Soisy sur
                        Seine RCS Evry 852449131 - Orias n°19005949,{' '}
                        <b>en partenariat avec la société ATM Assurances</b>,
                        qui en assure la gestion : ATM Assurances, SARL au
                        capital de 200 000 €, société de courtage en assurance.
                        Adresse postale et centre de gestion : CS 70440 - 49004
                        ANGERS Cedex 01, immatriculée au Registre du Commerce et
                        des Sociétés d'AIX EN PROVENCE sous le numéro 441 989
                        795 et au registre des intermédiaires en assurances sous
                        le numéro 07 026 312 (www.orias.fr) dont le siège social
                        est sis Côté village - Bâtiment numéro 3 - Avenue Adam
                        Puskanric - 13590 MEYREUIL. <br />
                        <br />
                        <b>En cas de réclamation</b>, vous pouvez vous adresser
                        au service qualité d'ATM Assurances par courrier à
                        Centre de gestion ATM - Service Qualité - CS 70440 -
                        49004 ANGERS Cedex 01 ou par mail à
                        servicequalite@atm-assur.com. Votre réclamation sera
                        traitée dans un délai de 10 jours ouvrables à compter de
                        sa réception. Si ce délai n'est pas suffisant pour le
                        traitement des réclamations, ATM Assurances accusera
                        réception de celle-ci durant ce délai et une réponse
                        vous sera apportée dans un délai de 2 mois. <br />
                        <br />
                        Si un accord n'est pas trouvé, vous pouvez adresser une
                        réclamation écrite à l'assureur avec le motif du litige
                        et les références du dossier à : L'Equité - Cellule
                        qualité - 75433 PARIS CEDEX 09. <br />
                        <br /> La procédure ci-dessus ne s'applique pas si une
                        juridiction a été saisie du litige, que ce soit par vous
                        ou par l'assureur. <br />
                        <br />
                        Si le désaccord persiste, vous pouvez saisir le
                        Médiateur de l'assurance, en écrivant à La Médiation de
                        l'Assurance - TSA 50110 75441 <br />
                        <br />
                        Paris Cedex 09. Nous vous précisons cependant que le
                        Médiateur peut être saisi uniquement après que le
                        Service Réclamations ait été saisi de votre demande et y
                        ait apporté une réponse. <br />
                        <br />
                        Le médiateur ne peut être saisi si une procédure
                        judiciaire est en cours.
                        <br />
                        <br />
                        Si vous souscrivez le contrat en qualité de
                        consommateur, vous pouvez aussi recourir à la plateforme
                        de Règlement en Ligne des Litiges (RLL) de la Commission
                        Européenne en utilisant le lien suivant :
                        <RulesExternalLink
                            to="http://ec.europa.eu/consumers/odr/"
                            target="_blank"
                        >
                            http://ec.europa.eu/consumers/odr/
                        </RulesExternalLink>
                        .
                        <br />
                        <br />
                        <b>
                            Informations relative à la protection de vos données
                            personnelles :{' '}
                        </b>{' '}
                        <br />
                        En qualité de responsable de traitement, nous allons
                        collecter vos données à caractère personnel afin de
                        satisfaire à votre demande et de permettre la
                        réalisation de mesures précontractuelles, de
                        souscription et d’exécution ultérieur de votre contrat »{' '}
                        <br />
                        Pour plus d’information sur le traitement de vos données
                        à caractère personnel, nous vous invitons à consulter
                        l’article 20 « Protection des données personnelles » qui
                        a été mis à votre disposition dans les Dispositions
                        Générales.
                    </RuleText> */}
                </RulesSection>
            </RulesWrapper>
        </RulesLink>
    );
};

export default RulesContainer;
