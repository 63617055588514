import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme/index';

export const Container = styled.div`
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.container};
    padding: 24px;
    position: relative;
    margin-bottom: 8px;
    cursor: pointer;

    ${media.lg`
    padding-right: 128px;
  `};
`;

export const Title = styled.p`
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    font-stretch: normal;
    margin: 0;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    word-break: break-all;
`;

export const Description = styled.p`
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    font-stretch: normal;
    margin: 16px 0 0;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    word-break: break-all;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 16px;
    right: 14px;
    padding: 10px;
    cursor: pointer;
`;

export const FaqContainer = styled.div`
    /* margin-top: 40px; */
`;
