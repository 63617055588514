import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme/index';
import { DefaultFlexStyles } from '../../../shared/styles/index';
import { LabelStyles } from '../../../shared/typography/index';

const AddressPersonalInfoContent = styled.div`
    width: 100%;
    background: ${(props: StyledThemePropsType) =>
        props.theme.colors.container};
    margin-bottom: 20px;
`;

const AddressPersonalInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${media.xl`
    flex-direction: row;
    align-items: flex-start;
  `};
`;

const FormGroupStyles = css`
    /* background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.disabled}; */
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 25px 15px 0;
    width: 100%;
`;

type ContainerProps = {
    specialHeight?: boolean;
};

const AddressPersonalInfoLeftGroup = styled.div<ContainerProps>`
    ${FormGroupStyles}

    ${media.xl`
			padding: 56px 32px 56px 65px;
			border-left: 3px;
			height: min-content;
  `};
`;

const AddressPersonalInfoRightGroup = styled.div<ContainerProps>`
    ${FormGroupStyles};
    ${media.xl`
			padding: 56px 65px 20px 32px;
			border-right: 3px;
			height: min-content;
		`};
`;

const SmallInputsGroup = styled.div`
    ${DefaultFlexStyles};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    ${media.xl`
    flex-direction: row;
  `};
`;

const CodePostalWrapper = styled.div`
    margin-right: 0;
    margin-bottom: 28px;
    width: 100%;

    ${media.xl`
    width: 130px;
    margin-bottom: 0; 
    margin-right: 26px;
  `};
`;

const PostalCodeErrorMsg = styled.div<StyledThemePropsType>`
    color: ${(props: StyledThemePropsType) => props.theme.colors.error};
`;

const VilleWrapper = styled.div`
    width: 100%;

    ${media.xl`
    width: 247px;
  `};
`;

const SubscriptionWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: baseline;
    margin: 0 0 15px;
    cursor: pointer;
`;

type SubscriptionTextProps = {
    fontSize?: string;
};

const SubscriptionText = styled.div<SubscriptionTextProps>`
    ${LabelStyles};
    /* font-size: ${({ fontSize }: SubscriptionTextProps) =>
        fontSize ? fontSize : '12px'}; */
    font-size: 20px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
    margin-left: 18px;
    opacity: 0.8;
    cursor: pointer;
    flex: 20;

    ${media.xl`
    flex: auto;
  `};
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;

    ${media.lg`
    width: 215px;
    align-self: baseline;
  `};
`;

const CustomSpan = styled.span`
    position: relative;
    display: block;
    margin-top: 3px;
    width: 13px;
    height: 13px;
    border-radius: 2px;
    border: solid 1px
        ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};

    :hover {
        cursor: pointer;
    }
`;

const MessageWrapper = styled.div`
    position: relative;
    margin: 0 auto 30px;
    max-width: 450px;
    ${media.xl`
		margin: -20px 0 30px 65px;
  `};
`;

export {
    AddressPersonalInfoContent,
    AddressPersonalInfoLeftGroup,
    AddressPersonalInfoRightGroup,
    SubscriptionWrapper,
    CustomSpan,
    SubscriptionText,
    SmallInputsGroup,
    CodePostalWrapper,
    VilleWrapper,
    ButtonWrapper,
    PostalCodeErrorMsg,
    MessageWrapper,
    AddressPersonalInfoWrapper,
};
