import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { StyledThemePropsType } from '../../../../config/theme/index';
import { Button } from '../../../../shared/elements/index';
import {
    DefaultFlexStyles,
    DefaultTransition,
} from '../../../../shared/styles/index';
import {
    H2DesktopStyles,
    H2MobileStyles,
    InputValueCaptionMobileStyles,
    UnderlinedLinkTextStyles,
} from '../../../../shared/typography/index';
type TariffCardContainerProps = {
    active?: boolean;
    disabledEvents?: boolean;
};

const TariffCardContainer = styled.div<TariffCardContainerProps>`
    ${DefaultTransition};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 290px;
    border-radius: 12px;
    margin: 0;
    position: relative;
    cursor: pointer;
    opacity: ${(props: TariffCardContainerProps) => (props.active ? 1 : 0.7)};
    &:hover {
        opacity: 1;
    }

    ${(props: StyledThemePropsType) => css`
        ${media.lg`
			width: 500px;
			padding: 34px 42px 21px; 
			/* margin: 0 auto 11px; */
		`};
    `};

    ${(props: StyledThemePropsType) => css`
        ${media.xl`
			border: solid 0.5px ${props.theme.colors.borderLight};
			background-color: ${props.theme.colors.white};
			width: 405px;
			padding: 34px 42px 21px; 
			/* margin: 0 auto 11px; */
		`};
    `};

    ${(props: TariffCardContainerProps & StyledThemePropsType) => css`
        ${media.xl`
      box-shadow: 0 2px 34px 0 ${props.theme.colors.shadowColor};
      transform: ${props.active ? 'scale(1)' : 'scale(.9)'};
      /* width: ${props.active ? '450px' : '395px'};
      height: ${props.active ? '580px' : '545px'};
      padding: ${props.active ? '23px 21px 21px' : '20px 22px 19px'};
      margin: ${props.active ? '0 0 11px' : '27px 0 11px'}; */
    `};
    `};
`;

const MainContainer = styled.div`
    border: solid 0.5px
        ${(props: StyledThemePropsType) => props.theme.colors.borderLight};
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    padding: 20px 23px;
    border-radius: 12px;
    width: 100%;
    ${media.lg`
		box-shadow: 0 2px 34px 0 rgba(29, 37, 45, 0.15);
	`};
    ${media.xl`
		border: none;
		background-color: none;
		padding: 0;
		box-shadow: none;
	`};
`;

const CardTitle = styled.div`
    ${H2DesktopStyles};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
`;

const TariffName = styled.div`
    ${H2DesktopStyles};
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
`;

const TariffDescription = styled.div`
    ${H2MobileStyles};
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
    font-size: 20px;
    text-align: center;
    opacity: 0.7;
    max-width: 280px;
    font-weight: normal;

    ${media.lg`
    max-width: 305px;
  `};
`;

const SubmitButton = styled(Button)<TariffCardContainerProps>`
    width: 100%;
    margin-top: 0;
    height: auto;
    font-size: 14px;
    line-height: 1.38;
    letter-spacing: 1px;
    box-shadow: 2px 4px 8px 0 rgba(47, 61, 64, 0.3);
    pointer-events: ${({ disabledEvents }: TariffCardContainerProps) =>
        disabledEvents ? 'all' : 'none'};
    ${(props: TariffCardContainerProps) => css`
        /* ${media.lg` 
			height: ${props.active ? '62px' : '54px'};
			font-size: ${props.active ? '16px' : '14px'};
		`}; */
    `};
`;

const AdvantagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 205px; */
    padding: 14px 9px 11px;
    margin: 13px 0 20px;
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.container};

    ${media.lg`
		padding: 23px 14px 11px;
		/* height: 169px; */
		margin: 21px 0 25px;
	`};
`;

export const AdvantagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const SingleAdvantageWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 18px;
    ${media.lg`
		margin-bottom: 0px;
    margin-bottom: 18px;
	`};
`;

const AdvantageIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 11px;
    transform: scale(0.7);
    margin-top: 3px;
    ${media.lg`
		transform: scale(1);
		margin-top: 5px;
	`};
`;

const AdvantageCustomLinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: 24px;
`;

type AdvantageTextProps = {
    showIcon: boolean;
};

const AdvantageText = styled.div<AdvantageTextProps & TariffCardContainerProps>`
    ${InputValueCaptionMobileStyles};
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
    opacity: ${(props: AdvantageTextProps) => (props.showIcon ? 1 : 0.3)};
    line-height: 15px;
    margin-left: 5px;
    font-size: 12px;

    ${(props: TariffCardContainerProps) => css`
        ${media.lg`
			/* font-size: ${props.active ? '14px' : '12.3px'};
			line-height: ${props.active ? '22px' : '15.8px'}; */
			margin-left: 14px;
            font-size: 16px;
            line-height: 140%;
		`};
    `};
`;

const ChekIconWrapper = styled.div`
    display: none;

    ${media.xl`
		${DefaultFlexStyles};
		position: absolute;
		right: 5px;
		top: 5px;
	`};
`;

const TabsMenu = styled.div`
    ${DefaultFlexStyles};
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 2px 34px 0 rgba(29, 37, 45, 0.15);
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    margin-top: 30px;
    text-align: center;

    ${media.xl`
    display: none;
  `};
`;

type CheckIconProps = StyledThemePropsType & {
    active: boolean;
};

const CheckIcon = styled.div<CheckIconProps>`
    ${({ active, theme }: CheckIconProps) => css`
        width: 30px;
        height: 30px;
        background: url(${active
                ? theme.icons.isuranceCheck
                : theme.icons.isuranceCheckTransparent})
            center no-repeat;
    `};
`;

const SpanLink = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${UnderlinedLinkTextStyles};
    font-size: 12px;
`;

const SubscriptionText = styled.div<TariffCardContainerProps>`
    width: 100%;
    height: 22px;
    opacity: 0.55;
    line-height: 1.57;
    font-size: 12px;
    text-align: center;
    color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
    margin-top: 13px;

    ${(props: TariffCardContainerProps) => css`
        ${media.lg`
			/* font-size: ${props.active ? '14px' : '12.3px'}; */
            font-size: 14px;
			width: 410px;
		`};
    `};
`;
type PriceContainerProps = {
    isVoucher: boolean;
};
const PriceContainer = styled.div<PriceContainerProps>`
    display: flex;
    flex-direction: ${({ isVoucher }: PriceContainerProps) =>
        isVoucher ? 'column' : 'row'};
    align-items: center;
    justify-content: center;
    width: 100%;
    ${(props: PriceContainerProps) => css`
        ${media.xl`
		flex-direction: row;
		justify-content: ${props.isVoucher ? 'flex-end' : 'center'};
		margin-bottom: 30px;
  `};
    `};
`;

const CouponDescription = styled.div<TariffCardContainerProps>`
    width: 221px;
    ${DefaultFlexStyles};
    width: 210px;
    flex-direction: column;
    height: 66px;
    border-radius: 3px;
    /* background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.container}; */
    background-color: red;

    ${(props: TariffCardContainerProps & StyledThemePropsType) => css`
        ${media.lg`
			width: ${props.active ? '210px' : '180px'};
			margin-left: 10px;
			padding: 10px;
		`};
    `};
`;
const CouponText = styled.p<TariffCardContainerProps>`
    font-size: ${(props: TariffCardContainerProps) =>
        props.active ? '12px' : '10px'};
    font-weight: bold;
    line-height: ${(props: TariffCardContainerProps) =>
        props.active ? '20px' : '17.6px'};
    color: ${(props: StyledThemePropsType) =>
        props.theme.colors.highlightedText};
    margin: 0;
    text-align: center;
    ${media.lg`
	text-align: left;
	`};
`;
const LinkWrapper = styled.div<TariffCardContainerProps>`
    display: flex;
    justify-content: flex-start;
    /* margin-bottom: ${(props: TariffCardContainerProps) =>
        props.active ? '38px' : '22px'}; */
    margin-bottom: 24px;
    width: 100%;
`;
const FirstLinkWrapper = styled(LinkWrapper)`
    margin-bottom: 5px;
`;

const FooterTariffCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    margin-top: 30px;
    padding-bottom: 40px;
    ${media.lg`
	width: 351px;
	`};
    ${media.xl`
	width: 100%;
	margin-top: 0;
  padding-bottom: 0;
	`};
`;

const CashBlock = styled.div`
    text-align: center;
    margin-top: 6px;
`;

const PlafondLineMain = styled.p`
    margin: 0;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
`;

const PlafondLineSub = styled.p`
    margin: 0;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
`;

const PlafondHighlighted = styled.span`
    color: ${(props: StyledThemePropsType) => props.theme.colors.button};
`;

const PlafondEvenMoreHighlighted = styled.span`
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: ${(props: StyledThemePropsType) => props.theme.colors.button};
`;

const Uppercased = styled.span`
    text-transform: uppercase;
`;

export {
    TariffCardContainer,
    CardTitle,
    TariffName,
    TariffDescription,
    SubmitButton,
    AdvantagesContainer,
    SingleAdvantageWrapper,
    AdvantageIconWrapper,
    AdvantageText,
    ChekIconWrapper,
    TabsMenu,
    CheckIcon,
    AdvantageCustomLinkWrapper,
    SpanLink,
    SubscriptionText,
    PriceContainer,
    CouponDescription,
    CouponText,
    LinkWrapper,
    FooterTariffCardWrapper,
    MainContainer,
    FirstLinkWrapper,
    CashBlock,
    PlafondLineMain,
    PlafondLineSub,
    PlafondHighlighted,
    PlafondEvenMoreHighlighted,
    Uppercased,
};
