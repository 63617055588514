import contact from "./contact.svg";
import coupon from "./coupon.svg";
import habitation from "./habitation.svg";
import logo from "./logo.svg";
import mobile from "./mobile.svg";
import error from "./statuses/error.svg";
import success from "./statuses/success.svg";
import warning from "./statuses/warning.svg";
import gyropode from "./vehicles/gyropode.svg";
import gyroroue from "./vehicles/gyroroue.svg";
import hoverboard from "./vehicles/hoverboard.svg";
import onewheel from "./vehicles/onewheel.svg";
import skateboard from "./vehicles/skateboard.svg";
import trottinette from "./vehicles/trottinette.svg";
import draisienne from "./vehicles/electricbalancebike.svg";
import voiture from "./vehicles/voiture.svg";
import add from "./actions/add.svg";
import voucher from "./voucher-icon.svg";
import targetBlank from "./actions/targetBlank.svg";
import check from "./actions/check.svg";
import insurance from "./actions/insurance.svg";
import locked from "./actions/locked.svg";
import tooltip from "./actions/tooltip.svg";
import close from "./actions/close.svg";
import isuranceCheck from './statuses/Isurance_check_green.svg';
import isuranceCheckTransparent from './statuses/Isurance_check_transparent.svg';
import facebook from "./social/facebook.svg";
import linkedIn from "./social/linkedIn.svg";
import twitter from "./social/twitter.svg";
import burger from "./actions/burger_menu.svg";
import phone from "./actions/phone.svg";
import mail from "./actions/mail.svg";
import chat from "./actions/chat.svg";
import habitationInterest from "./interests/habitation.svg";
import mobileInterest from "./interests/mobile.svg";
import voitureInterest from "./interests/voiture.svg";
import leftArrow from "./actions/leftArrow.svg";
import rightArrow from "./actions/rightArrow.svg";
import inputCheck from "./check.svg";
import calendar from "./calendar_icon.svg";
import filledLocker from "./actions/filledLocker.svg";
import lequite from "./lequite.svg";
export type IconsType = {
  [key: string]: any;
};

const Icons: IconsType = {
  logo,
  contact,
  coupon,
  mobile,
  habitation,
  error,
  warning,
  success,
  gyropode,
  gyroroue,
  hoverboard,
  onewheel,
  skateboard,
	trottinette,
	draisienne,
  voiture,
  add,
	voucher,
  targetBlank,
  check,
  insurance,
  locked,
  tooltip,
  close,
  isuranceCheck,
  isuranceCheckTransparent,
  facebook,
  linkedIn,
  twitter,
  burger,
  phone,
  mail,
  chat,
  habitationInterest,
  mobileInterest,
  voitureInterest,
  leftArrow,
	rightArrow,
	inputCheck,
	calendar,
	filledLocker,
	lequite,
};

export default Icons;
