import React, { useContext } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import GoogleAnalytics from '../GoogleAnalytics';
import Contact from '../pages/contact/index';
import ErrorPage from '../pages/error/index';
import Finish from '../pages/finish/index';
import Home from '../pages/home/index';
import Questionnaire from '../pages/questionnaire/index';
import Subscription from '../pages/subscription';
import VoucherActivation from '../pages/voucher';
import { QuestionnaireContext } from '../store/context/questionnaire/questionnaire';
import { Helmet } from 'react-helmet';
import Icons from '../shared/icons/index';
import Insurance from '../pages/insurance';
import favicon from '../shared/icons/defaultTheme/covea-favicon.png';
import EscapePro from '../pages/escapePro';
import Login from '../pages/login';
import Form from '../pages/form';
import SuccessPage from '../pages/succes';
// import Form from '../pages/form';
// import SuccessPage from '../pages/succes';
// import favicon from '../shared/icons/defaultTheme/covea-favicon.svg';
// import favicon from '../shared/icons/defaultTheme/favicon.png';

const Root = () => {
    const {
        firstStepCompleted,
        secondStepCompleted,
        thirdStepCompleted,
    } = useContext(QuestionnaireContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const questionnaireCompleted =
        firstStepCompleted && secondStepCompleted && thirdStepCompleted;
    const contentName: string = 'contentName';
    // process.env.REACT_APP_THEME_KEY[0].toUpperCase() +
    // process.env.REACT_APP_THEME_KEY.slice(1).toLowerCase();
    const favIconName: string = process.env.REACT_APP_THEME_KEY
        ? process.env.REACT_APP_THEME_KEY.toLowerCase()
        : 'favicon';

    return (
        <>
            <Helmet>
                <meta name="description" content={contentName} />
                <title>{contentName}</title>
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={Icons[`${favIconName}`]}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={Icons[`${favIconName}`]}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={Icons[`${favIconName}`]}
                />
                <link rel="mask-icon" href={Icons[`${favIconName}`]} />
            </Helmet>
            <Router>
                <GoogleAnalytics>
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/insurance" exact component={Insurance} />
                        <Route path="/espace-pro" exact component={EscapePro} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/error" component={ErrorPage} />
                        <Route path="/finish" component={Finish} />
                        {/* <Route path="/voucher" component={VoucherActivation} /> */}

                        <Route
                            path="/questionnaire"
                            component={Questionnaire}
                        />
                        <Route path="/subscription" component={Subscription} />

                        <Route path="/login" component={Login} />
                        <Route path="/voucher" component={Form} />
                        <Route path="/success" component={SuccessPage} />

                        <Redirect to="/error" />
                    </Switch>
                </GoogleAnalytics>
            </Router>
        </>
    );
};

export default Root;
