import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import * as yup from 'yup';
import { Button } from '../../shared/elements';
import Message from '../../shared/elements/Message';
import {
    FormsWrapper,
    FormsTitle,
    FormsUserFullName,
    TextFieldWrapper,
    FormsTextWrapper,
    Container,
    DropdownWrapper,
} from './elements';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
    VoucherMutationResult,
    VoucherMutationVariables,
} from '../../api/interfaces/mutation';
import {
    AdditionalVoucherFieldsType,
    ErrorMessage,
    VoucherFieldsType,
    VoucherFieldsTypeErrors,
} from '../../api/interfaces/voucher';
import EXTERNAL_REQUEST_VOUCHER from '../../api/mutations/externalRequestVoucher';
import { ExecutionResult } from 'graphql';
import { AccessContext } from '../../store';
import { VoucherSchema } from '../../shared/validation/voucher';
import GET_PRODUCTS from '../../api/queries/getProducts';
import Dropdown, { DropdownOption } from '../../shared/elements/Dropdown';
import { headerRoutes } from '../../routing/headerRoutes';
import TextFieldVoucher from '../../shared/elements/TextFieldVoucher';
import HeaderVoucher from '../../shared/elements/HeaderVoucher';

const initialVoucherErrors: VoucherFieldsTypeErrors = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    productBrand: '',
    productType: '',
    productSerial: '',
    distributorName: '',
    storeName: '',
    vendorName: '',
};

const Form = () => {
    const { saveAccessInfo } = useContext(AccessContext);
    const { push } = useHistory();
    const [voucherMutation] = useMutation<
        VoucherMutationResult,
        VoucherMutationVariables
    >(EXTERNAL_REQUEST_VOUCHER);
    const initialVoucher: VoucherFieldsType = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        productBrand: '',
        productType: 'TROTTINETTE',
        productSerial: '',
        distributorName: '',
        storeName: '',
        vendorName: '',
    };
    const additionalVoucherFields: AdditionalVoucherFieldsType = {
        orderId: 'T1',
        discountId: 3,
    };
    const [voucher, setVoucher] = useState<VoucherFieldsType>(initialVoucher);
    const [voucherErrors, setVoucherErrors] = useState<VoucherFieldsTypeErrors>(
        initialVoucherErrors
    );

    const [products, setProducts] = useState(null);

    const { data, loading } = useQuery<any>(GET_PRODUCTS, {
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (!data) return;
        const productsData = data.products.map((product) => {
            const { name } = product;
            return { value: name, label: name };
        });
        setProducts(productsData);
    }, [data]);

    const typeDropdownOnChange = useCallback((option: DropdownOption) => {
        // const label =
        //   option.label === "trottinette électrique"
        //     ? (option.label = "trottinette")
        //     : option.label === "e-skateboard"
        //     ? (option.label = "skateboard")
        //     : option.label;

        return setVoucher((prev) => ({
            ...prev,
            productType: option.value,
        }));
    }, []);

    const inputsOnChange = useCallback(
        (field) => (event: ChangeEvent<HTMLInputElement>) => {
            let value: string = event.target.value;
            if (field === 'mobile') value = value.substring(0, 9);
            return setVoucher((prev) => ({
                ...prev,
                [field]: value,
            }));
        },
        [setVoucher]
    );

    const [checkError, setCheckError] = useState<ErrorMessage>({
        display: false,
        message:
            'Oups...regardez si tous les champs obligatoires sont correctement renseignés.',
    });
    const hideCheckErrorMessage = useCallback(
        () => setCheckError({ ...checkError, display: false }),
        [checkError]
    );
    const showCheckErrorComponent = checkError.display && !!checkError.message;

    const handleCreateVoucher = useCallback(async () => {
        const validationPassed = await VoucherSchema.validate(
            { ...voucher },
            { abortEarly: false }
        ).catch((e) => {
            setCheckError({ ...checkError, display: true });
            const errors: VoucherFieldsTypeErrors = { ...initialVoucherErrors };
            e.inner.map(
                (err: yup.ValidationError) => (errors[err.path] = err.message)
            );
            return setVoucherErrors({ ...errors });
        });

        if (validationPassed) {
            setCheckError({ ...checkError, display: false });
            setVoucherErrors(initialVoucherErrors);
            await voucherMutation({
                variables: {
                    model: { ...voucher, ...additionalVoucherFields },
                },
            }).then(
                async ({ data }: ExecutionResult<VoucherMutationResult>) => {
                    if (data) {
                        saveAccessInfo({ accessFlag: true });
                        return push('/success');
                    }
                }
            );
        }
    }, [
        setCheckError,
        checkError,
        voucher,
        push,
        voucherMutation,
        additionalVoucherFields,
        saveAccessInfo,
    ]);

    const enableButton = useMemo(
        () =>
            !!voucher.firstName &&
            !!voucher.lastName &&
            !!voucher.email &&
            !!voucher.mobile &&
            !!voucher.productSerial &&
            !!voucher.distributorName &&
            !!voucher.storeName &&
            !!voucher.vendorName,
        [voucher]
    );

    return (
        <Container>
            <HeaderVoucher />
            <FormsWrapper>
                <FormsTextWrapper>
                    <FormsTitle spaceUnder={showCheckErrorComponent}>
                        Formulaire distributeur
                    </FormsTitle>
                    {showCheckErrorComponent ? (
                        <>
                            <Message
                                close={hideCheckErrorMessage}
                                icon="error"
                                textColor="actionError"
                                iconColor="actionError"
                                backgroundColor="errorBackground"
                                message={checkError.message}
                            />
                        </>
                    ) : null}
                    <FormsUserFullName>
                        <TextFieldWrapper>
                            <TextFieldVoucher
                                onChange={inputsOnChange('firstName')}
                                label="PRENOM CLIENT"
                                error={!!voucherErrors.firstName}
                            />
                        </TextFieldWrapper>
                        <TextFieldWrapper>
                            <TextFieldVoucher
                                onChange={inputsOnChange('lastName')}
                                label="NOM CLIENT"
                                error={!!voucherErrors.lastName}
                            />
                        </TextFieldWrapper>
                    </FormsUserFullName>
                    <TextFieldVoucher
                        onChange={inputsOnChange('email')}
                        label="EMAIL"
                        error={!!voucherErrors.email}
                    />
                    <TextFieldVoucher
                        onChange={inputsOnChange('mobile')}
                        label="TELEPHONE MOBILE"
                        error={!!voucherErrors.mobile}
                        value={voucher.mobile}
                        type="number"
                        prefield
                    />
                    {/* <TextField
            onChange={inputsOnChange("productType")}
            label="TYPE DE VEHICULE"
            error={!!voucherErrors.productType}
            disabled
            value={voucher.productType}
          /> */}
                    <DropdownWrapper>
                        <Dropdown
                            isDisabled={loading}
                            label="TYPE DE VEHICULE"
                            placeholder=""
                            options={products}
                            onChange={typeDropdownOnChange}
                            // value={voucher.productType}
                            value={{ label: voucher.productType }}
                            error={!!voucherErrors.productType}
                            upperCaseOptions
                        />
                    </DropdownWrapper>
                    <TextFieldVoucher
                        onChange={inputsOnChange('productSerial')}
                        label="NUMÉRO DE SÉRIE"
                        error={!!voucherErrors.productSerial}
                    />
                    <TextFieldVoucher
                        onChange={inputsOnChange('productBrand')}
                        label="MARQUE DU VEHICULE"
                        error={!!voucherErrors.productBrand}
                        optional
                    />
                    <TextFieldVoucher
                        onChange={inputsOnChange('distributorName')}
                        label="Nom du distributeur"
                        error={!!voucherErrors.distributorName}
                    />
                    <TextFieldVoucher
                        onChange={inputsOnChange('storeName')}
                        label="Nom du magasin"
                        error={!!voucherErrors.storeName}
                    />
                    <TextFieldVoucher
                        onChange={inputsOnChange('vendorName')}
                        label="Nom du vendeur"
                        error={!!voucherErrors.vendorName}
                    />
                    <Button
                        onClick={handleCreateVoucher}
                        disabled={!enableButton}
                        fullWidth={true}
                        size="l"
                        variant="dark"
                    >
                        VALIDER LA DEMANDE
                    </Button>
                </FormsTextWrapper>
            </FormsWrapper>
        </Container>
    );
};

export default Form;
