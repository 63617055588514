import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { H2DesktopStyles, HeroDescriptionStyles, HeroTitleStyles } from "../../typography/index";
import { Button } from "../index";

const FooterMessageBlockWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 45px 20px 61px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  
  ${media.lg`
    align-items: center;
    justify-content: center;
    padding: 55px 140px 62px;
  `};
  
  ${media.xl`
    flex-direction: row;
    align-items: flex-start;
  `};
`;

const FooterText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.lg`
    align-items: flex-start;
  `};
`;

const FooterTitle = styled.div` 
  ${H2DesktopStyles};  
  max-width: 280px;
  text-align: center;
  ${media.lg`
    ${HeroTitleStyles};  
    text-align: center;
    max-width: 470px;
    margin: 0 auto 13px;
  `};
  ${media.xl`
    ${HeroTitleStyles};  
    text-align: left;
    max-width: 760px;
    margin: 0 0 13px;
  `};
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
`;

const FooterDescription = styled.div`
  font-size: 12px;
  line-height: 2.17;
  letter-spacing: 0.1px;
  margin: 0 auto;
  ${media.lg`
    ${HeroDescriptionStyles};
    margin: 0 auto;
  `};
  ${media.xl`
    margin: 0;
    ${HeroDescriptionStyles};
  `};
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
`;

const GreenButtonWrapper = styled.div`
  width: 240px;
  ${media.xl`
    width: 134px;
    margin: auto 0;
  `};
`;

const GrayButtonWrapper = styled.div`
  width: 240px;
  margin: 46px 0 0;
  ${media.xl`
    width: 166px;
    margin: auto 0;
  `};
`;

const GrayButton = styled(Button)`
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.highlightedText};
`;

export {
  FooterMessageBlockWrapper,
  FooterText,
  FooterTitle,
  FooterDescription,
  GrayButton,
  GreenButtonWrapper,
  GrayButtonWrapper,
};
