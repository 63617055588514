import React, { useCallback, useState, useContext } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { StyledThemePropsType } from '../../../config/theme';
import { HexToRgba } from '../../helpers';
import Icon from '../Icon';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import {
    ButtonsWrapper,
    HeaderButton,
    SubscriptionButton,
    BurgerButton,
    MenuWrapper,
    BurgerMenuContainer,
    BurgerCloseButton,
    BurgerMenuButtons,
    BurgerSubscriptionButton,
    ExcapeProButton,
} from './elements';
import { IconsThemeContext } from '../../../themeProviderHOC';
import { CustomSvgLogo } from '../../../pages/questionnaire/elements';
import CustomLink from '../Link/index';
interface HeaderProps {
    menuButtons: MenuButtonProps[];
    withEscapeProButton?: boolean;
}

interface MenuButtonProps {
    key: number;
    name: string;
    url: string;
}

const Header: React.FC<HeaderProps> = ({
    menuButtons,
    withEscapeProButton = false,
}) => {
    const ContextIcons = useContext(IconsThemeContext);
    const { path } = useRouteMatch();

    const { push } = useHistory();

    const [burgerOpened, setBurgerOpened] = useState(false);

    const changeMenuState = useCallback(() => {
        setBurgerOpened(!burgerOpened);
    }, [burgerOpened, setBurgerOpened]);

    const goTo = useCallback(
        (route: string) => {
            changeMenuState();
            push(route);
        },
        [push, changeMenuState]
    );

    const goToQuestionnaire = useCallback(() => {
        push('/questionnaire');
    }, [push]);

    const goToEscapePro = useCallback(() => {
        push('/espace-pro');
    }, [push]);

    return (
        <Wrapper>
            <HeaderWrapper>
                <Link to="/">
                    <CustomSvgLogo icon={ContextIcons.logo} />
                </Link>
                <ButtonsWrapper>
                    {menuButtons.map(
                        ({ key, name, url }: MenuButtonProps, index) => {
                            return key === 4 ? ( // wtf why
                                <CustomLink
                                    href={url}
                                    target="_blank"
                                    font="12px"
                                    noneDecoration
                                >
                                    <HeaderButton
                                        key={index}
                                        selected={url === path}
                                    >
                                        {name}
                                    </HeaderButton>
                                </CustomLink>
                            ) : (
                                <HeaderButton
                                    key={index}
                                    onClick={() => goTo(url)}
                                    selected={url === path}
                                >
                                    {name}
                                </HeaderButton>
                            );
                        }
                    )}
                </ButtonsWrapper>
                {withEscapeProButton ? (
                    <ExcapeProButton onClick={goToEscapePro}>
                        <Icon
                            icon="lock"
                            iconColor="button"
                            height="16"
                            width="16"
                            margin="0 10px 0 0"
                        />
                        <span>Espace Pro</span>
                    </ExcapeProButton>
                ) : (
                    <SubscriptionButton onClick={goToQuestionnaire}>
                        Souscrire
                    </SubscriptionButton>
                )}
                <BurgerButton onClick={changeMenuState}>
                    <Icon
                        icon="burger"
                        iconColor="darkColor"
                        height="14"
                        width="20"
                    />
                </BurgerButton>
                {burgerOpened && (
                    <MenuWrapper>
                        <BurgerMenuContainer>
                            <BurgerCloseButton onClick={changeMenuState}>
                                <Icon
                                    icon="close"
                                    iconColor="darkColor"
                                    height="15"
                                    width="15"
                                />
                            </BurgerCloseButton>
                            <BurgerMenuButtons>
                                {menuButtons.map(
                                    (
                                        { key, name, url }: MenuButtonProps,
                                        index
                                    ) => {
                                        return key === 3 ? (
                                            <CustomLink
                                                href={url}
                                                target="_blank"
                                                font="12px"
                                                noneDecoration
                                            >
                                                <HeaderButton
                                                    key={index}
                                                    selected={url === path}
                                                >
                                                    {name}
                                                </HeaderButton>
                                            </CustomLink>
                                        ) : (
                                            <HeaderButton
                                                key={index}
                                                onClick={() => goTo(url)}
                                                selected={url === path}
                                            >
                                                {name}
                                            </HeaderButton>
                                        );
                                    }
                                )}
                            </BurgerMenuButtons>
                            <BurgerSubscriptionButton
                                onClick={() => {
                                    push('/questionnaire');
                                }}
                            >
                                Souscrire
                            </BurgerSubscriptionButton>
                        </BurgerMenuContainer>
                    </MenuWrapper>
                )}
            </HeaderWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    box-shadow: 0 2px 6px 0
        ${(props: StyledThemePropsType) => props.theme.colors.opacityBackground};
    background-color: ${(props: StyledThemePropsType) =>
        props.theme.colors.white};
    position: fixed;
    top: 0;
    z-index: 100;

    ${(props: StyledThemePropsType) => media.xl`
    background-color: ${HexToRgba(props.theme.colors.white, 0.8)};
  `}
`;

const HeaderWrapper = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 20px;

    ${media.lg`
    height: 80px;
    margin: 0 auto;
    width: 725px;
  `}

    ${media.xl`
    width: 1160px;
  `}
`;

export default Header;
